import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faqData } from '../../data/faq-data';

import { Router } from '@angular/router';
import { IFaqCategory, IFaqItem, SidebarService } from '@i-con/shared/common';

@Component({
  selector: 'app-faq-category',
  templateUrl: './faq-category.component.html',
  styleUrls: ['./faq-category.component.scss']
})
export class FaqCategoryComponent implements OnInit {

	public faqData: IFaqCategory[] = faqData;
	category_ref_name: string | null = "";
	category: IFaqCategory = {
		name: '',
		ref_name: '',
		alt: '',
		items: []
	};

	expanded: boolean = false;

	@Input() class_name:string = 'faq_page';
	@Input() phrase: string = 'Need Some Help?';
	@Input() title1: string = 'ASK US ANYTHING';
	@Input() title2: string = '';

	constructor(
		private activatedRouter: ActivatedRoute,
		private router: Router,
		private sidebarService: SidebarService) {
			this.sidebarService.expanded$.subscribe(expanded => (this.expanded = expanded));
	} 

	ngOnInit(): void {
        this.category_ref_name = this.activatedRouter.snapshot.paramMap.get('category_ref');
		this.category = faqData.filter(cat => {
			return cat.ref_name == this.category_ref_name
		})[0];

	}

	goToQuestion(question: IFaqItem, category: IFaqCategory): void {
		this.router.navigateByUrl('/faq/' + category.ref_name + '/' + question.ref_name);
	}

	goToHome(): void {
		this.router.navigateByUrl('/faq');
	}

}
