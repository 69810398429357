<div class="heading">
	<h2 mat-dialog-title>PRIVACY POLICY</h2>
</div>

<div class="container" mat-dialog-content>
	<!-- PRIVACY POLICY CONTENT GOES HERE -->
	<p>
		Nexxie Group Ltd is committed to maintaining robust privacy protections for its users. Our Privacy Policy is
		designed to help you understand how we collect, use and safeguard the information you provide to us and to
		assist you in making informed decisions when using our Service.
	</p>

	<p>
		For purposes of this Agreement, “Service” refers to the Company’s service which can be accessed via our website
		at www.nexxie.com. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of the
		Service. By accepting our Privacy Policy, you consent to our collection, storage, use and disclosure of your
		personal information as described in this Privacy Policy
	</p>
	<br />
	<h3><strong>I. INFORMATION WE COLLECT</strong></h3>

	<p>
		We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information
		that cannot be used to personally identify you, such as anonymous usage data, general demographic information we
		may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are
		generated based on the data you submit and number of clicks. Personal Information includes only your email,
		which you submit to us through the registration process at the Site.
	</p>
	<br />
	<p><em>Information collected via Technology</em></p>
	<p>
		In an attempt to improve the quality of the Service, we track information provided to us by your browser or by
		our software application when you view or use the Service, such as the website you came from (known as the
		“referring URL”), the type of browser you use, the device from which you connected to the Service, the time and
		date of access, and other information that does not personally identify you. We track this information using
		cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser
		from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables
		us to collect Non-Personal information about that user and keep a record of the user’s preferences when
		utilizing our services, both on an individual and aggregate basis.<br />The Company may use both persistent and
		session cookies; persistent cookies remain on your computer after you close your session and until you delete
		them, while session cookies expire when you close your browser.
	</p>
	<br />
	<h3><strong>II. HOW WE USE AND SHARE INFORMATION</strong></h3>

	<p><em>Personal Information</em></p>
	<p>
		Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share your Personal
		Information with third parties for marketing purposes without your consent. We do share Personal Information
		with vendors who are performing services for the Company, such as the servers for our email communications who
		are provided with access to a user’s email address for purposes of sending emails from us. Those vendors use
		your Personal Information only at our discretion and in accordance with our Privacy Policy.
	</p>

	<p>
		In general, the Personal Information you provide to us is used to help us communicate with you. For example, we
		use Personal Information to contact users in response to questions, solicit feedback from users, provide
		technical support, and inform users about promotional offers.
	</p>
	<br />
	<p><em>Non-Personal Information</em></p>
	<p>
		In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We
		also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. This
		Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the
		right to use and disclose such Non- Personal Information to our partners, advertisers and other third parties at
		our discretion.
	</p>

	<p>
		In the event we undergo a business transition such as a merger, acquisition by another company, or sale of all
		or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and
		consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our
		assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information
		practices change at any time in the future, we will post the policy changes to the Site so that you may opt out
		of the new information practices. We suggest that you check the Site periodically if you are concerned about how
		your information is used.
	</p>
	<br />
	<h3><strong>III. LINKS TO OTHER WEBSITES</strong></h3>

	<p>
		As part of the Service, we may provide links to or compatibility with other websites or applications. However,
		we are not responsible for the privacy practices employed by those websites or the information or content they
		contain. This Privacy Policy applies solely to information collected by us through the Site and the Service.
		Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link
		on our Site or via our Service. To the extent that you access or use the Service through or on another website
		or application, then the privacy policy of that other website or application will apply to your access or use of
		that site or application. We encourage our users to read the privacy statements of other websites before
		proceeding to use them.
	</p>
	<br />
	<h3><strong>IV. CHANGES TO OUR PRIVACY POLICY</strong></h3>

	<p>
		The Company reserves the right to change this policy and our Terms of Service at any time. You should
		periodically check the Site and this privacy page for updates.
	</p>
	<br />
</div>
