<div class="player">

    <div class="track-info-mobile">
        <div class="pretext">
            <div class="follow-on-spotify">
                <a href="https://open.spotify.com/playlist/2hPmS5N3eDHsM3X1mTqfRP?si=4cLo49xpTBGbSz9ZJt4r0g&nd=1" rel="nofollow"
                    target="_blank"><img src="https://cdn-images.island-conference.com/music-player/brand/i-con-vibes.svg" alt="i-Con vibes logo with white and orange colouring and grey background" /></a>
            </div>

        </div>

        <div class="track">{{artistName}} -
            {{trackName}}</div>
    </div>

    <div class="panel" [ngClass]="{'active': isPlaying }">
        <div class="controls">
            <div class="previous" (click)="previous()">
                <img class="tick-icon" src="https://cdn-images.island-conference.com/icons/previous-icon.svg">
            </div>
            <div class="tooggle" (click)="toogglePlay()">
                <div *ngIf="isPlaying; then pausedBlock else playingBlock"></div>
                <ng-template #pausedBlock>
                    <img class="tick-icon" src="https://cdn-images.island-conference.com/icons/pause-icon.svg">
                </ng-template>
                <ng-template #playingBlock>
                    <img class="tick-icon" src="https://cdn-images.island-conference.com/icons/play-icon.svg">
                </ng-template>
            </div>

            <div class="next" (click)="next()">
                <img class="tick-icon" src="https://cdn-images.island-conference.com/icons/next-icon.svg">
            </div>

        </div>
    </div>

</div>