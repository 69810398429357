<div class="wrapper">
    <div class="bg">
        <button class="close-btn" mat-button mat-dialog-close (click)="closeDialog()">
            <img src="https://cdn-images.island-conference.com/icons/close-icon.svg" alt="Close">
        </button>
    </div>

    <div class="content">
        <ng-container *ngIf="!proceedClicked">
            <h2>Are You Sure?</h2>
            <p>Please confirm that you would like to reserve this {{data.reservationType}}.</p>
            <div class="buttons">
                <button class="main-btn cancel" (click)="closeDialog()">Cancel</button>
                <button class="main-btn" (click)="proceedClick()">Proceed</button>
            </div>
        </ng-container>

        <ng-container *ngIf="proceedClicked && !requestFailed">
            <h2>Thank you for your reservation</h2>
            <p>Your new reservation will be processed by the i-Con team, and we will be in touch with you shortly.
                Please feel free to contact our team with any issues you might have regarding your
                {{data.reservationType}}
                reservations.
                <br>
                <br>
                Please keep in mind that reservations are subject to be cancelled if they are not paid in full within 7
                days after being booked.
            </p>
            <div class="buttons">
                <button class="main-btn" (click)="closeDialog()">Continue</button>
            </div>
        </ng-container>

        <ng-container *ngIf="proceedClicked && requestFailed">
            <h2>Something went wrong</h2>
            <p>{{errorMessage && errorMessage.length > 0 ? errorMessage : 'Please close the dialog and try again.'}}
            </p>
            <div class="buttons">
                <button class="main-btn" (click)="closeDialog()">Continue</button>
            </div>
        </ng-container>
    </div>
</div>