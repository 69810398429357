import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private _expanded = new BehaviorSubject<boolean>(false);
  expanded$ = this._expanded.asObservable();
  private _selectedItem = new BehaviorSubject<number>(0);
  selectedItem$ = this._selectedItem.asObservable();

  toggle() {
    this._expanded.next(!this._expanded.value);
  }

  closeSidebar() {
    this._expanded.next(false);
  }

  setSelectedItem(num: number) {
    this._selectedItem.next(num);
  }
}
