import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TransferState } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { makeStateKey } from '@angular/platform-browser';
import { CalculateDiscountService, IReservationDialogComponent, ISponsorshipType, SponsorshipService } from '@i-con/shared/common';
import { IDiscountType } from 'libs/i-con.shared/common/src/lib/interfaces/discount-type.interface';
import { ReservationDialogComponent } from '../../reservation-dialog/reservation-dialog.component';
import { PopupFormComponent } from '../popup-form/popup-form.component';

declare let window: any;

@Component({
  selector: 'app-sponsorships-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sponsorships-list.component.html',
  styleUrls: ['./sponsorships-list.component.scss'],
})
export class SponsorshipsListComponent {
  // @Input() sponsorshipsList: ISponsorshipType[];
  @Input() sponsorshipsList!: ISponsorshipType[];

  @Output() formEmitter = new EventEmitter();
  @Input() isPanel: boolean = false;
  @Input() expanded: boolean = false;
  public dialogRef!: MatDialogRef<PopupFormComponent>;

  constructor(
    private dialog: MatDialog,
    private discountService: CalculateDiscountService,
    private sponsorshipService: SponsorshipService,
    private transferState: TransferState
  ) {}

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getDiscountedPrice(price: number, discounts: IDiscountType[]): number {
    return this.discountService.calculateDiscountedPrice(price, discounts);
  }

  goToForm(sponsorship_id: number | undefined, quantity: number, name: string, price: number, discount?: IDiscountType[]) {
    window?.gtag('event', 'page_view', {
      page_title: 'i-Con Sponsorships, Reservation Form |',
      page_location: 'https://islandconference.com/attend/sponsorships/reserve',
      send_to: 'G-B8XPWS7NB5',
    });

    if (this.isPanel) {
      this.reserveSponsorshipDialog(sponsorship_id as number, quantity);
    } else {
      if (discount && discount.length > 0) {
        this.formEmitter.emit({
          sponsorshipId: sponsorship_id,
          quantity: quantity,
          name: name,
          price: Math.round(((1 - discount[0].percentage) * price) / 100) * 100 * quantity,
        });
      } else {
        this.formEmitter.emit({ sponsorshipId: sponsorship_id, quantity: quantity, name: name, price: price * quantity });
      }
    }
  }

  openDialog(sponsorship_id: number, sponsorship_name: string) {
    const sponsorshipDetails = {
      sponsorship_id: sponsorship_id,
      sponsorship_name: sponsorship_name,
    };

    this.dialogRef = this.dialog.open(PopupFormComponent, {
      maxHeight: '80vh',
      maxWidth: '85vw',
      width: '900px',
      panelClass: 'waiting-dialog',
      autoFocus: false,
      data: sponsorshipDetails,
    });
  }

  reserveSponsorshipDialog(sponsorship_id: number, quantity: number) {
    event?.preventDefault();
    const passData: IReservationDialogComponent = {
      expanded: this.expanded,
      itemId: sponsorship_id,
      reservationType: 'sponsorship',
      quantity: quantity,
    };

    const dialogRef = this.dialog.open(ReservationDialogComponent, {
      maxHeight: '85vh',
      maxWidth: '520px',
      width: '100%',
      autoFocus: true,
      disableClose: false,
      panelClass: 'padding-inline',
      //send [expanded] data so padding can be applied on the modal if the sidebar is open. when the time comes to pass actual profile/booth data an object will need to be created in this function so that we can also pass the expanded value
      data: passData,
    });

    dialogRef.afterClosed().subscribe(() => {
      const TRST_SPONSORSHIPS = makeStateKey('sponsorships');
      this.transferState.set(TRST_SPONSORSHIPS, null);
      this.sponsorshipService.getSponsorships(this.isPanel);
    });
  }

  discountedPrice(totalPrice: number, discount: number) {
    // return totalPrice;
    return Math.round(totalPrice * (1 - discount) * 100) / 100;
  }
}
