import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BookingFormGroupService, IFormField, IProfileType } from '@i-con/shared/common';

@Component({
  selector: 'i-con-billing-form',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './billing-form.component.html',
  styleUrl: './billing-form.component.scss',
})

export class BillingComponent implements OnInit {
	
	@Input() fieldsPart2: IFormField[] = [];
	@Input() submitted: boolean = false;
	@Input() profileDetails!: IProfileType;

	billingForm!: FormGroup;

	controlValidations: FormControl[] = [];


	constructor(
		private parentForm: FormGroupDirective,
		private bookingFormGroupService: BookingFormGroupService,
	) {
	}

	ngOnInit() {
		this.billingForm = this.parentForm.form;

		this.fieldsPart2.forEach((field) => {
			const validations: ValidatorFn[] = [];

			Object.entries(field.validations).forEach((validation) => {
				const key: keyof typeof Validators = validation[0] as keyof typeof Validators;

				// validation without parameters (eg. Validators.required)
				const validatorFunction = Validators[key] as () => ValidatorFn;
				
				// validation with parameters (eg. Validators.maxLength(10))
				const validatorFunctionWithParam = (Validators[key] as (param: number | string | RegExp) => ValidatorFn)(validation[1]);

				if (typeof validation[1] !== "boolean") {
					validations.push(validatorFunctionWithParam);
				}
				else {
					validations.push(validatorFunction);
				}
			});

			if (field.fieldType === 'checkbox') {
				const formControl = new FormControl(false, validations);
				this.controlValidations.push(formControl);
			}
			else {

				if (this.profileDetails.contactDetails.id !== 0) {
					switch (field.controlName) {
						case 'address':
							const addressControl = new FormControl(this.profileDetails.billingDetails.address, validations);
							this.controlValidations.push(addressControl);
							break;
						case 'country':
							const countryControl = new FormControl(this.profileDetails.billingDetails.country, validations);
							this.controlValidations.push(countryControl);
							break;
						case 'city':
							const cityControl = new FormControl(this.profileDetails.billingDetails.city, validations);
							this.controlValidations.push(cityControl);
							break;
						case 'zip':
							const zipControl = new FormControl(this.profileDetails.billingDetails.postalCode, validations);
							this.controlValidations.push(zipControl);
							break;
						case 'company':
								const companyControl = new FormControl(this.profileDetails.billingDetails.companyName, validations);
								this.controlValidations.push(companyControl);
								break;
						case 'vat':
							const vatControl = new FormControl(this.profileDetails.billingDetails.vatNumber, validations);
							this.controlValidations.push(vatControl);
							break;
						default:
							const formControl = new FormControl('', validations);
							this.controlValidations.push(formControl);
							break;
					}
				}
				else {
					const formControl = new FormControl('', validations);
					this.controlValidations.push(formControl);
				}
			}
			
		});
		
		this.billingForm.addControl('part2billing', new FormGroup({
			part2: new FormArray(this.controlValidations)
		}))
	}

	checkErrors(fieldNumber: number, error: string){
		const formGroup = this.billingForm.get('part2billing');
		const mFormArray = formGroup?.get('part2') as FormArray;

		return this.bookingFormGroupService.checkErrors(mFormArray, error, fieldNumber);
	}

	get f() {
		const formGroup = this.billingForm.get('part2billing');
		const mFormArray = formGroup?.get('part2') as FormArray;
		return mFormArray.controls;
	}
}
