<div class="container" [ngClass]="{ 'sidebarOpened' : expanded }">
    <div class="faq-list-header">
        <h3>Need Some Help?</h3>
        <h1>ASK US ANYTHING</h1>
    </div>
    
    <div class="question-section">
    
        <div class="question-s">
            <div class="left">
                <div class="questions-list">
                    <div class="question" *ngFor="let q of category.items; let i = index;" (click)="goToQuestion(q, category)" [ngClass]="{'is-active': q === question}">
                        <p>
                            {{q.question}}
                        </p>
                    </div>
                </div>
    
            </div>
            <div class="center">
                <div class="navigator">
                    <span class="cursor" (click)="goToHome()">Home</span> <span class="arrow">></span> <span class="category-name cursor" (click)="goToCategory(category)">{{category.name}}</span> <span class="arrow">></span> <span class="answer-name">Answer</span>
                </div>
                <div class="question-c">
                    <div class="title">
                        {{question.question}}
                    </div>
                    <div class="body" [innerHTML]="question.answer_safe">
                    </div>
                </div>
            </div>
            <div class="right">
    
            </div>
        </div>
    </div>
</div>
