import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ContactUsService, IContactUsTypeResponse, SidebarService, StateService } from '@i-con/shared/common';
import { Observable } from 'rxjs';
import { MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';

interface IContactUsState {
  success: boolean;
  return_msg: string;
}

const initialState: IContactUsState = {
  success: false,
  return_msg: '',
};

@Component({
  selector: 'i-con-contact-us',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatSelectModule ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent extends StateService<IContactUsState> implements OnInit {

    expanded: boolean = false;

    @Input() class_name: string = 'contact_page';
    @Input() phrase: string = 'Contact us';
    @Input() title1: string = 'WE ARE HERE FOR YOU';
    @Input() title2: string = '';

    options = [
        {
          value: 'exhibition',
          text: 'Exhibition',
        },
        {
          value: 'sponsorships',
          text: 'Sponsorships',
        },
        {
          value: 'tickets',
          text: 'Tickets',
        },
        {
          value: 'accommodation',
          text: 'Accommodation',
        },
        {
          value: 'general_questions',
          text: 'General Questions',
        },
        {
          value: 'booth_request',
          text: 'Custom Booth Request',
        },
        {
          value: 'speaker',
          text: 'Become a Speaker',
        },
        {
          value: 'mykonos',
          text: 'Private trip to Mykonos',
        },
        {
          value: 'transportation',
          text: 'Transportation',
        },
      ];
    
      submitted = false;
      contactForm!: UntypedFormGroup;
      selected = '';

    constructor(private formBuilder: UntypedFormBuilder, 
        private contactUsService: ContactUsService, 
        private activeRoute: ActivatedRoute,
        private sidebarService: SidebarService) {
        super(initialState);
        this.sidebarService.expanded$.subscribe(expanded => (this.expanded = expanded));
    }

    postData$!: Observable<IContactUsTypeResponse>;
    responseState$!: Observable<IContactUsState>;

  get f() {
    return this.contactForm.controls;
  }

  ngOnInit(): void {
    this.postData$ = this.contactUsService.postData$;
    this.responseState$ = this.getState(state => state);

    this.activeRoute.queryParams.subscribe(param => {
      if (param['enquiry'] != null) {
        this.selected = param['enquiry'];
      }
    });

    this.options.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

    this.contactForm = this.formBuilder.group({
      nameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      surnameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      emailControl: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      companyControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      topicControl: ['', [Validators.required]],
      messageControl: ['', [Validators.required, Validators.maxLength(510), Validators.minLength(10)]],
    });

    this.contactForm.get('topicControl')?.setValue(this.selected);
  }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    const contact_object = {
      name: this.contactForm.value.nameControl,
      surname: this.contactForm.value.surnameControl,
      email: this.contactForm.value.emailControl,
      companyName: this.contactForm.value.companyControl,
      topic: this.contactForm.value.topicControl,
      message: this.contactForm.value.messageControl,
    };

    this.contactUsService.contactUsService(contact_object);

    this.postData$.subscribe(res => {
      if (res.response?.statusCode == 201) {
        const element = document.querySelector('.heading');
        if (element) element.scrollIntoView();
        this.setState({
          success: true,
          return_msg: '',
        });
      } else {
        this.setState({
          success: false,
          return_msg: res.response?.message,
        });
      }
    });
  }

}
