<div class="form-container-wrapper">
	<h3>{{ formTitle }}</h3>
	<!-- <p>Please ensure this person is the best point of contact for all communications regarding your ticket purchase.</p> -->
	<form class="form" [formGroup]="primaryContactForm" (ngSubmit)="onSubmit()">
		<div class="form-row">
			<div class="form-col name-field">
				<input class="input-name-field" type="text" placeholder="Name" formControlName="nameControl" />
				<div
					*ngIf="(primaryContactForm.touched || submitted) && f['nameControl'].errors"
					class="invalid-feedback">
					<div *ngIf="(primaryContactForm.touched || submitted) && f['nameControl'].errors['required']">
						Name is required
					</div>
					<div *ngIf="f['nameControl'].errors['minlength']">
						A minimum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["nameControl"].errors["minlength"].requiredLength
						}}
						characters length is required
					</div>
					<div *ngIf="f['nameControl'].errors['maxlength']">
						A maximum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["nameControl"].errors["maxlength"].requiredLength
						}}
						characters length is required
					</div>
				</div>
			</div>

			<div class="form-col surname-field">
				<input class="input-name-field" type="text" placeholder="Surname" formControlName="surnameControl" />
				<div
					*ngIf="(primaryContactForm.touched || submitted) && f['surnameControl'].errors"
					class="invalid-feedback">
					<div *ngIf="(primaryContactForm.touched || submitted) && f['surnameControl'].errors['required']">
						Surname is required
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['surnameControl'].errors['minlength']">
						A minimum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["surnameControl"].errors["minlength"].requiredLength
						}}
						characters length is required
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['surnameControl'].errors['maxlength']">
						A maximum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["surnameControl"].errors["maxlength"].requiredLength
						}}
						characters length is required
					</div>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="form-col email-field">
				<input
					class="input-email-field"
					type="text"
					placeholder="Email Address"
					formControlName="emailControl" />
				<div
					*ngIf="(primaryContactForm.touched || submitted) && f['emailControl'].errors"
					class="invalid-feedback">
					<div *ngIf="(primaryContactForm.touched || submitted) && f['emailControl'].errors['required']">
						Email is required
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['emailControl'].errors['email']">
						Please write a valid email
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['emailControl'].errors['maxlength']">
						A maximum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["emailControl"].errors["maxlength"].requiredLength
						}}
						characters length is required
					</div>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="form-col skype-field">
				<input class="input-skype-field" type="text" placeholder="Skype ID" formControlName="skypeControl" />
				<div
					*ngIf="(primaryContactForm.touched || submitted) && f['skypeControl'].errors"
					class="invalid-feedback">
					<div *ngIf="(primaryContactForm.touched || submitted) && f['skypeControl'].errors['required']">
						Skype ID is required
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['skypeControl'].errors['pattern']">
						Please write a valid Skype ID
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['skypeControl'].errors['minlength']">
						A minimum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["skypeControl"].errors["minlength"].requiredLength
						}}
						characters length is required
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['skypeControl'].errors['maxlength']">
						A maximum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["skypeControl"].errors["maxlength"].requiredLength
						}}
						characters length is required
					</div>
				</div>
			</div>
		</div>

		<div class="form-row">
			<div class="form-col phone-field">
				<ngx-intl-tel-input
					[cssClass]="'phone-input'"
					[enablePlaceholder]="true"
					[searchCountryFlag]="true"
					[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
					[enableAutoCountrySelect]="true"
					[maxLength]="15"
					[selectedCountryISO]="CountryISO.Cyprus"
					[phoneValidation]="true"
					[separateDialCode]="true"
					name="phone"
					formControlName="phoneControl">
				</ngx-intl-tel-input>
				<div *ngIf="submitted && f['phoneControl'].errors" class="invalid-feedback">
					<div *ngIf="f['phoneControl'].errors['required']">Phone is required</div>
					<div *ngIf="f['phoneControl'].errors && !f['phoneControl'].errors['required']">Invalid Input</div>
				</div>
			</div>
			<div class="form-col company-field">
				<input
					class="input-company-field"
					type="text"
					placeholder="Company Name"
					formControlName="companyControl" />
				<div
					*ngIf="(primaryContactForm.touched || submitted) && f['companyControl'].errors"
					class="invalid-feedback">
					<div *ngIf="(primaryContactForm.touched || submitted) && f['companyControl'].errors['required']">
						Company Name is required
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['companyControl'].errors['minlength']">
						A minimum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["companyControl"].errors["minlength"].requiredLength
						}}
						characters length is required
					</div>
					<div *ngIf="(primaryContactForm.touched || submitted) && f['companyControl'].errors['maxlength']">
						A maximum of
						{{
							(primaryContactForm.touched || submitted) &&
								f["companyControl"].errors["maxlength"].requiredLength
						}}
						characters length is required
					</div>
				</div>
			</div>
		</div>

		<div class="form-row"></div>
		<div class="buttons">
			<button class="main-btn" type="submit" (submit)="onSubmit()">{{ buttonText }}</button>
			<button class="back-btn" (click)="goBack()">Back</button>
		</div>
	</form>
</div>
