<ng-container *ngIf="sortedSponsoships$ | async as sponsorships">

	<ng-container *ngIf="sponsorshipResponseState$ | async as sponsorshipResponseState">

		<div class="main-container">
			<div class="main-header">
				<h3>
					<span>Boost Your Brand with</span><br>
					EXTRA BENEFITS!
				</h3>
			</div>

			<div class="container-details" *ngIf="currentStep === 1">
				<div class="details">
					<h3>
						DON'T MISS YOUR CHANCE TO SECURE ONE OF OUR
					</h3>
					<h1>
						AMAZING SPONSORSHIP OPPORTUNITIES.
					</h1>
					<p>
						Through them you will receive unparalleled and dynamic exposure both inside and outside the
						Expo! Elevate your brand and become an i-Con partner by sponsoring one of the options below!
						<br><br>
						All Sponsorships come with their own set of benefits and inclusions, nonetheless, they all share
						the following perks:
					</p>
				</div>
				<div class="perks-items">
					<div class="item">
						<img class="icon" src="https://cdn-images.island-conference.com/sponsorships/email.svg">
						<p>
							Exposure to i-Con attendees through our email list
						</p>
					</div>
					<div class="item">
						<img class="icon" src="https://cdn-images.island-conference.com/sponsorships/devices.svg">
						<p>
							Online branding on both the official i-Con website & app
						</p>
					</div>
					<div class="item">
						<img class="icon" src="https://cdn-images.island-conference.com/sponsorships/branded.svg">
						<p>
							Dedicated branded posts on i-Con's official social media channels
						</p>
					</div>
					<div class="item">
						<img class="icon" src="https://cdn-images.island-conference.com/sponsorships/plane.svg">
						<p>
							A "Thank You" mention in a wrap mail-out to all of i-Con's attendees
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="sponsorships-container" *ngIf="currentStep === 1">
			<div *ngIf="!showOnlyAvailableSponsorships" class="dropdown-wrapper">
				<mat-form-field class="mat-form-field" appearance="fill">
					<mat-select (selectionChange)="sortSponsorships($event.value)" placeholder="All Sponsorships"
						panelClass="select-dropdown">
						<mat-option [value]="3">All Sponsorships</mat-option>
						<mat-option [value]="2">Available Sponsorships</mat-option>
						<mat-option [value]="1">Pending Sponsorships</mat-option>
						<mat-option [value]="0">Reserved Sponsorships</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<app-sponsorships-list *ngIf="currentStep === 1 && !sponsorshipResponseState.success"
				[sponsorshipsList]="sponsorships" (formEmitter)="goToForm($event)"
				[isPanel]="showOnlyAvailableSponsorships" [expanded]="expanded"></app-sponsorships-list>
		</div>

		<div class="booking-container" *ngIf="currentStep === 2 && !sponsorshipResponseState.success">
			<div class="information-container">
				<span *ngIf="isLoggedIn">One Step Away! You Won't Need To Fill In Your Information To Make Your
					Reservation!</span>
				<span *ngIf="!isLoggedIn">One Step Away! Fill In Your Contact Information And A Member Of Our Team Will
					Be In Touch!</span>
				<p>Step 2 of 2: Fill in your info</p>
			</div>
			<div class="booking-details">

				<!-- <booking-form-group
					class="booking-form-component"
					[previousStep]="currentStep - 1"
					(goToStepEmitter)="goToStep($event)"
					(bookingFormEmmitter)="bookingFormDetails($event)"
					[btnText]="'Reserve'"
					[errorMessages]="[sponsorshipResponseState.return_msg]">
				</booking-form-group> -->

				<i-con-booking-form-group class="booking-form-component" [previousStep]="currentStep - 1"
					(goToStepEmitter)="goToStep($event)" (bookingFormEmmitter)="bookingFormDetails($event)"
					[btnText]="'Reserve'" [errorMessages]="[sponsorshipResponseState.return_msg]"
					[confirmationName]="sponsorshipName" (loggedInEmitter)="loggedInReservation()">
				</i-con-booking-form-group>

				<div class="order-summary">
					<div class="title">
						<h2>Order Summary</h2>
					</div>
					<div class="details">
						<hr>
						<div class="item">
							<p>{{sponsorshipName}}</p>
							<p>&euro;{{sponsorshipPrice | number}}</p>
						</div>
						<hr>
					</div>
					<div class="total">
						<p>Total</p>
						<p>&euro;{{sponsorshipPrice | number}}</p>
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="sponsorshipResponseState.success">
			<successful-api-call [heading]="'Thank you for your request!'" [subText]="sponsorshipSuccessData"
				(goBackInitialPage)="goToStep($event)">
			</successful-api-call>
		</ng-container>
	</ng-container>
</ng-container>