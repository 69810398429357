import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { hotelsData } from '@i-con/shared/ui';
import { IHotels } from '@i-con/shared/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';


export interface IReservation {
    startDate: string;
    endDate: string;
    totalPersons: number;
    hotelName: string;
}

@Component({
  selector: 'app-stay-accommodation-request',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatNativeDateModule, MatDatepickerModule, ReactiveFormsModule],
  templateUrl: './stay-accommodation-request.component.html',
  styleUrls: ['./stay-accommodation-request.component.scss'],
})
export class StayAccommodationRequestComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private dateAdapter: DateAdapter<Date>, private formBuilder: UntypedFormBuilder) {
    this.dateAdapter.setLocale('en-GB');
  }
  hotel_ref_name!: string;

  @Input() selectedId!: number;

  @Output() stayInformation = new EventEmitter<any>();

  // hotelsInfo = hotelsData;
  hotelsInfo = hotelsData;
  selectedHotel: IHotels = {
    ref_name: '',
    name: '',
    stars: '',
    altStars: '',
    description: '',
    logoUrl: '',
    image1: '',
    image2: '',
    mainImage: '',
    mainImageMobile: '',
    alt1: '',
    alt2: '',
    alt3: '',
  };
  numberOfPersons: number = 0;
  minDate!: Date;
  maxDate!: Date;

  submitted = false;
  wrongRange = false;
  dateForm!: UntypedFormGroup;

  ngOnInit(): void {
    // this.hotel_ref_name = this.route.snapshot.paramMap.get('hotel_ref_name') ?? '';

    this.route.params.subscribe(params => {
      this.hotel_ref_name = params['hotel_ref_name'];
      const hotel = this.selectHotel();

      // temp redirect
      if (!hotel) {
        this.router.navigate(['404']);
        // if hote name is not provided redirect to 404 page
      }
    });

    this.minDate = new Date('2024-05-27T00:00:00');
    this.maxDate = new Date('2024-06-02T00:00:00');

    this.dateForm = this.formBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
    });
  }

  get f() {
    return this.dateForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // if the form does not pass the validations return
    if (this.dateForm.invalid || this.numberOfPersons == 0) {
      return;
    }

    // calculate the total days
    const selectedStartDate: Date = this.dateForm.value.startDate;
    const selectedEndDate: Date = this.dateForm.value.endDate;
    const totalDays = this.getNumberOfDays(selectedEndDate.getTime() - selectedStartDate.getTime());

    if (totalDays > 10) {
      this.wrongRange = true;
      return;
    }

    // const startDate = selectedStartDate.getTime();
    // const endDate = selectedEndDate.getTime();
    const startDate = selectedStartDate.toISOString();
    const endDate = selectedEndDate.toISOString();

    // this.info.startDate = startDate;
    // this.info.endDate = endDate;
    // this.info.totalPersons = this.numberOfPersons;
    // this.info.hotelName = this.selectedHotel.name

    const info: IReservation = {
      startDate: startDate,
      endDate: endDate,
      totalPersons: this.numberOfPersons,
      hotelName: this.selectedHotel.name,
    };

    this.submitStayInformation(info);
  }

  submitStayInformation(reservation: IReservation) {
    this.stayInformation.emit({ reservation });
  }

  getNumberOfDays(milliseconds: number) {
    let minutes = Math.floor(milliseconds / 60000);
    let hours = Math.round(minutes / 60);
    let days = Math.round(hours / 24);

    return days;
  }

  // select the initial hotel the user choose from previous page
  selectHotel(): IHotels | null {
    for (var hotel of this.hotelsInfo) {
      if (hotel.ref_name == this.hotel_ref_name) {
        this.selectedHotel = hotel;
        return this.selectedHotel;
      }
    }
    return null;
  }

  // change the selected hotel, in case the user changed hotel from the same page
  changeSelectedHotel(hotel: IHotels) {
    this.router.navigate([ this.router.url.includes('stay') ? '/stay/accommodation/request' : '/accommodation/request' , hotel.ref_name]);
    //this.selectedHotel = hotel;
  }

  increasePersons() {
    if (this.numberOfPersons >= 10) {
      this.numberOfPersons = 10;
    } else {
      this.numberOfPersons++;
    }
  }

  decreasePersons() {
    if (this.numberOfPersons <= 0) {
      this.numberOfPersons = 0;
    } else {
      this.numberOfPersons--;
    }
  }
}
