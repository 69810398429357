import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBoothReservationOrder, IReservationDialogComponent, ISponsorshipTypeReservationLoggedIn, OrderService } from '@i-con/shared/common';

@Component({
  selector: 'i-con-reservation-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './reservation-dialog.component.html',
  styleUrl: './reservation-dialog.component.scss',
})
export class ReservationDialogComponent implements OnInit {
  proceedClicked: boolean = false;
  requestFailed: boolean = false;
  errorMessage: string = '';
  //data will be an interface when we pass actual data
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: IReservationDialogComponent,
    public dialogRef: MatDialogRef<ReservationDialogComponent>,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    if (this.data?.expanded) {
      const modalEl = this.document.querySelector(".cdk-global-overlay-wrapper[dir='ltr']");
      modalEl?.setAttribute('style', 'padding-left: 260px');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  proceedClick() {
    switch (this.data.reservationType) {
      case 'booth': {
        const reservation: IBoothReservationOrder = {
          reservation: {
            boothTypes: [
              {
                boothTypeId: this.data.itemId,
              },
            ],
          },
        };
        this.orderService.reserveBooth(reservation).subscribe(
          response => {
            this.proceedClicked = true;
            if (response.response.statusCode !== 201) {
              this.requestFailed = true;
            }
          },
          error => {
            this.requestFailed = true;
            this.proceedClicked = true;
            this.errorMessage = error.error.response.message;
          }
        );
        break;
      }
      case 'sponsorship': {
        const reservation: ISponsorshipTypeReservationLoggedIn = {
          reservation: {
            sponsorshipTypes: [
              {
                sponsorshipTypeId: this.data.itemId,
                quantity: this.data.quantity as number,
              },
            ],
          },
        };
        this.orderService.reserveSponsorship(reservation).subscribe(
          response => {
            this.proceedClicked = true;
            if (response.response.statusCode !== 201) {
              this.requestFailed = true;
            }
          },
          error => {
            this.requestFailed = true;
            this.proceedClicked = true;
            this.errorMessage = error.error.response.message;
          }
        );
        break;
      }
      default: {
        this.requestFailed = true;
        this.proceedClicked = true;
      }
    }
  }
}
