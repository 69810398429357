
<div class="container" [ngClass]="{ 'sidebarOpened' : expanded }">
    <div class="download-header">
        <div class="content">
            <h3>i-Con at your</h3>
            <h1>FINGERTIPS</h1>
        </div>
    </div>

    <div class="first-section section">
        <div class="left">
            <div class="rectangle"></div>
            <img class="icon-app" src="https://cdn-images.island-conference.com/download_app/mobile_app.png">
        </div>
        <div class="right">
            <div class="title">
                <h2>The i-Conic App</h2>
                <img class="download-icon" src="https://cdn-images.island-conference.com/download_app/download_icon.png">
            </div>
            <p>
                Do you want to make your planning and execution of meetings and events seamless during i-Con?<br><br>
                <strong>Then look no further!</strong><br><br>
                The i-Conic app allows attendees to manage all aspects of the event from one centralized platform.<br><br>
                The wait is over! The i-Con app is now live! Simply tap the button below to download it on your preferred device and experience the event like never before!
            </p>
            <div class="button-wrapper">
                <img (click)="appStore()" class="app-store" src="https://cdn-images.island-conference.com/homepage/app_store.svg"/>
                <img (click)="playStore()" class="play-store" src="https://cdn-images.island-conference.com/homepage/google_play.svg"/>
            </div>
        </div>
    </div>

    <div class="second-section">
        <h2>Features</h2>
        <div class="overlay"></div>
        <div class="background-line"></div>
        <div class="slider-container">
            <div class="slider">
                <ngx-slick-carousel class="carousel" 
                #slickModal="slick-carousel" 
                [config]="slideConfig" >
                    <div ngxSlickItem class="slide" *ngFor="let slide of mobileAppSlides">
                        <div class="slider-item">
                            <div class="left">
                                <img class="slide-img" src="{{slide.img}}" alt="{{slide.alt}}">
                            </div>
                            <div class="right">
                                <h2>{{slide.title}}</h2>
                                <p>{{slide.content}}</p>                                
                            </div>
                        </div>                            
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>

    <div class="third-section">
        <div class="content">
            <div class="left">
                <img class="icon-vibes" src="https://cdn-images.island-conference.com/download_app/icon_vibes_logo.png" alt="i-Con cy Vibes spotify playlist logo">
                <p>Tune in with Island Conference’s very own Spotify playlist and spread the i-Con vibes!</p>
                <div class="spotify-wrapper">
                    <button class="main-btn" (click)="goToPlaylist()">Listen Now</button>
                    <img class="spotify-icon" src="https://cdn-images.island-conference.com/download_app/spotify-icon-white.png" alt="Spotify favicon. Icon and circle border are white">
                </div>
            </div>
            <div class="right">
                <div class="phone-container">
                    <img class="frame" src="https://cdn-images.island-conference.com/download_app/frame.png" alt="iphone frame with a video displaying in its screen">
                    <video id="myVideo" loop muted autoplay playsinline oncanplay="this.play()"
                        poster="https://cdn-images.island-conference.com/homepage/i-con-thumbnail.webp"
                        onloadedmetadata="this.muted = true">
                        <source src="https://cdn-images.island-conference.com/download_app/spotify_frameless.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    </div>

    <div class="fourth-section">
        <div class="content">
            <div class="left">
                <img class="phone-animation" src=" https://cdn-images.island-conference.com/download_app/sponsor2024.gif" alt="Two mobile phone displays overlapping. Presenting the i-Con application. Floating pop up box on the right with traffic stars logo">
            </div>
            <div class="right">
                <h2>SIMPLE AND MINIMALISTIC APPROACH</h2>
                <p>
                    Our app offers a seamless and intuitive interface, including powerful features that will make networking easier
                    and more efficient than ever before.
            
                </p>
                <p>
                    Want to find out more about what the conference's available sponsorships? Then check out our dedicated page
                    below for more information!
                </p>
            
                <button class="main-btn sponsor-btn"><a href="{{appUrl}}/attend/sponsorships">Sponsorships</a></button>
            </div>
        </div>    
    </div>

    <div class="fifth-section">
        <div class="content">
            <div class="left">
                <h2>Get in touch</h2>
                <p>
                    Our mobile application is available on both Android and iOS devices. <br><br>
                    As the i-Conic App is being developed internally, any feedback and ideas are welcome to give our attendees a better all-around experience!
                </p>
                <button class="contact-btn main-btn" (click)="contactUs()">Contact Us</button>
            </div>
            <div class="right">
                <img class="devices" src="https://cdn-images.island-conference.com/download_app/devices.png" alt="Android mobile phone showing it's logo on the display. iPhone mobile phone displaying the apple logo. Both phones overlapping">
            </div>
        </div>
    </div>
</div>