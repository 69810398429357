import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  standalone: true,
  imports: [CommonModule, MatSelectModule, FormsModule],
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit {
  disableSelect = new UntypedFormControl(false);
  @Input() items!: { key: string | undefined; text: string; disabled: boolean; subtext?: string }[];
  @Input() selected_item_id: string | undefined = '';
  @Input() placeholder: string = 'Please select';
  @Input() prefixIconUrl?: string;
  @Input() select_class: string = '';
  @Input() formControlName: string = 'select';
  @Output() onSelectedOptionChange = new EventEmitter<string>();
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit(): void {
    //console.log(this.items);
  }

  change($event: any): void {
    this.onSelectedOptionChange.emit(this.selected_item_id);
  }
}
