import { Injectable } from "@angular/core";
import { filter, switchMap } from "rxjs";
import { AudioStream } from "./audio-stream.service";
import { PlaylistStreamConfig } from "./playlist-audio-stream.config";
import { IPlaylistStreamState } from "../interfaces/playlist-stream-state.interface";
import { ITrack } from "../interfaces/playlist-tracks.interface";
import { PlatformService } from "./platform.service";
import { InjectorHolderService } from "./injector-holder.service";
import { IPlaylistStreamConfig } from "../interfaces/playlist-stream-config.interface";


@Injectable()
export class PlaylistAudioStream extends AudioStream {
    protected override state: IPlaylistStreamState = {
        playing: false,
        isFirstTrack: false,
        isLastTrack: false,
        trackInfo: {
            currentTime: undefined,
            duration: undefined,
            currentTrack: undefined,
            currentTrackArtist: undefined,
            currentTrackName: undefined,
            currentAlbumPhoto: undefined,
        }
    };

    private currentTrackIndex: number = 0;
    private tracks: Array<ITrack> = [];
    declare protected platformService: PlatformService;

    constructor(config: PlaylistStreamConfig,
        platformService: PlatformService) {
        super(config, platformService);

        if (platformService.isBrowser) {
            this.configureStream(config);
        }
        
        // this.platformService = InjectorHolderService.injector.get(PlatformService);
        // if (this.platformService.isBrowser) {
        //     this.configureStream(config);
        // }
    }


    public next(): void {
        if (!this.isLastPlaying()) {
            this.loadTrackAtIndex(++this.currentTrackIndex);
        } else {
            this.loadTrackAtIndex(0);
        }
    }

    public previous(): void {
        if (!this.isFirstPlaying()) {
            this.loadTrackAtIndex(--this.currentTrackIndex);
        }
        else {
            this.loadTrackAtIndex(0);
        }
    }

    public loadTrackAtIndex(index: number): void {
        this.currentTrackIndex = index;
        this.loadTrack(this.tracks[index].src);
        this.updateStateProps(this.state, 'isFirstTrack', this.isFirstPlaying());
        this.updateStateProps(this.state, 'isLastTrack', this.isLastPlaying());


        const trackInfo = this.state.trackInfo;
        trackInfo.currentTrackArtist = this.tracks[index].artistName;
        trackInfo.currentTrackName = this.tracks[index].trackName;
        trackInfo.currentAlbumPhoto = this.tracks[index].albumPhoto;
        this.updateStateProps(this.state, 'trackInfo', trackInfo);
    }

    public isFirstPlaying(): boolean {
        return this.currentTrackIndex === 0 ? true : false;
    }

    public isLastPlaying(): boolean {
        return this.currentTrackIndex === this.tracks.length - 1 ? true : false;
    }

    private autoPlayNext(config: IPlaylistStreamConfig) {
        if (config && config.autoPlayNext) {
            this.next();
            this.play();
        }
    }

    private configureStream(config: IPlaylistStreamConfig) {
        let initialTrack;
        this.tracks = config.tracks;

        if (config && config.initialTrack) {
            initialTrack = config.initialTrack;
        } else {
            initialTrack = 0;
        }

        this.loadTrackAtIndex(initialTrack);

        const canPlay$ = this.events().pipe(filter((ev: Event) => ev.type === 'canplay'));
        const ended$ = this.events().pipe(filter((ev: Event) => ev.type === 'ended'));
        canPlay$.pipe(switchMap(event => ended$)).subscribe(value => this.autoPlayNext(config));
    }
}