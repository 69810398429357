import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(private _platform: PlatformService) {}

  public get getApiUrl(): string {
    if (this._platform.isServer) {
      return environment.apiUrlServer;
    } else {
      return environment.apiUrlBrowser;
    }
  }

  public get getName(): string {
    return environment.name;
  }

  public get getPortalUrl(): string {
    return environment.portalUrl;
  }

  public get getAppUrl(): string {
    return environment.appUrl;
  }

  public get getDomain(): string {
    return environment.domain;
  }
}
