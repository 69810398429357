import { ISideBarItem } from "@i-con/shared/common";

export const sidebarItems: ISideBarItem[] = [
  {
    imgUrl: 'https://cdn-images.island-conference.com/panel/sidebar/tickets.svg',
    name: 'Tickets',
    isActive: false,
    path: '/tickets',
  },
  {
    imgUrl: 'https://cdn-images.island-conference.com/panel/sidebar/booths.svg',
    name: 'Booths',
    isActive: false,
    path: '/booths',
  },
  {
    imgUrl: 'https://cdn-images.island-conference.com/panel/sidebar/sponsorships.svg',
    name: 'Sponsorships',
    isActive: false,
    path: '/sponsorships',
  },
  {
    imgUrl: 'https://cdn-images.island-conference.com/panel/sidebar/transportation.svg',
    name: 'Transportation',
    isActive: false,
    path: '/transportation',
  },
  {
    imgUrl: 'https://cdn-images.island-conference.com/panel/sidebar/accommodation.svg',
    name: 'Accommodation',
    isActive: false,
    path: '/accommodation',
  },
  {
    imgUrl: 'https://cdn-images.island-conference.com/panel/sidebar/haze_events.svg',
    name: 'Haze Events',
    isActive: false,
    path: '/haze-events',
  },
  {
    imgUrl: 'https://cdn-images.island-conference.com/panel/sidebar/order_history.svg',
    name: 'Order History',
    isActive: false,
    path: '/order-history',
  },
  {
    imgUrl: 'https://cdn-images.island-conference.com/panel/sidebar/logout.svg',
    name: 'Logout',
    isActive: false,
  },
];
