<div class="billing-container" [formGroup]="billingForm">
    <h3 class="form-head">Billing Details</h3>

    <div class="field-wrapper" formGroupName="part2billing">
        <ng-container *ngFor="let field of fieldsPart2; let i = index;">
            <div formArrayName="part2" class="field-container {{field.class}}" [ngClass]="{'inline': field.fullLine}">
                <input
                    *ngIf="field.fieldType === 'text'"
                    [attr.type]="field.fieldType"
                    class="{{field.class}}"
                    [placeholder]="field.placeHolder"
                    [formControlName]="i"
                >
                
                <div class="invalid-feedback" *ngIf="(billingForm.touched || submitted) && f[i].errors">
                    <div *ngIf="checkErrors(i, 'required')">
                        {{field.placeHolder}} is required.
                    </div>
                    <div *ngIf="checkErrors(i, 'maxlength')">
                        A maximum of {{field.validations.maxLength}} characters length is required.
                    </div>
                    <div *ngIf="checkErrors(i, 'minlength')">
                        A maximum of {{field.validations.minLength}} characters length is required.
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>