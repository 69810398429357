<div class="container">
    <div class="content">
        <div class="heading">
            <h1>{{heading}}</h1>
            <a class="back-btn" (click)="goBack()">Back</a>
        </div>
        <div class="subText">
            <div class="left">
                <h2>{{subText.subHeading}}</h2> 
                <p [innerHtml]="subText.content"></p>
                <span>{{subText.note}}</span>
                <a [routerLink]="['/contact']">Contact us</a>
            </div>
            <div class="right">
                <img class="success" src="https://cdn-images.island-conference.com/contact/success-tick.svg" />
            </div>
        </div>
        <div class="nextSteps">
            <span><strong>What are your next steps?</strong></span>
        </div>
        <!-- <div class="nextStepsContent">
            <div class="card" *ngFor="let card of cardsData">
                <div class="image"><img src="{{card.imageUrl}}" alt="{{card.imageAlt}}" /></div>
                <div class="infoAndLink">
                    <div class="info">
                        <span>{{card.title}}</span>
                        <p>{{card.description}}</p>
                    </div>
                    <div class="link">
                        <a [routerLink]="['/' + card.redirectUrl]">{{card.linkText}}</a>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="followup-links">
            <div class="link" *ngFor="let card of cardsData">
                <div class="top" style="background-image: url({{card.imageUrl}});">
                </div>
                <div class="bottom">
                    <h3>{{card.title}}</h3>
                    <p>{{card.description}}</p>
                    <a class="page-link" [routerLink]="['/' + card.redirectUrl]">{{card.linkText}}</a>
                </div>
            </div>
        </div>


    </div>
</div>