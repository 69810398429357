import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IStayTransportation, IStayTransportationTypeResponse, StateService, StayTransportationService } from '@i-con/shared/common';
import { CountryISO, NgxIntlTelInputModule, SearchCountryField } from 'ngx-intl-tel-input';
import { Observable } from 'rxjs';
import { SelectComponent } from './components/select/select.component';
import { TransportationEventCardComponent } from './components/transportation-event-card/transportation-event-card.component';
import { eventsTransportationData } from './data/events-transportation-data';

interface ITransportationFormState {
  success: boolean;
  return_msg: string;
}
const initialState: ITransportationFormState = {
  success: false,
  return_msg: '',
};
@Component({
  selector: 'i-con-app-transportation',
  standalone: true,
  templateUrl: './transportation.component.html',
  styleUrls: ['./transportation.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, NgxIntlTelInputModule, SelectComponent, TransportationEventCardComponent],
})
export class TransportationComponent extends StateService<ITransportationFormState> implements OnInit {
  class_name = 'stay_transportation_page';
  phrase: string = 'Transportation';
  title1: string = 'TO AND FROM THE VENUE';

  public eventsTransportationData: any = eventsTransportationData;
  trip_type_option_items: { key: string; text: string; disabled: boolean }[] = [
    {
      key: 'one-way',
      text: 'One way (Arrival)',
      disabled: false,
    },
    {
      key: 'one-way-departure',
      text: 'One way (Departure)',
      disabled: false,
    },
    {
      key: 'round-trip',
      text: 'Round Trip',
      disabled: false,
    },
  ];
  seats_option_items: { key: string; text: string; disabled: boolean }[] = [
    { key: '1', text: '1 seat', disabled: false },
    { key: '2', text: '2 seats', disabled: false },
    { key: '2', text: '2 seats', disabled: false },
    { key: '3', text: '3 seats', disabled: false },
    { key: '4', text: '4 seats', disabled: false },
    { key: '5', text: '5 seats', disabled: false },
    { key: '6', text: '6 seats', disabled: false },
    { key: '7', text: '7 seats', disabled: false },
    { key: '8', text: '8 seats', disabled: false },
    { key: '9', text: '9 seats', disabled: false },
    { key: '10', text: '10 seats', disabled: false },
  ];
  airports_option_items: { key: string; text: string; subtext: string; disabled: boolean }[] = [
    { key: 'pfo', text: 'Paphos', subtext: 'PFO', disabled: false },
    { key: 'lca', text: 'Larnaca', subtext: 'LCA', disabled: false },
  ];
  venue_option_items: { key: string; text: string; subtext: string; disabled: boolean }[] = [
    { key: 'cod', text: 'City of Dreams', subtext: 'COD', disabled: false },
    { key: 'ajax', text: 'Ajax Hotel', subtext: '', disabled: false },
  ];
  preferredslot_option_items: { key: string; text: string; disabled: boolean }[] = [];

  preferredslot_option_itemsFirst: { key: string; text: string; disabled: boolean }[] = [
    { key: '10:00', text: '10:00', disabled: false },
    { key: '12:00', text: '12:00', disabled: false },
    { key: '14:00', text: '14:00', disabled: false },
    { key: '16:00', text: '16:00', disabled: false },
    { key: '18:00', text: '18:00', disabled: false },
    { key: '21:00', text: '21:00', disabled: false },
    { key: '23:00', text: '23:00', disabled: false },
  ];

  airportShuttleBookForm!: UntypedFormGroup;
  submitted = false;
  success = false;
  phoneInput: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  selected_seats: number = 1;
  selected_from_airport: string | null = this.airports_option_items[0].key;
  selected_to_airport: string | null = null;
  selected_from_location: string | null = null;
  selected_to_preffered_slot: string | null = null;
  selected_to_location: string | null = this.venue_option_items[0].key;
  selected_trip_type_key: string = 'one-way';
  selectedSlotDept: string = '06:30';
  informationalString = '';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private stayTransportationService: StayTransportationService,
    private router: Router
  ) {
    super(initialState);
  }

  selected_trip_type_changed(trip_type_id: string): void {
    this.selected_trip_type_key = trip_type_id;

    // add/remove validation based on the trip type the user selects
    // validations added (required field)
    switch (this.selected_trip_type_key) {
      case 'one-way': {
        //validate fromAirport, toLocation, fromSlot  remove fromLocation,toAirport,ToSlot + null fields
        this.airportShuttleBookForm.controls['fromAirportSelectControl'].setValidators([Validators.required]);
        this.airportShuttleBookForm.controls['toLocationSelectControl'].setValidators([Validators.required]);
        this.airportShuttleBookForm.controls['fromPreferredSlotControl'].setValidators([Validators.required]);

        this.airportShuttleBookForm.get('fromLocationSelectControl')?.setValidators(null);
        this.airportShuttleBookForm.get('fromLocationSelectControl')?.setValue(null);
        this.airportShuttleBookForm.get('fromLocationSelectControl')?.setErrors(null);

        this.airportShuttleBookForm.get('toAirportSelectControl')?.setValidators(null);
        this.airportShuttleBookForm.get('toAirportSelectControl')?.setValue(null);
        this.airportShuttleBookForm.get('toAirportSelectControl')?.setErrors(null);

        this.airportShuttleBookForm.get('toPreferredSlotControl')?.setValidators(null);
        this.airportShuttleBookForm.get('toPreferredSlotControl')?.setValue(null);
        this.airportShuttleBookForm.get('toPreferredSlotControl')?.setErrors(null);

        this.selected_from_location = null;
        this.selected_to_airport = null;
        this.selected_to_preffered_slot = null;

        //reset informational string and variables
        this.selected_from_location = 'cod';
        this.selectedSlotDept = '06:30';
        this.selected_from_airport = this.airports_option_items[0].key;
        this.setCODSlots();
        this.setCODString();
        break;
      }
      case 'one-way-departure': {
        //validate fromLocation, toAirport, toSlot remove fromAirport, toLocation, fromSlot + null fields

        this.airportShuttleBookForm.controls['fromLocationSelectControl'].setValidators([Validators.required]);
        this.airportShuttleBookForm.controls['toAirportSelectControl'].setValidators([Validators.required]);
        this.airportShuttleBookForm.controls['toPreferredSlotControl'].setValidators([Validators.required]);

        this.airportShuttleBookForm.get('fromAirportSelectControl')?.setValidators(null);
        this.airportShuttleBookForm.get('fromAirportSelectControl')?.setValue(null);
        this.airportShuttleBookForm.get('fromAirportSelectControl')?.setErrors(null);

        this.airportShuttleBookForm.get('toLocationSelectControl')?.setValidators(null);
        this.airportShuttleBookForm.get('toLocationSelectControl')?.setValue(null);
        this.airportShuttleBookForm.get('toLocationSelectControl')?.setErrors(null);

        this.airportShuttleBookForm.get('fromPreferredSlotControl')?.setValidators(null);
        this.airportShuttleBookForm.get('toLocationSelectControl')?.setValue(null);
        this.airportShuttleBookForm.get('fromPreferredSlotControl')?.setErrors(null);

        this.selected_from_airport = null;
        this.selected_to_location = null;
        this.airportShuttleBookForm.value.fromPreferredSlotControl = null;
        break;
      }
      case 'round-trip': {
        //validate all
        this.airportShuttleBookForm.controls['fromAirportSelectControl'].setValidators([Validators.required]);
        this.airportShuttleBookForm.controls['toLocationSelectControl'].setValidators([Validators.required]);
        this.airportShuttleBookForm.controls['fromPreferredSlotControl'].setValidators([Validators.required]);

        this.airportShuttleBookForm.controls['fromLocationSelectControl'].setValidators([Validators.required]);
        this.airportShuttleBookForm.controls['toAirportSelectControl'].setValidators([Validators.required]);
        this.airportShuttleBookForm.controls['toPreferredSlotControl'].setValidators([Validators.required]);
        break;
      }
      default: {
        break;
      }
    }
  }

  selected_seats_changed(value: string): void {
    this.selected_seats = parseInt(value);
  }

  from_selected_airport_changed(value: string): void {
    this.selected_from_airport = value;
  }
  to_selected_prefferedLocation_changed(value: string): void {
    this.selected_to_location = value;
  }

  private addHours(time: string, hoursToAdd: number): string {
    // Split the time string into hours and minutes
    let [hours, minutes] = time.split(':').map(Number);

    // Calculate total minutes
    let totalMinutes = hours * 60 + minutes + hoursToAdd * 60;

    // Calculate new hours and minutes
    let newHours = Math.floor(totalMinutes / 60) % 24;
    let newMinutes = totalMinutes % 60;

    // Return the new time string
    return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
  }

  private setCODString() {
    this.informationalString = `The departure time from City of Dreams is ${
      this.selectedSlotDept
    }. There will be a stop at Ajax Hotel and the arrival time at the airport will be approximately ${this.addHours(this.selectedSlotDept, 1.5)}`;
  }

  private setCODSlots() {
    this.preferredslot_option_items = [
      { key: '06:30', text: '06:30', disabled: false },
      { key: '09:30', text: '09:30', disabled: false },
      { key: '11:30', text: '11:30', disabled: false },
      { key: '14:30', text: '14:30', disabled: false },
    ];
  }

  private setAjaxSlots() {
    this.preferredslot_option_items = [
      { key: '07:00', text: '07:00', disabled: false },
      { key: '10:00', text: '10:00', disabled: false },
      { key: '12:00', text: '12:00', disabled: false },
      { key: '15:00', text: '15:00', disabled: false },
    ];
  }
  private setAjaxString() {
    this.informationalString = `The departure time from Ajax Hotel is ${
      this.selectedSlotDept
    } and the arrival time at the airport will be approximately ${this.addHours(this.selectedSlotDept, 1.5)}`;
  }
  from_selected_prefferedAiport_changed(value: string): void {
    this.selected_from_location = value;
    if (this.selected_from_location === 'cod') {
      this.setCODSlots();
      this.selectedSlotDept = this.preferredslot_option_items[0].key;
      this.setCODString();
    } else if (this.selected_from_location === 'ajax') {
      this.setAjaxSlots();
      this.selectedSlotDept = this.preferredslot_option_items[0].key;

      this.setAjaxString();
    }
    this.cd.detectChanges();
    this.cd.markForCheck();
  }
  to_selected_airport_changed(value: string): void {
    this.selected_to_airport = value;
  }

  from_selected_prefferedslot_changed(value: string): void {
    this.airportShuttleBookForm.value.fromPreferredSlotControl = value;
  }
  to_selected_prefferedslot_changed(value: string): void {
    this.selected_to_preffered_slot = value;

    this.selectedSlotDept = this.selected_to_preffered_slot;
    if (this.selected_from_location === 'cod') {
      this.setCODString();
    } else if (this.selected_from_location === 'ajax') {
      this.setAjaxString();
    }

    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  postData$!: Observable<IStayTransportationTypeResponse>;
  responseState$!: Observable<ITransportationFormState>;

  // convenience getter for easy access to form fields
  get f() {
    return this.airportShuttleBookForm.controls;
  }

  ngOnInit(): void {
    this.setCODSlots();

    this.postData$ = this.stayTransportationService.postData$;
    this.responseState$ = this.getState(state => state);

    this.airportShuttleBookForm = this.formBuilder.group({
      nameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      surnameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      emailControl: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      phoneControl: ['', [Validators.required]],
      companyControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      tripSelectControl: ['', []],
      fromPreferredSlotControl: ['', [Validators.required]],
      toPreferredSlotControl: [],
      seatsSelectControl: [],
      fromAirportSelectControl: ['', [Validators.required]],
      fromLocationSelectControl: [],
      toAirportSelectControl: [],
      toLocationSelectControl: ['', [Validators.required]],
    });

    this.selected_from_location = 'cod';
    this.setCODString();
  }

  customerSupport() {
    this.router.navigate(['/contact'], { queryParams: { enquiry: 'transportation' } });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.airportShuttleBookForm.invalid) {
      return;
    } else {
      this.sendRequest();
    }
  }

  sendRequest() {
    const transportationObject: IStayTransportation = {
      name: this.airportShuttleBookForm.value.nameControl,
      surname: this.airportShuttleBookForm.value.surnameControl,
      email: this.airportShuttleBookForm.value.emailControl,
      seats: this.selected_seats,
      dialCode: this.airportShuttleBookForm.value.phoneControl.dialCode,
      phoneNumber: this.airportShuttleBookForm.value.phoneControl.number,
      companyName: this.airportShuttleBookForm.value.companyControl,
      fromAirport: this.selected_from_airport,
      fromLocation: this.selected_from_location,
      fromPreferredSlot: this.airportShuttleBookForm.value.fromPreferredSlotControl,
      toLocation: this.selected_to_location,
      toAirport: this.selected_to_airport,
      toPreferredSlot: this.selected_to_preffered_slot,
    };

    if (this.selected_trip_type_key === 'one-way-departure') {
      transportationObject.fromAirport = 'N/A';
      transportationObject.toLocation = 'N/A';
      transportationObject.fromPreferredSlot = 'N/A';
    }
    // console.log(transportationObject);

    this.stayTransportationService.stayTransportation(transportationObject);

    this.postData$.subscribe(res => {
      if (res.response?.statusCode == 201) {
        this.setState({
          success: true,
          return_msg: '',
        });
      } else {
        this.setState({
          success: false,
          return_msg: res.response?.message,
        });
      }
    });
  }
}
