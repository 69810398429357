<ng-container *ngIf="responseState$ | async as responseState">
    <div class="stay-header">
        <div class="content">
            <div class="left">
                <h3>We'll make your stay</h3>
                <h1>UNFORGETTABLE</h1>
            </div>
            <div class="right">
                <img class="header-icon" src="https://cdn-images.island-conference.com/pageheader/header_logo.png">
            </div>
        </div>
    </div>
    <app-stay-accommodation-list *ngIf="currentStep === 1 && !responseState.success"></app-stay-accommodation-list>
</ng-container>