export const playlistStreamConfig = {
    tracks: [
        {
            artistName: "Gianni Romano",
            trackName: "It's not right - Radio Edit",
            src: "https://islandconference.b-cdn.net/It's%20Not%20Right%20(feat.%20Helen%20Tesfazghi)%20(Radio%20Edit).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/its-not-right.jpg"
        },

        {
            artistName: "Swidish House Mafia",
            trackName: "Moth to a flame (with the weeknd mojo) - mojo remix",
            src: "https://islandconference.b-cdn.net/Moth%20To%20A%20Flame%20(Moojo%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/moth-to-a-flame.jpg"
        },
        {
            artistName: "Francis Mercier, Roland Clark",
            trackName: "Holy",
            src: "https://islandconference.b-cdn.net/Francis%20Mercier,%20Roland%20Clark%20-%20Holy.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/holy.jpg"
        },
        {
            artistName: "Sophie Hunger",
            trackName: "There Is Still Pain Left (Laolu Remix)",
            src: "https://islandconference.b-cdn.net/There%20Is%20Still%20Pain%20Left%20(Laolu%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/there-is-still-pain-left.jpg"
        },
        {
            artistName: "Julien Hess feat. Sandhaus",
            trackName: "Midnight Paradise",
            src: "https://islandconference.b-cdn.net/Julien%20Hess%20feat.%20Sandhaus%20-%20Midnight%20Paradise%20(MIDH%20029).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/midnight.jpg"
        },
        {
            artistName: "Notre Dame",
            trackName: "Kaneba (Original Mix)",
            src: "https://islandconference.b-cdn.net/Notre%20Dame%20-%20Kaneba%20(Original%20Mix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/kaneba.jpg"
        },
        {
            artistName: "Sebjak",
            trackName: "Aguacero (Original Mix)",
            src: "https://islandconference.b-cdn.net/Sebjak%20%20Aguacero%20(Original%20Mix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/aguacero.jpg"
        },
        {
            artistName: "Chambord",
            trackName: "La Cumbia (ABRACADABRA)",
            src: "https://islandconference.b-cdn.net/Chambord%20-%20La%20Cumbia%20(ABRACADABRA).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/la-cumbia.jpg"
        },
        {
            artistName: "Vamonos",
            trackName: "Moojo",
            src: "https://islandconference.b-cdn.net/Moojo%20-%20Vamonos.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/vamonos.jpg"
        },
        {
            artistName: "Moojo & Nico de Andrea Remix",
            trackName: "Buya",
            src: "https://islandconference.b-cdn.net/Buya%20(Moojo%20&%20Nico%20de%20Andrea%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/buya.jpg"
        },
        {
            artistName: "Yumi (Original Mix)",
            trackName: "Notre Dame",
            src: "https://islandconference.b-cdn.net/Notre%20Dame%20-%20Yumi%20(Original%20Mix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/yumni.jpg"
        },
        {
            artistName: "Adam Port, Cubicolor",
            trackName: "No Dancers (Adam Port Remix)",
            src: "https://islandconference.b-cdn.net/Cubicolor%20-%20No%20Dancers%20(Adam%20Port%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/no-dancers.jpg"
        },
        {
            artistName: "Stones & BonesToshi",
            trackName: "Amahloni (Manoo Remix)",
            src: "https://islandconference.b-cdn.net/Amahloni%20(Manoo%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/amahloni.jpg"
        },
        {
            artistName: "Blanka Mazimela",
            trackName: "Phezulu (Aero Manyelo Remix)",
            src: "https://islandconference.b-cdn.net/Blanka%20Mazimela%20-%20Phezulu%20(%20Aero%20Manyelo%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/phezulu.jpg"
        },
        {
            artistName: "Chelsea Como & Jacko",
            trackName: "Until Tomorrow (Da Africa Deep Remix)",
            src: "https://islandconference.b-cdn.net/Chelsea%20Como%20&%20Jacko%20-%20Until%20Tomorrow%20(Da%20Africa%20Deep%20Remix)%20MIDH%20034.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/until-tomorrow.jpg"
        },
        {
            artistName: "Kasango",
            trackName: "Closer",
            src: "https://islandconference.b-cdn.net/Kasango%20-%20Closer%20(MIDH%20032).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/closer.jpg"
        },
        {
            artistName: "DJEFF & Kasango",
            trackName: "Let You Go feat. Betty Gray (Main Mix)",
            src: "https://islandconference.b-cdn.net/DJEFF%20&%20Kasango%20-%20Let%20You%20Go%20feat.%20Betty%20Gray%20(Main%20Mix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/let-you-go.jpg"
        },
        {
            artistName: "Dj Beekay ft Lyrik Shoxen",
            trackName: "BlackOut",
            src: "https://islandconference.b-cdn.net/Dj%20Beekay%20ft%20Lyrik%20Shoxen%20-%20BlackOut.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/blackout.jpg"
        },
        {
            artistName: "Heaven Takes You Home (Moojo Remix)",
            trackName: "Connie Constance, MoojoSwedish, House Mafia",
            src: "https://islandconference.b-cdn.net/Heaven%20Takes%20You%20Home%20(Moojo%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/heaven-takes-you-home.jpg"
        },
        {
            artistName: "Confusion",
            trackName: "&MEAdam, PortAli, LoveRampa",
            src: "https://islandconference.b-cdn.net/Keinemusik%20(&ME,%20Rampa,%20Adam%20Port)%20-%20Confusion%20feat.%20Ali%20Love.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/confusion.jpg"
        },
        {
            artistName: "SirYLL",
            trackName: "Malawi (Abracadabra)",
            src: "https://islandconference.b-cdn.net/SirYLL%20-%20Malawi%20(Abracadabra).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/malawi.jpg"
        },
        {
            artistName: "Reborn",
            trackName: "Arodes, Moojo",
            src: "https://islandconference.b-cdn.net/Arodes,%20Moojo%20-%20Reborn.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/reborn.jpg"
        },
        {
            artistName: "Kaz James, Nick Morgan",
            trackName: "Not In Love",
            src: "https://islandconference.b-cdn.net/Not%20In%20Love.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/not-in-love.jpg"
        },
        {
            artistName: "Francis Mercier, Magic System, Nite freak",
            trackName: "Premier Gaou - Nitefreak Remix",
            src: "https://islandconference.b-cdn.net/Francis%20Mercier%20&%20Magic%20System%20-%20Premier%20Gaou%20(Nitefreak%20Extended%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/premier-gaou.jpg"
        },
        {
            artistName: "Zakes Bantwini & Kasango",
            trackName: "Osama (AfroZone Remix)",
            src: "https://islandconference.b-cdn.net/Osama%20(AfroZone%20Remix)%20Zakes%20Bantwini%20&%20Kasango.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/osama.jpg"
        },
        {
            artistName: "Adam Port, AWEN, Caiiro",
            trackName: "Your Voice - Adam Port Remix",
            src: "https://islandconference.b-cdn.net/Awen%20&%20Caiiro%20-%20Your%20Voice%20(Adam%20Port%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/your-voice.jpg"
        },
        {
            artistName: "Lyke Mathame",
            trackName: "Nothing Around Us",
            src: "https://islandconference.b-cdn.net/AL018%20-%20Mathame%20-%20Nothing%20Around%20Us%20(with%20Lyke).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/nothing-around-us.jpg"
        },
        {
            artistName: "Mathame",
            trackName: "Skywalking",
            src: "https://islandconference.b-cdn.net/AL024%20-%20Mathame%20-%20Skywalking.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/skywalking.jpg"
        },
        {
            artistName: "Mind Against",
            trackName: "Walking Away",
            src: "https://islandconference.b-cdn.net/AL035%20-%20Mind%20Against%20-%20Walking%20Away.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/mind-against.jpg"
        },
        {
            artistName: "Kölsch",
            trackName: "Two Birds",
            src: "https://islandconference.b-cdn.net/AL035%20-%20Mind%20Against%20-%20Walking%20Away.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/two-birds.jpg"
        },
        {
            artistName: "Fideles",
            trackName: "Wave Rider",
            src: "https://islandconference.b-cdn.net/AL037%20-%20Fideles%20-%20Wave%20Rider.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/wave-rider.jpg"
        },
        {
            artistName: "Neverwaves Safar",
            trackName: "Sweet Disposition",
            src: "https://islandconference.b-cdn.net/Safar%20(FR)%20ft.%20Neverwaves%20-%20Sweet%20Disposition%20(Radio%20Edit).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/sweet-disposition.jpg"
        },
        {
            artistName: "Diana Robert, Georgescu White",
            trackName: "How you feel",
            src: "https://islandconference.b-cdn.net/Robert%20Georgescu%20and%20White%20feat.%20Diana%20-%20How%20you%20feel%20%20Official%20Video.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/how-you-feel.jpg"
        },
        {
            artistName: "Dikla Hackmon, Paso Doble Safar",
            trackName: "Thousand And Two Nights - Paso Doble Remix",
            src: "https://islandconference.b-cdn.net/Safar%20(FR)%20feat.%20Dikla%20Hackmon%20-%20Thousand%20and%20Two%20Nights%20(Paso%20Doble%20Remix)%20MIDH%20Premiere.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/thousand.jpg"
        },
        {
            artistName: "Ben Böhmer, Jan Blomqvist",
            trackName: "The Space In Between - Ben Böhmer Remix",
            src: "https://islandconference.b-cdn.net/The%20Space%20In%20Between%20(Ben%20B%C3%B6hmer%20Extended%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/the-space-in-between.jpg"
        },
        {
            artistName: "Birds of Mind feat. Zemira Israel",
            trackName: "Just Let Go",
            src: "https://islandconference.b-cdn.net/Birds%20of%20Mind%20feat.%20Zemira%20Israel%20-%20Just%20Let%20Go.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/just-let-go.jpg"
        },
        {
            artistName: "Fideles, Ross Quinn",
            trackName: "Away With Me (Fideles Remix - Extended)",
            src: "https://islandconference.b-cdn.net/Away%20With%20Me%20(Fideles%20Remix%20-%20Extended).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/stream-away.jpg"
        },
        {
            artistName: "MAXI MERAKI",
            trackName: "Dare Your Move",
            src: "https://islandconference.b-cdn.net/Maxi%20Meraki%20-%20Dare%20Your%20Move%20(Official%20Audio).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/dare-your-move.jpg"
        },
        {
            artistName: "Dikla, HackmonSafar",
            trackName: "Hoshiana",
            src: "https://islandconference.b-cdn.net/Safar%20feat.%20Dikla%20Hackmon%20-%20Hoshiana%20(Original%20Mix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/hoshiana.jpg"
        },
        {
            artistName: "N’Dinga Gaba feat. Sahffi",
            trackName: "Summer Breeze",
            src: "https://islandconference.b-cdn.net/NDinga%20Gaba%20feat.%20Sahffi%20%20Summer%20Breeze%20(Fka%20Mash%20Re-glitch).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/summer-breeze.jpg"
        },
        {
            artistName: "Soulroots Feat Soulstar",
            trackName: "Sala Papa Ye",
            src: "https://islandconference.b-cdn.net/Soulroots%20&%20Soul%20Star%20-%20Sala%20Papa%20Ye%20(Original).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/sala-papa-ye.jpg"
        },
        {
            artistName: "Francis Coletta, Sparrow & BarbossaVinila von Bismark",
            trackName: "Morena",
            src: "https://islandconference.b-cdn.net/Sparrow%20&%20Barbossa,%20Vinila%20Von%20Bismark%20feat.%20Francis%20Coletta.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/morena.jpg"
        },
        {
            artistName: "BLOND:ISH, Shawni",
            trackName: "Wizard of Love",
            src: "https://islandconference.b-cdn.net/BLOND_ISH%20-%20Wizard%20of%20Love%20(Radio%20Edit)%20%5BRebirth%5D.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/wizard-of-love.jpg"
        },
        {
            artistName: "Brigitte Wickens, Liva KSimone Vitullo",
            trackName: "Feeling Good",
            src: "https://islandconference.b-cdn.net/Feeling%20Good%20(feat.%20Brigitte%20Wickens).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/feeling-good.jpg"
        },
        {
            artistName: "Abu Simbel",
            trackName: "WhoMadeWho",
            src: "https://islandconference.b-cdn.net/WhoMadeWho%20-%20Abu%20Simbel.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/abu-simbel.jpg"
        },
        {
            artistName: "Jessica Brankka, NOEMIE",
            trackName: "Too Many Roads - Radio Mix",
            src: "https://islandconference.b-cdn.net/Too%20Many%20Roads%20(Radio%20Mix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/too-many-roads.jpg"
        },
        {
            artistName: "Notre Dame",
            trackName: "Emowe",
            src: "https://islandconference.b-cdn.net/Notre%20Dame%20-%20Emowe%20(Sasson%20(FR)%20Remix)%20MIDH%20Premiere.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/emowe.jpg"
        },
        {
            artistName: "Birds of Mind",
            trackName: "Mi Pena",
            src: "https://islandconference.b-cdn.net/Birds%20Of%20Mind%20-%20Mi%20Pena.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/birds-of-mind.jpg"
        },
        {
            artistName: "Sebjak & Fahlberg",
            trackName: "Somebody - Edit",
            src: "https://islandconference.b-cdn.net/Sebjak%20&%20Fahlberg%20-%20'Somebody'%20%5BAbracadabra%20Music%5D.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/somebody.jpg"
        },
        {
            artistName: "Awen & Caiiro",
            trackName: "Your Voice (clean)",
            src: "https://islandconference.b-cdn.net/Awen%20&%20Caiiro%20-%20Your%20Voice%20(Original%20Mix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/your-voice-clean.jpg"
        },
        {
            artistName: "The Advocate",
            trackName: "Lunar Plane Remix",
            src: "https://islandconference.b-cdn.net/The%20Advocate%20-%20Ekata%20feat%20%20Toshi%20(Lunar%20Plane%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/ekata.jpg"
        },
        {
            artistName: "Jinadu, Nico MoranoSabb",
            trackName: "Solaris (Sabb Radiant Extended Mix)",
            src: "https://islandconference.b-cdn.net/Solaris%20(Sabb%20Radiant%20Extended%20Mix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/solaris.jpg"
        },
        {
            artistName: "Calamar, Crew Leon",
            trackName: "Rise",
            src: "https://islandconference.b-cdn.net/Rise.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/rise.jpg"
        },
        {
            artistName: "Aaaron",
            trackName: "2020 Souls",
            src: "https://islandconference.b-cdn.net/Aaaron%20-%202020%20Souls.mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/2020-souls.jpg"
        },
        {
            artistName: "Shouse",
            trackName: "Won't Forget You",
            src: "https://islandconference.b-cdn.net/SHOUSE%20-%20Won't%20Forget%20You%20(Eli%20&%20Fur%20Remix).mp3",
            albumPhoto: "https://cdn-images.island-conference.com/music-player/albums/wont-forget.jpg"
        },
    ],
    autoPlayNext: true,
    autoPlay: false,
}