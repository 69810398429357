import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faqData } from '../../data/faq-data';

import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { IFaqCategory, IFaqItem, SidebarService } from '@i-con/shared/common';

@Component({
  selector: 'app-faq-question',
  templateUrl: './faq-question.component.html',
  styleUrls: ['./faq-question.component.scss']
})
export class FaqQuestionComponent implements OnInit {

	public faqData: IFaqCategory[] = faqData;
	// category
	category_ref_name: any;
	category: IFaqCategory = {
		name: '',
		ref_name: '',
		alt:'',
		items: []
	};
	// question
	question_ref_name: any;
	question: IFaqItem = {
		ref_name: '',
		question: '',
		answer: '',
		answer_safe: ''
	};
	
	expanded: boolean = false;

	@Input() class_name:string = 'faq_page';
	@Input() phrase: string = 'Need Some Help?';
	@Input() title1: string = 'ASK US ANYTHING';
	@Input() title2: string = '';

	constructor(
		private activatedRouter: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private router: Router,
		private sidebarService: SidebarService) {
			this.sidebarService.expanded$.subscribe(expanded => (this.expanded = expanded));
	} 

	ngOnInit(): void {
        this.category_ref_name = this.activatedRouter.snapshot.paramMap.get('category_ref');
        this.question_ref_name = this.activatedRouter.snapshot.paramMap.get('question_ref_name');
		this.category = faqData.filter(cat => {
			return cat.ref_name == this.category_ref_name
		})[0];
		this.question = this.category.items.filter(q => {
			return q.ref_name == this.question_ref_name
		})[0];
		this.question.answer_safe = this.sanitizer.bypassSecurityTrustHtml(this.question.answer);
	}

	goToQuestion(question: IFaqItem, category: IFaqCategory): void {
		this.question_ref_name =  question.ref_name;
		this.question = question;
		this.question.answer_safe = this.sanitizer.bypassSecurityTrustHtml(this.question.answer);
		this.router.navigateByUrl('/faq/' + category.ref_name + '/' + question.ref_name);
	}
	goToCategory(category: IFaqCategory): void {
		this.router.navigateByUrl('/faq/' + category.ref_name);
	}

	goToHome(): void {
		this.router.navigateByUrl('/faq');
	}

}
