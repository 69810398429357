import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrimaryContactFormComponent } from '../../primary-contact-form/primary-contact-form.component';

@Component({
  selector: 'app-create-primary-contact',
  standalone: true,
  templateUrl: './create-primary-contact.component.html',
  styleUrls: ['./create-primary-contact.component.scss'],
  imports: [CommonModule, PrimaryContactFormComponent],
})
export class StayCreatePrimaryContactComponent implements OnInit {
  @Output('setPrimaryContact') setPrimaryContact: EventEmitter<any> = new EventEmitter();
  @Output('goToStep') goToStep: EventEmitter<any> = new EventEmitter();
  @Input() previousStep!: number;
  @Input() responseState: any;

  constructor() {}

  ngOnInit(): void {}

  createPrimaryContact($event: any) {
    const { primaryContact } = $event;
    this.setPrimaryContact.emit({ primaryContact });
  }

  goBack($event: any) {
    const { step } = $event;
    this.goToStep.emit({ step: step });
  }
}
