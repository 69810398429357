import { IFaqCategory } from "@i-con/shared/common";


export const faqData: IFaqCategory[] = [
    {
      id: 1,
      ref_name: 'general',
      name: 'General',
      alt:"Purple-lined vector of information icon on white background.",
      items: [
        {
          ref_name: "whats-is-i-icon",
          question: "What's All The Buzz About i-Con?",
          answer: "i-Con is your future affiliate conference! Whether you are looking to expand your affiliate program, discover new networking opportunities, or even partner up with some of the biggest names around, we can help!<br/></br/>\
          We are a fresh conference that brings together experts from various online industries to share the secrets of their success. You will be able to approach them with your questions as well as discuss possible future collaborations.<br/></br/>\
          You can get all these with the added bonus of trying your luck at Europe's largest casino resort in one of the most magical Mediterranean islands!"
        },
        {
          ref_name: "who-is-attending",
          question: "Who Is Attending & Who Can Attend i-Con?",
          answer: "We have established a solid line-up with some of the best networks in the online world!<br/></br/>\
          Our attendees are key people from a large number of different industries, predominantly working around affiliate marketing.<br/></br/>\
          Some of the largest brand names will be attending to give real and meaningful value to the event.<br/></br/>\
          Our lineup currently features:<br/></br/>\
          - Casino<br/>\
          - Crypto<br/>\
          - Dating<br/>\
          - E-Commerce<br/>\
          - Finance<br/>\
          - Games<br/>\
          - Payment Solutions<br/>\
          - Services<br/>\
          - Social<br/>\
          - Sweeps & Surveys<br/></br/>\
          ... And many more! So, join us and allow your brand to expand its horizons to reach new heights!"
        },
        {
          ref_name: "when-and-where",
          question: "When & Where Is i-Con Taking Place?",
          answer: "i-Con 2024 will open its doors on Thursday, the 30th and Friday, the 31st of May 2024!<br/></br/>\
          Don't miss i-Con's pre-event welcome party on the 29th of May 2024 for an opportunity to scope out potential contacts early!<br/></br/>\
          The conference will take place on one of the most beautiful islands in the Mediterranean. Located in Limassol, Cyprus, a place widely known to be a hub for the digital affiliate world!<br/></br/>\
          Our host will be the City of Dreams, Europe's largest Casino resort! All attendees will gather at the Expo area on both days of the event."
        },
        {
          ref_name: "how-do-i-get-to-i-con",
          question: "How Do I Get To i-Con?",
          answer: "i-Con will be taking place in Limassol, Cyprus, with the closest airports to the venue being the Paphos International Airport and Larnaca International Airport, which are both approximately a one-hour drive away.<br/></br/>\
          You can book a complimentary Shuttle Bus service through our dedicated \"Transportation\" page to get picked up from the airport but also be driven back on the day of your departure.<br/></br/>\
          Regarding the conference itself, if you've not booked a room with City Of Dreams, we would recommend booking a private ride to make sure you will get to the venue on time each day.<br/></br/>\
          Alternatively, you can also take our Shuttle Bus Services at one of the available slots.<br/></br/>\
          For more information on getting to and from the conference, please <a target='_blank' href='https://www.cityofdreamsmed.com.cy/en/overview/gettingatcityofdreams'>click here</a>."
        },
        {
          ref_name: "how-to-contact-others",
          question: "How Can I Contact Other i-Con Attendees?",
          answer: "You will be able to view and contact everyone attending the conference through i-Con's dedicated app!<br/></br/>\
          Our app is currently available to download through i-Con's official website as well as PlayStore & AppStore.<br/></br/>\
          If you'd like to find out more about our attendees and exhibitors, visit our \"Exhibitors\" page under the \"ATTEND\" tab."
        },
        {
          ref_name: "food-beverage",
          question: "Will i-Con Provide Food & Beverages?",
          answer: "All attendees are welcome to join us for unlimited \"Coffee and Refreshments\" on both days of the conference.<br/></br/>\
          As far as food goes, i-Con attendees who have secured Premium Tickets can visit the Aura Restaurant to enjoy a 5-star lunch experience.<br/></br/>\
          It's worth noting that our sponsors have ensured that all attendees will be able to enjoy some street food and snacks at i-Con's Food Market."
        }
      ]
    },
    {
      id: 2,
      ref_name: 'booths-sponsorships',
      name: 'Booths & Sponsorships',
      alt:"Purple-lined vector of two people sitting on a booth on white background.",
      items: [
        {
          ref_name: "book-a-booth",
          question: "How Can I Book A Booth With i-Con?",
          answer: "You can book your booth with i-Con through the website. Simply hover over the \"ATTEND\" tab and click \"Booths\" to be directed to our dedicated page.<br/></br/>\
          From there:<br/></br/>\
          - Select the booth you'd like to book<br/>\
          - Click \"Reserve your Booth\"<br/>\
          - Fill in the form with your details<br/>\
          - Click \"Reserve\"<br/><br/>\
          After that, an email confirming your booth reservation will be sent to your email.<br/><br/>\
          Please note that our three largest booths are fully booked. If you wish to secure a booth in our Expo space act fast and book yours now!<br/><br/>\
          For more information, please contact us at reservations@island-conference.com."
        },
        {
          ref_name: "which-booth",
          question: "Which Booth Would You Recommend?",
          answer: "Depending on the size of your team as well as your needs, each of our booths comes with complimentary tickets along with their own unique features.<br/></br/>\
          Additionally, i-Con's floor plan has been designed to provide all our exhibitors with a great amount of exposure.<br/></br/>\
          Please keep in mind that our largest booths are currently fully booked."
        },
        {
          ref_name: "customize-booth",
          question: "Can I Customize My Booth?",
          answer: "If you have a specific idea of how you'd like your booth to look, let us know!<br/></br/>\
          You can contact us with your custom booth request at reservations@island-conference.com<br/></br/>\
          Please keep in mind that our largest booths are currently fully booked."
        },
        {
          ref_name: "booth-location",
          question: "Can I Choose My Booth's Location In The Expo?",
          answer: "It's worth noting that the floorplan for the conference is adjusted according to our reservations.<br/></br/>\
          We currently cannot provide you with a fixed floor plan, meaning that you won't be able to choose your booth's location in the Expo."
        },
        {
          ref_name: "when-to-access-booth",
          question: "When Can I Access My Booth?",
          answer: "The Expo will be open to exhibitors for early access on the first day of the conference (30th of May 2024) at 9:00 am, an hour before the Expo officially opens to all attendees.<br/></br/>\
          If you have any particular requirements prior to your arrival, please contact us at reservations@island-conference.com ahead of time in order to make the necessary arrangements for you."
        },
        {
          ref_name: "artwork-restrictions",
          question: "Does i-Con Have Any Booth Artwork Restrictions?",
          answer: "Once your booking has been confirmed, we will be in touch with you for further details.<br/></br/>\
          We ask that you keep in mind that the conference will be taking place at a 5* Resort, so any artwork featuring aggressive content may not be accepted.<br/></br/>\
          If your artwork is deemed unsuitable, you will be informed ahead of time to make amendments and re-submit it for approval."
        },
        {
          ref_name: "giveaways",
          question: "Can Exhibitors Do Giveaways?",
          answer: "As an Exhibitor, you will be able to showcase your choice of giveaways to your booth visitors.<br/></br/>\
          However, please keep a premium approach to your choice of gifts, and avoid any branded shirts with explicit content as well as contextually aggressive or provocative items."
        },
        {
          ref_name: "i-con-sponsor",
          question: "How Can I Become A Sponsor For i-Con?",
          answer: "Our list of Sponsorships has been created in order to give exposure to all types of teams and budgets.<br/></br/>\
          You can find out all about them on our website, under the \"Attend\" tab.<br/></br/>\
          You can also send us an email at <span style=\"font-weight:bold\">reservations@island-conference.com</span> to get more information on the matter."
        },
        {
          ref_name: "sponsorship-idea",
          question: "Can I Suggest A Sponsorship Idea?",
          answer: "We are open to any new and innovative sponsorship ideas we might not already have on our dedicated page.<br/></br/>\
          If you'd like to become a sponsor in a unique and fun way, and have an idea on how to do so, then contact our team at <span style=\"font-weight:bold\">reservations@island-conference.com</span>!"
        },
        {
          ref_name: "rebook-my-booth",
          question: "Can I Re-Book My Booth Or Sponsorship For Future i-Con Conferences?",
          answer: "A few months after the end of the conference, we will be reaching out to all our Exhibitors and Sponsors to give them the opportunity to renew their booth or sponsorship deal.<br/></br/>\
          They will be able to book the same booths and sponsorships ahead of time for the following year's conference.<br/></br/>\
          Whether you are an existing or new attendee, we will try our utmost to treat everyone fairly."
        },
        {
          ref_name: "i-con-refund-policy",
          question: "What Is i-Con's Refund Policy On Booths & Sponsorships?",
          answer: "We understand that things don't always go as planned.<br/></br/>\
          If you cannot attend i-Con but have already booked a booth or reserved a sponsorship with us, please advise our Terms & Conditions to check your refund eligibility."
        }
      ]
    },
    {
      id: 3,
      ref_name: 'tickets',
      name: 'Tickets',
      alt:"Purple-lined vector of cut tickets on white background.",
      items: [
        {
          ref_name: "purchase",
          question: "How Can I Purchase An i-Con Ticket?",
          answer: "You can purchase a ticket for i-Con through our website.<br/></br/>\
          From our homepage, Simply click on \"PURCHASE TICKETS\" to be directed to our dedicated page."
        },
        {
          ref_name: "payment-options",
          question: "What Payment Options Does i-Con Provide?",
          answer: "i-Con currently accepts Visa and Mastercard as our sole payment options.<br/></br/>\
          Please bear with us as we are working on incorporating more payment options, including SecurePay, Apple pay, Google pay, PayPal, and more."
        },
        {
          ref_name: "purchase-on-behalf",
          question: "Is It Possible To Purchase A Ticket And Book It On Behalf Of Someone Else?",
          answer: "You can purchase as many tickets as you wish, and you will be able to set each ticket under other individuals' names during the booking process."
        },
        {
          ref_name: "transfer-or-share",
          question: "Can I Transfer Or Share My Ticket With Other People?",
          answer: "Unfortunately, you cannot share your ticket with other people.<br/></br/>\
          Please note that for safety purposes, each ticket will have a unique 4-digit code which will be directly sent to you once the ticket has been purchased.<br/></br/>\
          As per i-Con's policy, once your ticket has been booked you will not be able to transfer or share it with colleagues, partners, or others."
        },
        {
          ref_name: "haze-events",
          question: "Does My i-Con Ticket Grant Me Access To Haze Events Taking Place During The Conference?",
          answer: "Premium Ticket holders have the priviledge of a guaranteed place in all of our event guestlists.<br/></br/>\
          If you are a Standard or Stay & Attend Ticket holder please contact us at events@island-conference.com to secure a spot for you and your team.<br/></br/>\
          We recommend reserving a table ahead of time due to the volume of interest.<br/></br/>\
          Please keep in mind that the availability of tables and entrance to each venue will be provided on a first-come-first-served basis.<br/></br/>\
          For more information, check out our \"Haze Events\" section."
        },
        {
          ref_name: "refund-policy",
          question: "What Is i-Con's Refund Policy If I Am Unable To Attend?",
          answer: "In case you have already purchased your tickets but you are unable to attend i-Con, please advise our Terms & Conditions for further information on your eligibility for a refund."
        },
      ]
    },
    {
      id: 6,
      ref_name: 'speeches-seminars',
      name: 'Speeches & Seminars',
      alt:"Purple-lined vector of a person in t-shirt standing at a podium with two mics on either side of them on white background.",
      items: [
        {
          ref_name: "become-speaker",
          question: "Can I Become A Speaker At i-Con?",
          answer: "If you wish to become a speaker, you can find out more information about the process and availability by contacting us via events@island-conference.com.<br/></br/>\
          Please keep in mind that our speakers are hand-picked based on their industry-relevant experience, achievements as well as their credibility."
        },
        {
          ref_name: "topics",
          question: "What Topics Will Be Covered During The Talks?",
          answer: "We make sure that our speakers are qualified to share their knowledge with our attendees, taking into consideration that the topics covered are not only relevant but also applied strategies and proven case studies that have worked for them in the past.<br/></br/>\
          Based on the variety of industries, you can expect to come across various subjects:<br/></br/>\
          - Google Ads</br/>\
          - Lead Generation</br/>\
          - Payment Alternatives</br/>\
          - SEO</br/>\
          - And Much More...!<br/></br/>\
          Through our speeches, interviews as well as presentations, you will surely find a multitude of talking points that will answer your questions!"
        },
        {
          ref_name: "slots-limited",
          question: "Why Are i-Con's Speaker Slots Limited?",
          answer: "In an attempt to take a different approach to the regular presentation schedules you typically find in other conferences, we have limited our speaker slots.<br/></br/>\
          This was done in order to give our attendees the opportunity to attend as many speeches, panels, as well as presentations as they could.<br/></br/>\
          Our speakers are experts in their field, covering topics relevant to multiple industries, so we did our best to avoid speech overlaps as much as we could."
        },
        {
          ref_name: "participate-pose-questions",
          question: "Can Attendees Participate & Pose Questions During The Scheduled Talks?",
          answer: "All attendees at i-Con's speeches will be given the opportunity to pose their own questions at the end of each session.<br/></br/>\
          No matter the type of speech you'd like to attend, we always dedicate an amount of time for our speakers to interact with the audience."
        },
        {
          ref_name: "attend-talks",
          question: "Can I Attend Any Of The Talks With My i-Con Ticket?",
          answer: "Your i-Con ticket grants you access to all speeches under the official schedule.<br/></br/>\
          All speeches will be taking place at Speaker Stages A & B of City of Dreams Mediterranean, which has ample space for the attendees. However, if you'd like to secure a seat up close, we recommend showing up a bit earlier."
        },
        
      ]
    },
    {
      id: 4,
      ref_name: 'hotel-bookings',
      name: 'Hotel Bookings',
      alt:"Purple-lined vector of calendar with a tick on a day on white background.",
      items: [
        {
          ref_name: "venue-rooms",
          question: "How Many Rooms Can The Conference Venue Host?",
          answer: "We have been in close contact with the City of Dreams Casino resort and have secured a number of rooms for i-Con attendees at a fixed and discounted rate.<br/></br/>\
          Premium Ticket holders get priority in their hotel booking accommodations through the i-Con website.<br/></br/>\
          Please contact us with regards to booking your room with City of Dreams soonest, as they are the highest in demand!"
        },
        {
          ref_name: "hotel-recommendations",
          question: "Do You Have Any Other Hotel Recommendations?",
          answer: "If you would prefer a hotel with more privacy than the City Of Dreams, then look no further!<br/></br/>\
          We have personally visited and stayed at 2 of the best hotels in Limassol, and can guarantee that you will be avoiding any \"Tourist Traps\":<br/></br/>\
          - Ajax Hotel<br/>\
          - Atlantica Oasis Hotel<br/>\
          <br/>\
          All of which you will be able to get a quotation through i-Con's official website by visiting the \"STAY\" tab.<br/></br/>\
          Please keep in mind that all rooms booked via i-Con's platforms will be at a discounted rate, with priority booking for Premium Ticket holders"
        },
        {
          ref_name: "book-a-room",
          question: "How Can I Book A Room?",
          answer: "You can book a room in any of the hotels recommended by visiting our \"Hotels\" page and selecting \"Request Quotation\".<br/></br/>\
          Depending on which hotel you choose to stay at, once a reservation is made, our dedicated team will provide you with all the options regarding the rooms and their availability.<br/></br/>\
          Please contact us at <span style=\"font-weight:bold\">accommodation@island-conference.com</span> to find out more details about your stay during i-Con."
        },
        {
          ref_name: "upgrade-my-room",
          question: "Can I Upgrade My Room?",
          answer: "If you wish to upgrade your room, we would be glad to accommodate your request.<br/></br/>\
          Please contact us at <span style=\"font-weight:bold\">accommodation@island-conference.com</span> with your query in order to assist you the soonest possible."
        },
        {
          ref_name: "book-longer-hotel-stay",
          question: "Can I Book A Longer Hotel Stay With i-Con's Rates?",
          answer: "We understand that some of our attendees might wish to extend their stay beyond the 2-day duration of our conference.<br/></br/>\
          However, it's important to note that i-Con's discounted rates are applicable only for the duration of the conference itself and do not extend to any additional days afterward."
        },
        {
          ref_name: "breakfast-included",
          question: "Will Breakfast Be Included In My Stay?",
          answer: "Along with the discounted rates we managed to secure, we made sure that your booking will also include breakfast for each day of your stay.<br/></br/>\
          This is true for all hotels under the \"STAY\" tab on the i-Con website and will be effective for both days of the conference.<br/></br/>\
          Please keep in mind that Premium Ticket holders get priority in all bookings through the i-Con website."
        }
      ]
    },
    {
      id: 5,
      ref_name: 'haze',
      name: 'Haze Events',
      alt:"Purple-lined vector of party popper on white background.",
      items: [
        {
          ref_name: "what-is-haze-all-about",
          question: "What Is Haze All About?",
          answer: "Haze are the best lifestyle event organizers on the island, featuring private and public events as well as guaranteed great parties!<br/></br/>\
          i-Con has collaborated with Haze to ensure an entertaining time at every single i-Con event!<br/></br/>\
          Check out the \"Haze Team\" tab on our site for further information."
        },
        {
          ref_name: "attend-haze-events",
          question: "Can I Attend Haze Events With My i-Con Ticket?",
          answer: "Premium Ticket owners are guaranteed to be in the guestlist of both the Opening & Closing parties.<br/></br/>\
          We always ensure to keep a section of each venue free for non-reservations, however due to the volume of interest, we recommend reserving a table ahead of time if you wish to avoid the long lines and to secure a place for your team.<br/></br/>\
          Please keep in mind that the availability of tables and entrance to each venue will be provided on a first-come-first-served basis.<br/></br/>\
          For more information, check out our \"Haze Events\" section."
        },
        {
          ref_name: "best-experience",
          question: "How Can I Have The Best Experience With Haze?",
          answer: "Haze guarantees some of the best events and parties on the island, all you have to do is show up!<br/></br/>\
          However, if you'd like to ensure your team will not have to wait in lines or stand without seats, you can always reserve a table.<br/></br/>\
          By reserving a table, you will guarantee yourself a spot at the party, you will be able to take a shorter queue to the event, while also securing some of the best premium drinks and shisha services on the island! "
        },
        {
          ref_name: "reservation",
          question: "How Can I Make A Reservation?",
          answer: "If you wish to reserve a table for any of our Haze events, then visit the relevant page and send your <a href='https://island-conference.com/plan/events'>reservation enquiry</a> for the events you are interested in.<br/></br/>\
          You will be asked to fill in some information with regards to your reservation and a member of our team will be in touch within 48 hours.<br/></br/>\
          Please contact us at <span style=\"font-weight:bold\">events@island-conference.com</span> if you would like to know more information about reservations."
        },
        {
          ref_name: "minimum-charge",
          question: "Is There A Minimum Charge To Make A Reservation?",
          answer: "You will be able to choose between three reservation options:<br/></br/>\
          - VIP</br/>\
          - Semi-VIP</br/>\
          - Normal</br/>\
          Each reservation option comes with a minimum charge (depending on the event/venue) expected for the table.<br/></br/>\
          For any additional people on the table, the charges will depend on your reservation type.<br/></br/>\
          Each reservation type includes:<br/></br/>\
          <span style=\"text-decoration:underline;\">VIP</span><br/></br/>\
          Sofa seats for 8-12 People</br/>\
          <span style=\"text-decoration:underline;\">Semi-VIP</span><br/></br/>\
          Sofa seats for 8-12 People</br/>\
          <span style=\"text-decoration:underline;\">Normal</span><br/></br/>\
          High table and chairs for 5-10 People</br/>\
          * Please note that for each reservation, the amount you spend on each booking will correspond to the amount of consumption you are entitled to."
        },
        {
          ref_name: "choose-where-to-be-seated",
          question: "Can I Choose Where My Team Will Be Seated?",
          answer: "Unfortunately, even if you make a reservation, you won't be able to choose where your team will be seated during any of the Haze events.<br/></br/>\
          We always try to provide the best service possible for our attendees, for further information on table reservations, please contact us at <span style=\"font-weight:bold\">events@island-conference.com</span>."
        },
        {
          ref_name: "type-of-music",
          question: "What Kind Of Music Will Be Played At The Haze Events?",
          answer: "Haze brings you some of the best music on the island!<br/></br/>\
          Popular local and worldwide DJs will make sure that the music will be just as refined as the drinks!<br/></br/>\
          You will be able to party the night away with music from our Guest DJs, so grab your drink and join us on the dance floor!"
        },
      ]
    },
    {
      id: 7,
      ref_name: 'other',
      name: 'Other',
      alt:"Purple-lined vector of a speech bubble with ellipses on white background.",
      items: [
        {
          ref_name: "means-of-transportation",
          question: "Which Means Of Transportation Are Available?",
          answer: "We would advise planning ahead in terms of transportation if you have not booked your stay with City Of Dreams.<br/></br/>\
          Visit our \"Transportation\" page for more information on how to take complimentary Shuttle Buses to and from the airports, as well as all the events.<br/></br/>\
          It's worth mentioning that Uber is not available in Cyprus, while the island's public transport options are also not as easily accessible."
        },
        {
          ref_name: "i-con-dress-code",
          question: "Is There A Specific Dress Code For i-Con?",
          answer: "Your brand's individuality is your biggest advantage, so we encourage you to bring your own style to the conference!<br/></br/>\
          However, we kindly ask that you keep your dress code to a smart casual standard and avoid any provocative clothing, as i-Con is a professional networking conference."
        },
        {
          ref_name: "kind-of-weather",
          question: "What Kind Of Weather Can I Expect?",
          answer: "As Cyprus is known for being a holiday destination featuring sunny days all year round, we have chosen to strategically host this year's i-Con at the end of May.<br/></br/>\
          Just in case things get a little too hot, all inside areas of the resort will be fully air-conditioned and kept at a cool temperature."
        },
        {
          ref_name: "other-questions",
          question: "Do You Have A Question Not In Our FAQ?",
          answer: "For any questions not covered in our FAQ, please email us accordingly:<br/></br/>\
          For any booth or sponsorship reservations:</br/><span style=\"font-weight:bold\">reservations@island-conference.com</span><br/><br/>\
          For any hotel reservation requests and information:<br/><span style=\"font-weight:bold\">accommodation@island-conference.com</span><br/></br/>\
          For more information on our scheduled events:<br/><span style=\"font-weight:bold\">events@island-conference.com</span><br/></br/>\
          For more information regarding Haze events:<br/><span style=\"font-weight:bold\">events@island-conference.com</span><br/></br/>\
          For any general information that is not included in the above:<br/><span style=\"font-weight:bold\">info&#64;island-conference.com</span><br/></br/>\
          You can also follow i-Con's social media handles for our latest updates on:<br/></br/>\
          <style>.social-icon{padding:0 30px;}@media only screen and (max-width: 500px){.social-icon{padding:0 10px;}}</style>\
          <div class='social-media-links'>\
          <a href='https://www.facebook.com/IslandConference' target='_blank'><img style=\"height:50px;box-shadow:0px 0px 6px rgba(0,42,84,0.3);border-radius:30px; margin-right: 10px;\" class='social-icon fb_icon' src='https://cdn-images.island-conference.com/faq/facebook.svg'></a>\
          <a href='https://www.instagram.com/islandconference_icon/' target='_blank'><img style=\"height:50px;box-shadow:0px 0px 6px rgba(0,42,84,0.3);border-radius:30px; margin-right: 10px;\" class='social-icon insta_icon' src='https://cdn-images.island-conference.com/faq/instagram.svg'></a>\
          <a href='https://www.linkedin.com/company/islandconference-i-con/' target='_blank'><img style=\"height:50px;box-shadow:0px 0px 6px rgba(0,42,84,0.3);border-radius:30px;\" class='social-icon linked_icon' src='https://cdn-images.island-conference.com/faq/linkedin.svg'></a>\
          </div>"
        },
      ]
    },
]