import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISponsorshipWaitingList, ISponsorshipWaitingListResponse, ReserverSponsorshipService } from '@i-con/shared/common';
import { CountryISO, NgxIntlTelInputComponent, NgxIntlTelInputModule, SearchCountryField } from 'ngx-intl-tel-input';
import { Observable } from 'rxjs';

export interface IPendingSponsorship {
  sponsorship_name: string;
  sponsorship_id: number;
}

@Component({
  selector: 'i-con-popup-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgxIntlTelInputModule],
  templateUrl: './popup-form.component.html',
  styleUrl: './popup-form.component.scss',
})
export class PopupFormComponent implements OnInit {
  @ViewChild(NgxIntlTelInputComponent) telInputt!: NgxIntlTelInputComponent;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;

  waitingListForm!: UntypedFormGroup;
  submitted: boolean = false;

  sponsoshipWaitingList$!: Observable<ISponsorshipWaitingListResponse>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public sponsorshipData: IPendingSponsorship,
    private formBuilder: UntypedFormBuilder,
    private reserveSponsorshiService: ReserverSponsorshipService
  ) {}

  ngOnInit() {
    this.sponsoshipWaitingList$ = this.reserveSponsorshiService.pendingData$;

    this.waitingListForm = this.formBuilder.group({
      nameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      lastnameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      emailControl: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      phoneControl: ['', [Validators.required]],
      notesControl: [''],
    });
  }

  submitForm() {
    this.submitted = true;

    if (!this.waitingListForm.valid) {
      return;
    }

    const waitingListInfo: ISponsorshipWaitingList = {
      name: this.waitingListForm.value.nameControl,
      surname: this.waitingListForm.value.lastnameControl,
      email: this.waitingListForm.value.emailControl,
      dialCode: this.waitingListForm.value.phoneControl.dialCode,
      phoneNumber: this.waitingListForm.value.phoneControl.number,
      notes: this.waitingListForm.value.notesControl,
      sponsorship: {
        id: this.sponsorshipData.sponsorship_id,
      },
    };

    this.reserveSponsorshiService.pendingSponsorship(waitingListInfo);
  }

  resetFormState() {
    this.reserveSponsorshiService.resetFormState();
  }

  get f() {
    return this.waitingListForm.controls;
  }
}
