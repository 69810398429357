<div class="login-dialog active_nudge" >
    <div class="header-section">
        <button class="close-btn" mat-button mat-dialog-close>X</button>
    </div>

    <div class="login-form">
        <login-form></login-form>
    </div>
    <div class="login-footer">
    </div>
</div>