import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { INextSteps, ISubText } from '@i-con/shared/common';
import { cardsData } from './data/successful-api-call.data';

@Component({
  selector: 'successful-api-call',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './successful-api-call.component.html',
  styleUrl: './successful-api-call.component.scss',
})
export class SuccessfulApiCallComponent {
  @Input() heading!: string;
  @Input() subText!: ISubText;
  @Input() redirect: boolean = false;
  @Input() newPath: string = '';

  @Output() goBackInitialPage = new EventEmitter();

  cardsData: INextSteps[] = cardsData;

  constructor(private router: Router) {}

  goBack() {
    if (this.redirect) {
      this.router.navigate([this.newPath]);
    } else {
      this.goBackInitialPage.emit({ step: 1 });
    }
  }
}
