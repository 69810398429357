import { Injectable } from "@angular/core";
import { FormArray } from "@angular/forms";


import { Observable } from "rxjs";
import { StateService } from "./state.service";


interface ISubmitted {
    submitted: boolean
}

const initialValue: ISubmitted = {
    submitted: false
}


@Injectable({ providedIn: 'root' })
export class BookingFormGroupService extends StateService<ISubmitted>{

    submitted$: Observable<boolean> = this.getState((state) => state.submitted);

    constructor() {
        super(initialValue);
    }

    public checkErrors(formArray: FormArray, error: string, fieldNumber: number):boolean {
        
        const errors = formArray.controls[fieldNumber].errors;

        if (errors) {
            if (error in errors) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    setSubmitted(value: boolean) {
        this.setState({submitted: value});
    }
    
}