import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EnvironmentService, IResendCode, ResendCodeService } from '@i-con/shared/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'resend-code-form',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './resend-code-form.component.html',
  styleUrl: './resend-code-form.component.scss',
})
export class ResendCodeFormComponent implements OnInit {
  resendCodeForm!: UntypedFormGroup;
  submitted: boolean = false;
  numberOfMinutes: number = 5;
  timer: number = 60000 * this.numberOfMinutes; //1 minute = 60000 ms
  timerString: string = '';
  timerIsRunning: boolean = false;
  @Output() backToLogin = new EventEmitter<void>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private resendCodeService: ResendCodeService,
    private cookieService: CookieService,
    private environment: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.setTimerInitialState();
    this.resendCodeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
    });
  }
  get f() {
    return this.resendCodeForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.resendCodeForm.invalid) {
      return;
    } else {
      const email: IResendCode = {
        email: this.resendCodeForm.controls['email'].value,
      };
      this.resendCodeService.postRequestResendCode(email).subscribe(data => {
        const statusCode = data.response.statusCode;
        if (statusCode === 200 || statusCode === 201) {
          
          this.timerIsRunning = true;
          this.startTimer();
          const resendCodeThrottleCookie = this.cookieService.get('rct');
          
          if (resendCodeThrottleCookie === '') {
            const expires = new Date(Date.now() +  this.numberOfMinutes * 60000);
            this.cookieService.set('rct', '1',  {expires: expires, path: '/', domain: this.environment.getDomain}); // not working
          }
        }
      });
    }
  }

  private startTimer() {
    let intervalId = setInterval(() => {
      if (this.timer === 0 || this.timerString === '0:00') {
        this.timerIsRunning = false;
        this.setTimerInitialState();
        clearInterval(intervalId);
      } else {
        this.timer -= 1000;
        const minutes = Math.floor(this.timer / 60000);
        const seconds = ((this.timer % 60000) / 1000).toFixed(0);
        const secondsNumber = Number(seconds);
        this.timerString = minutes + ':' + (secondsNumber < 10 ? '0' : '') + seconds;
      }
    }, 1000);
  }

  private setTimerInitialState() {
    this.timer = 60000 * this.numberOfMinutes;
    const minutes = Math.floor(this.timer / 60000);
    const seconds = ((this.timer % 60000) / 1000).toFixed(0);
    const secondsNumber = Number(seconds);
    this.timerString = minutes + ':' + (secondsNumber < 10 ? '0' : '') + seconds;
  }
}
