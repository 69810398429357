<div class="header-container">
    <h3>Transportation</h3>
    <h1>TO AND FROM THE VENUE</h1>
</div>
<div class="container">
    <p class="intro">
        Rest assured that we will take good care of your transportation needs during the conference. The i-Con team is
        well prepared to arrange your transfers to and from the Larnaca & Paphos International Airports, as well as City
        of Dreams Mediterranean and the Ajax Hotel. Plus, we have ensured your transport from both hotels to our
        exclusive events and back!
        <br /><br />
        We have secured several shuttle buses, which will be operational at different intervals during the conference.
        If you prefer to have more private means of transportation, you can contact our Customer Support to book a taxi
        for you (4 or 6 seats) at pre-set prices provided by our exclusive associates.
    </p>
    <div class="airport" *ngIf="responseState$ | async as responseState">
        <div class="card">
            <div class="images">
                <div
                    style="--url: url('https://cdn-images.island-conference.com/stay/transportation/larnaca-airport-image.jpg')">
                </div>
                <div
                    style="--url: url('https://cdn-images.island-conference.com/stay/transportation/paphos-airport.jpg')">
                </div>
                <div
                    style="--url: url('https://cdn-images.island-conference.com/stay/transportation/city-of-dreams.jpg')">
                </div>
            </div>
            <div class="body">
                <div class="content">
                    <p>
                        Our Shuttle Service will operate from the Larnaca & Paphos International Airports towards both
                        City of Dreams Mediterranean and the Ajax Hotel. A branded i-Con kiosk will be placed at the
                        airports' arrivals for your convenience. Mini-buses will be leaving the airports every 2 hours
                        as per the below scheduled time slots:
                    </p>
                    <p class="pre-form">Please select your pickup point and destination. You can also pick a designated
                        time slot for your convenience. Once submitted, you can leave the rest to us!</p>
                    <div class="success-msg flexr" *ngIf="responseState.success">
                        <div class="left">
                            <h2>Your Booking Request Has Been Received!</h2>
                            <p>
                                Thank you for booking your transportation for i-Con! We have received your request and
                                it will be reviewed by our team shortly.
                                <br />
                                Feel free to contact us for more information regarding the transportation options from
                                City of Dreams to the i-Con Exclusive Events and back. If you wish to arrange a private
                                taxi (4 or 6 seater) for your company, we can accept bookings at pre-set rates as
                                outlined by our exclusive associates.
                            </p>
                        </div>
                        <div class="right">
                            <img class="success"
                                src="https://cdn-images.island-conference.com/contact/email_sent.gif" />
                        </div>
                    </div>
                    <form class="form" [formGroup]="airportShuttleBookForm" *ngIf="!responseState.success">
                        <div class="form-row">
                            <div class="form-col trip-type-seats">
                                <app-select class="trip-type-select" select_class="rounded-border"
                                    [items]="trip_type_option_items" [selected_item_id]="selected_trip_type_key"
                                    (onSelectedOptionChange)="selected_trip_type_changed($event)"
                                    prefixIconUrl="https://cdn-images.island-conference.com/stay/transportation/round-trip.svg"
                                    formControlName="tripSelectControl">
                                </app-select>
                                <app-select class="seats-select" select_class="rounded-border"
                                    [items]="seats_option_items" [selected_item_id]="seats_option_items[0].key"
                                    (onSelectedOptionChange)="selected_seats_changed($event)"
                                    prefixIconUrl="https://cdn-images.island-conference.com/stay/transportation/bus.svg"
                                    formControlName="seatsSelectControl">
                                </app-select>
                            </div>
                        </div>
                        <div class="form-row" *ngIf="this.selected_trip_type_key !== 'one-way-departure'">
                            <div class="form-col line-title">
                                Wednesday 29/05/2024
                            </div>
                        </div>
                        <div class="form-row airport-to-venue"
                            *ngIf="this.selected_trip_type_key !== 'one-way-departure'">
                            <div class="form-col">
                                <app-select class="full-width" select_class="rounded-border full-width"
                                    [items]="airports_option_items" placeholder="Preferred Airport"
                                    (onSelectedOptionChange)="from_selected_airport_changed($event)"
                                    prefixIconUrl="https://cdn-images.island-conference.com/stay/transportation/start-icon.svg"
                                    formControlName="fromAirportSelectControl">
                                </app-select>
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['fromAirportSelectControl'].errors"
                                    class="invalid-feedback select-error">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['fromAirportSelectControl'].errors['required']">
                                        Preferred Airport is required
                                    </div>
                                </div>
                            </div>
                            <img class="arrow"
                                src="https://cdn-images.island-conference.com/stay/transportation/arrow.svg" />
                            <div class="form-col to-venue">
                                <app-select class="full-width" select_class="rounded-border full-width"
                                    [items]="venue_option_items"
                                    (onSelectedOptionChange)="to_selected_prefferedLocation_changed($event)"
                                    placeholder="Preferred Location"
                                    prefixIconUrl="https://cdn-images.island-conference.com/stay/transportation/finish-icon.svg"
                                    formControlName="toLocationSelectControl">
                                </app-select>
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['toLocationSelectControl'].errors"
                                    class="invalid-feedback select-error">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['toLocationSelectControl'].errors['required']">
                                        Preferred Location is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-col">
                                <app-select class="full-width preferred-slot" select_class="rounded-border full-width"
                                    [items]="preferredslot_option_itemsFirst" placeholder="Slot"
                                    (onSelectedOptionChange)="from_selected_prefferedslot_changed($event)"
                                    prefixIconUrl="https://cdn-images.island-conference.com/stay/transportation/timer.svg"
                                    formControlName="fromPreferredSlotControl">
                                </app-select>
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['fromPreferredSlotControl'].errors"
                                    class="invalid-feedback select-error">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['fromPreferredSlotControl'].errors['required']">
                                        Slot is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row"
                            *ngIf="this.selected_trip_type_key === 'round-trip' || this.selected_trip_type_key === 'one-way-departure'">
                            <div class="form-col line-title">
                                Saturday 01/06/2024
                            </div>
                        </div>
                        <div class="form-row venue-to-airport"
                            *ngIf="this.selected_trip_type_key === 'round-trip' || this.selected_trip_type_key === 'one-way-departure'">
                            <div class="form-col">
                                <app-select class="full-width" select_class="rounded-border full-width"
                                    [items]="venue_option_items" placeholder="Preferred Location"
                                    [selected_item_id]="venue_option_items[0].key"
                                    (onSelectedOptionChange)="from_selected_prefferedAiport_changed($event)"
                                    prefixIconUrl="https://cdn-images.island-conference.com/stay/transportation/start-icon.svg"
                                    formControlName="fromLocationSelectControl">
                                </app-select>
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['fromLocationSelectControl'].errors"
                                    class="invalid-feedback select-error">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['fromLocationSelectControl'].errors['required']">
                                        Preferred Location is required
                                    </div>
                                </div>
                            </div>
                            <img class="arrow"
                                src="https://cdn-images.island-conference.com/stay/transportation/arrow.svg" />
                            <div class="form-col to-airport">
                                <app-select class="full-width" select_class="rounded-border full-width"
                                    [items]="airports_option_items" placeholder="Preferred Airport"
                                    (onSelectedOptionChange)="to_selected_airport_changed($event)"
                                    prefixIconUrl="https://cdn-images.island-conference.com/stay/transportation/finish-icon.svg"
                                    formControlName="toAirportSelectControl"
                                    [selected_item_id]="airports_option_items[0].key">
                                </app-select>
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['toAirportSelectControl'].errors"
                                    class="invalid-feedback select-error">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['toAirportSelectControl'].errors['required']">
                                        Preferred Airport is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-col">
                                <app-select class="full-width preferred-slot" select_class="rounded-border full-width"
                                    [items]="preferredslot_option_items" placeholder="Slot"
                                    (onSelectedOptionChange)="to_selected_prefferedslot_changed($event)"
                                    prefixIconUrl="https://cdn-images.island-conference.com/stay/transportation/timer.svg"
                                    formControlName="toPreferredSlotControl"
                                    [selected_item_id]="preferredslot_option_items[0].key">
                                </app-select>
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['toPreferredSlotControl'].errors"
                                    class="invalid-feedback select-error">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['toPreferredSlotControl'].errors['required']">
                                        Slot is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="note"
                            *ngIf="this.selected_trip_type_key === 'round-trip' || this.selected_trip_type_key === 'one-way-departure'">

                            <img src="https://cdn-images.island-conference.com/icons/info.svg" alt="Information">
                            <label>{{informationalString}}</label>

                        </div>
                        <div class="form-row">
                            <div class="form-col form-title">
                                Fill in your info
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-col name-field">
                                <input class="input-name-field" type="text" placeholder="Name"
                                    formControlName="nameControl" />
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['nameControl'].errors"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['nameControl'].errors['required']">
                                        Name is required
                                    </div>
                                    <div *ngIf="f['nameControl'].errors['minlength']">
                                        A minimum of
                                        {{
                                        (airportShuttleBookForm.touched || submitted) &&
                                        f["nameControl"].errors["minlength"].requiredLength
                                        }}
                                        characters length is required
                                    </div>
                                    <div *ngIf="f['nameControl'].errors['maxlength']">
                                        A maximum of
                                        {{
                                        (airportShuttleBookForm.touched || submitted) &&
                                        f["nameControl"].errors["maxlength"].requiredLength
                                        }}
                                        characters length is required
                                    </div>
                                </div>
                            </div>

                            <div class="form-col surname-field">
                                <input class="input-name-field" type="text" placeholder="Surname"
                                    formControlName="surnameControl" />
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['surnameControl'].errors"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['surnameControl'].errors['required']">
                                        Surname is required
                                    </div>
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['surnameControl'].errors['minlength']">
                                        A minimum of
                                        {{
                                        (airportShuttleBookForm.touched || submitted) &&
                                        f["surnameControl"].errors["minlength"].requiredLength
                                        }}
                                        characters length is required
                                    </div>
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['surnameControl'].errors['maxlength']">
                                        A maximum of
                                        {{
                                        (airportShuttleBookForm.touched || submitted) &&
                                        f["surnameControl"].errors["maxlength"].requiredLength
                                        }}
                                        characters length is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-col email-field">
                                <input class="input-email-field" type="text" placeholder="Email Address"
                                    formControlName="emailControl" />
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['emailControl'].errors"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['emailControl'].errors['required']">
                                        Email is required
                                    </div>
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['emailControl'].errors['email']">
                                        Please write a valid email
                                    </div>
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['emailControl'].errors['maxlength']">
                                        A maximum of
                                        {{
                                        (airportShuttleBookForm.touched || submitted) &&
                                        f["emailControl"].errors["maxlength"].requiredLength
                                        }}
                                        characters length is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-col phone-field">
                                <ngx-intl-tel-input [cssClass]="'phone-input'" [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [enableAutoCountrySelect]="true" [maxLength]="15"
                                    [selectedCountryISO]="CountryISO.Cyprus" [phoneValidation]="true"
                                    [separateDialCode]="true" name="phone" formControlName="phoneControl">
                                </ngx-intl-tel-input>
                                <div *ngIf="submitted && f['phoneControl'].errors" class="invalid-feedback">
                                    <div *ngIf="f['phoneControl'].errors['required']">Phone is required</div>
                                    <div *ngIf="f['phoneControl'].errors && !f['phoneControl'].errors['required']">
                                        Invalid Input</div>
                                </div>
                            </div>
                            <div class="form-col company-field">
                                <input class="input-company-field" type="text" placeholder="Company Name"
                                    formControlName="companyControl" />
                                <div *ngIf="(airportShuttleBookForm.touched || submitted) && f['companyControl'].errors"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['companyControl'].errors['required']">
                                        Company Name is required
                                    </div>
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['companyControl'].errors['minlength']">
                                        A minimum of
                                        {{
                                        (airportShuttleBookForm.touched || submitted) &&
                                        f["companyControl"].errors["minlength"].requiredLength
                                        }}
                                        characters length is required
                                    </div>
                                    <div
                                        *ngIf="(airportShuttleBookForm.touched || submitted) && f['companyControl'].errors['maxlength']">
                                        A maximum of
                                        {{
                                        (airportShuttleBookForm.touched || submitted) &&
                                        f["companyControl"].errors["maxlength"].requiredLength
                                        }}
                                        characters length is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row"></div>
                        <div class="buttons">
                            <button class="main-btn" disabled type="submit">Book Now</button>
                        </div>
                        <!-- <div class="note">
                            <img src="https://cdn-images.island-conference.com/icons/info.svg" alt="Information">
                            <label>We're sorry for the inconvenience, but all the bus seats have been reserved.</label>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="venue-events">
        <h2>From The Venue <span>To the i-Con exclusive events</span> And Back</h2>
        <p class="subtitle">For the three i-CON events, our Shuttle Service will operate as follows:</p>
        <div class="event-card" *ngFor="let event of eventsTransportationData">
            <transportation-event-card [transportationEvent]="event"></transportation-event-card>
        </div>
    </div>

</div>