import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISideBarItem, SidebarService } from '@i-con/shared/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'i-con-sidebar-item',
  standalone: true,
  imports: [CommonModule,],
  templateUrl: './sidebar-item.component.html',
  styleUrl: './sidebar-item.component.scss',
})
export class SidebarItemComponent implements OnInit {
  @Input() item!: ISideBarItem;

  expanded: boolean = false;
  isOpen$!: Observable<boolean>;

  constructor(
    private sidebarService: SidebarService
  ) {}

  ngOnInit(): void {
    this.isOpen$ = this.sidebarService.expanded$;
    this.isOpen$.subscribe(val => (this.expanded = val));
  }
}
