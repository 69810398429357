import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStayTransportationTypeResponse } from '../interfaces/responses/stay-transportation-response-interface';
import { EnvironmentService } from './environment.service';
import { StateService } from './state.service';

interface IStayTransportationState {
  postData: IStayTransportationTypeResponse;
}

const initialStatusValue: any = {
  postData: {},
};

@Injectable({ providedIn: 'root' })
export class StayTransportationService extends StateService<IStayTransportationState> {
  postData$: Observable<IStayTransportationTypeResponse> = this.getState(state => state.postData);

  constructor(private http: HttpClient, private _environment: EnvironmentService) {
    super(initialStatusValue);
  }

  stayTransportation(stayTransportationInfo: object) {
    this.http.post<IStayTransportationTypeResponse>(`${this._environment.getApiUrl}/transportation`, stayTransportationInfo).subscribe({
      error: (error: any) => {
        this.setState({ postData: error.error });
      },
      next: (response: any) => {
        this.setState({ postData: response });
      },
    });
  }
}
