<div class="wrapper">
    <div class="bg">
        <button class="close-btn" mat-button mat-dialog-close (click)="closeDialog()">
            <img src="https://cdn-images.island-conference.com/icons/close-icon.svg" alt="Close">
        </button>
    </div>

    <div class="content">
        <h2>It looks like you're about to be signed out.</h2>
        <p>Would you like to end your session?</p>
        <div class="buttons">
            <button class="main-btn cancel" (click)="closeDialog()">Cancel</button>
            <button class="main-btn" (click)="continueClick()">Continue</button>
        </div>

    </div>
</div>