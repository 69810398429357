import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { AuthorizationService, EnvironmentService, IBoothReservationOrder, IOrderResponse, ISponsorshipTypeReservationLoggedIn } from '../../index';

@Injectable({ providedIn: 'root' })
export class OrderService {
  constructor(private http: HttpClient, private _environment: EnvironmentService, private authService: AuthorizationService) {}

  getBoothOrders(): Observable<IOrderResponse> {
    return this.http.get<IOrderResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/order?only=booths`, {
      withCredentials: true,
    });
  }

  reserveBooth(reservation: IBoothReservationOrder) {
    return this.http.post<IOrderResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/order/booth`, reservation, {
      withCredentials: true,
    });
  }

  getSponsorshipOrders(): Observable<IOrderResponse> {
    return this.http.get<IOrderResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/order?only=sponsorships`, {
      withCredentials: true,
    });
  }

  reserveSponsorship(reserveDetails: ISponsorshipTypeReservationLoggedIn) {
    return this.http.post<IOrderResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/order/sponsorship`, reserveDetails, {
      withCredentials: true,
    });
  }
}
