import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventDialogFormComponent } from '../event-dialog-form/event-dialog-form.component';
import { GuestListFormComponent } from '../guest-list-form/guest-list-form.component';
import { TripReservationComponent } from '../trip-reservation/trip-reservation.component';
import { VipTableFormComponent } from '../vip-table-form/vip-table-form.component';

@Component({
  selector: 'app-plan-events',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './plan-events.component.html',
  styleUrls: ['./plan-events.component.scss'],
})
export class PlanEventsComponent implements OnInit {
  @Input() class_name: string = 'planevents_page';
  @Input() referenceClass: string = '';

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  playVid(video_id: string) {
    var myVideo: any = document?.getElementById(video_id);
    if (myVideo) {
      myVideo.muted = true;
      myVideo.controls = false;
      myVideo.play();
    }
  }

  pauseVid(video_id: string) {
    var myVideo: any = document?.getElementById(video_id);
    myVideo?.pause();
  }

  openReserveDialog(header_title: string) {
    event?.preventDefault();
    const dialogRef = this.dialog.open(EventDialogFormComponent, {
      maxHeight: '80vh',
      maxWidth: '95vw',
      width: '850px',
      autoFocus: false,
      data: header_title,
      disableClose: true,
    });
  }

  openVIPReserveDialog(header_title: string, event_class: string) {
    event?.preventDefault();
    const dialogRef = this.dialog.open(VipTableFormComponent, {
      maxHeight: '80vh',
      maxWidth: '95vw',
      width: '850px',
      autoFocus: false,
      data: { header_title, event_class },
      disableClose: true,
    });
  }

  openGuestListDialog(header_title: string, event_class: string, sponsor: string) {
    event?.preventDefault();
    const dialogRef = this.dialog.open(GuestListFormComponent, {
      maxHeight: '80vh',
      maxWidth: '95vw',
      width: '850px',
      autoFocus: false,
      data: { header_title, event_class, sponsor },
      disableClose: true,
    });
  }

  openTripDialog() {
    event?.preventDefault();
    const dialogRef = this.dialog.open(TripReservationComponent, {
      maxHeight: '80vh',
      maxWidth: '95vw',
      width: '950px',
      autoFocus: false,
      panelClass: 'paddless',
    });
  }
}
