<ng-container *ngIf="sponsoshipWaitingList$ | async as sponsoshipWaitingList">
	<div class="popup-container">
		<img class="close-icon" (click)="resetFormState()" mat-dialog-close src="https://cdn-images.island-conference.com/sponsorships/close.svg">
		<div class="top">
			<div class="content">
				<h2>
					<span>{{sponsorshipData.sponsorship_name}}</span><br>
					PENDING STATUS
				</h2>
			</div>
		</div>

		<div class="bottom" *ngIf="sponsoshipWaitingList.response.statusCode !== 201">
			<p>
				The Current Sponsorship Is Under Pending Status And It Is Unofficially Reserved By An Interested Party. If You Would Like To Lock It, Once It Becomes Available, Join The Waiting List And We Contact You Soon, With More Updates.
			</p>
			<form class="form-container" [formGroup]="waitingListForm" (ngSubmit)="submitForm()">
				<div class="row">
					<div class="field-wrapper">
						<input class="field-input" type="text" placeholder="First Name" formControlName="nameControl">
						<div
						*ngIf="(waitingListForm.touched || submitted) && f['nameControl'].errors"
						class="invalid-feedback">
						<div *ngIf="(waitingListForm.touched || submitted) && f['nameControl'].errors['required']">
							Name is required
						</div>
						<div *ngIf="f['nameControl'].errors['minlength']">
							A minimum of
							{{
								(waitingListForm.touched || submitted) &&
									f["nameControl"].errors["minlength"].requiredLength
							}}
							characters length is required
						</div>
						<div *ngIf="f['nameControl'].errors['maxlength']">
							A maximum of
							{{
								(waitingListForm.touched || submitted) &&
									f["nameControl"].errors["maxlength"].requiredLength
							}}
							characters length is required
						</div>
					</div>
					</div>
					<div class="field-wrapper">
						<input class="field-input" type="text" placeholder="Last Name" formControlName="lastnameControl">
						<div
						*ngIf="(waitingListForm.touched || submitted) && f['lastnameControl'].errors"
						class="invalid-feedback">
						<div *ngIf="(waitingListForm.touched || submitted) && f['lastnameControl'].errors['required']">
							Surname is required
						</div>
						<div *ngIf="(waitingListForm.touched || submitted) && f['lastnameControl'].errors['minlength']">
							A minimum of
							{{
								(waitingListForm.touched || submitted) &&
									f["lastnameControl"].errors["minlength"].requiredLength
							}}
							characters length is required
						</div>
						<div *ngIf="(waitingListForm.touched || submitted) && f['lastnameControl'].errors['maxlength']">
							A maximum of
							{{
								(waitingListForm.touched || submitted) &&
									f["lastnameControl"].errors["maxlength"].requiredLength
							}}
							characters length is required
						</div>
					</div>
					</div>
				</div>
				<div class="row">
					<div class="field-wrapper">
						<input class="field-input" type="text" placeholder="Email Address" formControlName="emailControl">
						<div
						*ngIf="(waitingListForm.touched || submitted) && f['emailControl'].errors"
						class="invalid-feedback">
						<div *ngIf="(waitingListForm.touched || submitted) && f['emailControl'].errors['required']">
							Email is required
						</div>
						<div *ngIf="(waitingListForm.touched || submitted) && f['emailControl'].errors['email']">
							Please write a valid email
						</div>
						<div *ngIf="(waitingListForm.touched || submitted) && f['emailControl'].errors['maxlength']">
							A maximum of
							{{
								(waitingListForm.touched || submitted) &&
									f["emailControl"].errors["maxlength"].requiredLength
							}}
							characters length is required
						</div>
					</div>
					</div>
					<div class="field-wrapper">
						<ngx-intl-tel-input
								[cssClass]="'field-input'"
								[enablePlaceholder]="true"
								[searchCountryFlag]="true"
								[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
								[enableAutoCountrySelect]="true"
								[maxLength]="15"
								[selectedCountryISO]="CountryISO.Cyprus"
								[phoneValidation]="true"
								[separateDialCode]="true"
								name="phone"
								formControlName="phoneControl">
							</ngx-intl-tel-input>
							<div *ngIf="submitted && f['phoneControl'].errors" class="invalid-feedback">
								<div *ngIf="f['phoneControl'].errors['required']">Phone is required</div>
								<div *ngIf="f['phoneControl'].errors && !f['phoneControl'].errors['required']">Invalid Input</div>
							</div>
					</div>
				</div>
				<div class="row">
					<div class="textarea-wrapper">
						<textarea placeholder="Notes" formControlName="notesControl"></textarea>
					</div>
				</div>
				<button class="main-btn">Add to waiting list</button>
			</form>
		</div>
		<div class="success-container" *ngIf="sponsoshipWaitingList.response.statusCode === 201" >
			<img class="success-icon" class="calendar-icon" src="https://cdn-images.island-conference.com/homepage/tick.svg">
			<h2>You have been added to the waiting list!</h2>
			<hr>
			<h3>
				A member of our team will contact you with more updates once the sponsorship becomes available.
			</h3>
		</div>
	</div>	
</ng-container>