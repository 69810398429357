import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, TransferState } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { makeStateKey } from '@angular/platform-browser';
import {
  AuthorizationService,
  IBookingFormReservation,
  IOrderResponse,
  ISponsorshipType,
  ISponsorshipTypeReservation,
  ISponsorshipTypeReservationLoggedIn,
  ISponsorshipTypeResponse,
  PlatformService,
  ReserverSponsorshipService,
  SponsorshipService,
  StateService,
} from '@i-con/shared/common';
import { Observable } from 'rxjs';
import { BookingFormGroupComponent } from '../booking-form-group/booking-form-group.component';
import { SuccessfulApiCallComponent } from '../successful-api-call/successful-api-call.component';
import { sponsorshipSuccessData } from './data/sponsorship-success-data';
import { SponsorshipsListComponent } from './sponsorships-list/sponsorships-list.component';
declare let window: any;

interface IReserveSponsorshipState {
  success: boolean;
  return_msg: string;
}

const initialState: IReserveSponsorshipState = {
  success: false,
  return_msg: '',
};

interface IStep {
  step: number;
}

interface IBookingDetails {
  sponsorshipId: number;
  quantity: number;
  name: string;
  price: number;
}

@Component({
  selector: 'app-sponsorships',
  standalone: true,
  templateUrl: './sponsorships.component.html',
  styleUrls: ['./sponsorships.component.scss'],
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, BookingFormGroupComponent, SuccessfulApiCallComponent, SponsorshipsListComponent],
})
export class SponsorshipsComponent extends StateService<IReserveSponsorshipState> implements OnInit {
  constructor(
    private sponsorshipService: SponsorshipService,
    private platformService: PlatformService,
    private reserveSponsorshipService: ReserverSponsorshipService,
    private transferState: TransferState,
    private authService: AuthorizationService
  ) {
    super(initialState);
  }

  @Input() showOnlyAvailableSponsorships: boolean = false;
  @Input() expanded: boolean = false;
  allSponsorhips!: ISponsorshipType[];
  selectedValue: number = 3;
  availableSponsorshipsIndex: number = 2;
  sponsorshipData$!: Observable<ISponsorshipType[]>;
  sortedSponsoships$!: Observable<ISponsorshipType[]>;
  postData$!: Observable<ISponsorshipTypeResponse>;
  orderData$!: Observable<IOrderResponse>;
  sponsorshipResponseState$ = this.getState(state => state);

  class_name = 'sponsorships_page';
  selectedSponsorshipId: number = 0;
  sponsorshipQuantity: number = 0;
  sponsorshipPrice: number = 0;
  sponsorshipName: string = '';

  currentStep: number = 1;
  submitted = false;

  isLoggedIn: boolean = false;

  sponsorshipSuccessData = sponsorshipSuccessData;

  // public testSponsor = testData;

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.sponsorshipData$ = this.sponsorshipService.data$;
    this.sortedSponsoships$ = this.sponsorshipService.sortedSponsorhip$;
    this.postData$ = this.reserveSponsorshipService.postData$;
    this.orderData$ = this.reserveSponsorshipService.orderData$;
    this.sponsorshipResponseState$ = this.getState(state => state);
    this.sponsorshipService.getSponsorships(this.showOnlyAvailableSponsorships);
    this.sponsorshipData$.subscribe(res => {
      this.allSponsorhips = res;

      if (this.showOnlyAvailableSponsorships) {
        this.allSponsorhips = this.allSponsorhips.filter(s => s.status === this.availableSponsorshipsIndex);
      }
      this.sponsorshipService.addValues(this.allSponsorhips);
    });
  }

  bookingFormDetails(bookingInfo: IBookingFormReservation) {
    const bookingDetails: ISponsorshipTypeReservation = {
      reservation: {
        sponsorshipTypes: [
          {
            sponsorshipTypeId: this.selectedSponsorshipId,
            quantity: this.sponsorshipQuantity,
          },
        ],
      },
      contact: {
        name: bookingInfo.name,
        surname: bookingInfo.surname,
        email: bookingInfo.email,
        termsAgreement: bookingInfo.termsAgreement,
        optinConsent: bookingInfo.optinConsent,
      },
      billing: {
        address: bookingInfo.address,
        city: bookingInfo.city,
        country: bookingInfo.country,
        postalCode: bookingInfo.postalCode,
        companyName: bookingInfo.companyName,
        vatNumber: bookingInfo.vatNumber,
      },
    };

    this.sendPostRequest(bookingDetails);
  }

  loggedInReservation() {

    const bookingDetails: ISponsorshipTypeReservationLoggedIn = {
      reservation: {
        sponsorshipTypes: [
          {
            sponsorshipTypeId: this.selectedSponsorshipId,
            quantity: this.sponsorshipQuantity,
          },
        ],
      }
    }

    this.reserveSponsorshipService.reserveSponsorshipLoggedIn(bookingDetails);

    this.changeSuccessState(bookingDetails, true);
  }

  goToForm($event: IBookingDetails) {
    this.selectedSponsorshipId = $event.sponsorshipId;
    this.sponsorshipQuantity = $event.quantity;
    this.sponsorshipName = $event.name;
    this.sponsorshipPrice = $event.price;
    this.currentStep = 2;
  }

  goToStep(step: IStep): void {
    this.currentStep = step.step;
    if (this.showOnlyAvailableSponsorships) this.allSponsorhips = this.allSponsorhips.filter(s => s.status === this.availableSponsorshipsIndex);
    
    this.sponsorshipService.addValues(this.allSponsorhips);

    this.setState({
      success: false,
      return_msg: '',
    });
    const TRST_SPONSORSHIPS = makeStateKey('sponsorships');
    this.transferState.set(TRST_SPONSORSHIPS, null);
    this.sponsorshipService.getSponsorships(this.showOnlyAvailableSponsorships);
  }

  sortSponsorships(statusNumber: number) {
    if (statusNumber == 3) {
      this.sponsorshipService.addValues(this.allSponsorhips);
    } else {
      const sortedSponsorhips = this.allSponsorhips.filter(res => res.status == statusNumber);
      this.sponsorshipService.addValues(sortedSponsorhips);
    }
  }

  changeSuccessState(reservationDetails: ISponsorshipTypeReservationLoggedIn | ISponsorshipTypeReservation, loggedIn: boolean) {

    if (loggedIn) {
      this.orderData$.subscribe(res => {
        if (res.response?.statusCode == 201) {
          this.setState({
            success: true,
            return_msg: '',
          });

          this.addTrackingDetails(reservationDetails);
          
        } else {
          this.setState({
            success: false,
            return_msg: res.response?.message,
          });
        }
      });
    }
    else {
      this.postData$.subscribe(res => {
        if (res.response?.statusCode == 201) {
          this.setState({
            success: true,
            return_msg: '',
          });
          this.addTrackingDetails(reservationDetails);
        } else {
          this.setState({
            success: false,
            return_msg: res.response?.message,
          });
        }
      });
    }
  }

  addTrackingDetails(reservationDetails: ISponsorshipTypeReservationLoggedIn | ISponsorshipTypeReservation) {
    if (this.platformService.isBrowser) {
      window?.gtag('event', 'conversion', {
        send_to: 'AW-10940770656/7PgMCP-6zoYYEODS--Ao',
        transaction_id: '',
      });
    }
    //window?.lintrk('track', { conversion_id: 12195858 });
    window?.fbq('trackCustom', 'SposnorshipPurchase', {
      content_category: 'sponsorships',
      content_ids: [reservationDetails.reservation.sponsorshipTypes[0]?.sponsorshipTypeId],
      content_name: this.sponsorshipName,
      content_type: 'product',
      contents: [
        { id: reservationDetails.reservation.sponsorshipTypes[0]?.sponsorshipTypeId, quantity: reservationDetails.reservation.sponsorshipTypes[0]?.quantity },
      ],
    });
  }

  sendPostRequest(bookingDetails: ISponsorshipTypeReservation) {
    this.submitted = true;

    this.reserveSponsorshipService.reserverSponsorshipItem(bookingDetails);

    this.changeSuccessState(bookingDetails, false);
  }
}
