import { Injectable } from '@angular/core';
import { IDiscountType } from '../interfaces/discount-type.interface';

@Injectable({
  providedIn: 'root',
})
export class CalculateDiscountService {
  calculateDiscountedPrice(price: number, discounts: IDiscountType[]): number {
    discounts?.forEach(discount => {
      price = Math.round(((price * (1 - discount.percentage)) / 100) * 100);
    });
    return price;
  }
}
