import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { PlaylistAudioStream } from '@i-con/shared/common';

@Component({
  selector: 'i-con-native-music-player',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './native-music-player.component.html',
  styleUrl: './native-music-player.component.scss',
})
export class NativeMusicPlayerComponent implements OnInit {

  @Input() isPlaying: boolean = false;
    artistName: string = '';
    trackName: string = '';
    albumPhoto: string = '';
    inTransition: boolean = false;
    @HostBinding('class.hidden') protected isHidden = false;
    constructor(private playlistAudioStream: PlaylistAudioStream, private ref: ChangeDetectorRef) {
        //playlistAudioStream.play();
    }

    ngOnInit(): void {
        this.playlistAudioStream.getState().pipe().subscribe((state: any) => {
            this.isPlaying = state.playing;
            this.artistName = state.trackInfo.currentTrackArtist;
            this.trackName = state.trackInfo.currentTrackName;
            this.albumPhoto = state.trackInfo.currentAlbumPhoto;
            this.ref.markForCheck();
        });
    }

    toogglePlay() {
        this.isPlaying ? this.playlistAudioStream.pause() : this.playlistAudioStream.play();
    }

    play(): void {
        this.playlistAudioStream.play();
    }

    pause(): void {
        this.playlistAudioStream.pause();
    }

    next(): void {
        if (this.isPlaying) {
            this.inTransition = true;
            this.playlistAudioStream.next();
        }

    }

    previous(): void {
        if (this.isPlaying) {
            this.playlistAudioStream.previous();
        }

    }
}
