import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { hotelsData } from '@i-con/shared/ui';
import { NavButtonComponent } from '../../nav-button/nav-button.component';

export interface IReservation {
  startDate: string;
  endDate: string;
  totalPersons: number;
  hotelName: string;
}

@Component({
  selector: 'app-stay-accommodation-list',
  standalone: true,
  templateUrl: './stay-accommodation-list.component.html',
  styleUrls: ['./stay-accommodation-list.component.scss'],
  imports: [CommonModule, NavButtonComponent, MatDatepickerModule, MatNativeDateModule],
})
export class StayAccommodationListComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private dateAdapter: DateAdapter<Date>, private formBuilder: UntypedFormBuilder) {
    this.dateAdapter.setLocale('en-GB');
  }

  hotelsInfo = hotelsData;

  navigateToHotel(hotelName: string) {
    return this.router.url.includes('stay') ? ['/stay/accommodation/request/' + hotelName] : ['/accommodation/request/' + hotelName];
  }

  ngOnInit(): void {}
}
