
<ng-container *ngIf="responseState$ | async as responseState">
	<div class="accomondation-header">
		<div class="content">
			<div class="left">
				<h3>These Hotels Have The Island Conference</h3>
				<h1>STAMP OF APPROVAL</h1>
			</div>
			<div class="right">
				<img class="header-icon" src="https://cdn-images.island-conference.com/pageheader/header_logo.png">
			</div>
		</div>		
	</div>

	<app-stay-accommodation-request
		ngSkipHydration
		*ngIf="currentStep === 1 && !responseState.success"
		[selectedId]="hotelId"
		(stayInformation)="stayInformation($event)"></app-stay-accommodation-request>
	<app-create-primary-contact
		*ngIf="currentStep === 2 && !responseState.success"
		[previousStep]="1"
		(setPrimaryContact)="setPrimaryContact($event)"
		(goToStep)="goToStep($event)"
		[responseState]="responseState"></app-create-primary-contact>

	<div class="success-container" *ngIf="responseState.success">
		<div class="container">
			<div class="success-msg flexr">
				<div class="left">
					<h2>Thank you for your request!</h2>
					<p>
						A member of our team will be in touch with you shortly. Please be patient as we get back you.
						<br />
						We would advise on checking your spam folder if you have not received a reply from us within 24
						hours.
					</p>
				</div>
				<div class="right">
					<img class="success" src="https://cdn-images.island-conference.com/contact/email_sent.gif" />
				</div>
			</div>
		</div>
	</div>
</ng-container>
