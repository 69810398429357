import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BookingFormGroupService, IFormField } from '@i-con/shared/common';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'i-con-terms-form',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './terms-form.component.html',
  styleUrl: './terms-form.component.scss',
})

export class TermsFormComponent implements OnInit {
    
    @Input() fieldsPart3: IFormField[] = [];
    @Input() submitted: boolean = false;
	@Input() showInformationSection: boolean = false;
    termsForm!: FormGroup;

    controlValidations: FormControl[] = [];
    public dialogRef!: MatDialogRef<TermsAndConditionsComponent | PrivacyPolicyComponent>;

    constructor(
        private dialog: MatDialog,
        private bookingFormGroupService: BookingFormGroupService,
        private parentForm: FormGroupDirective,
    ) {

    }

    ngOnInit(): void {
        this.termsForm = this.parentForm.form;

        this.fieldsPart3.forEach((field) => {
			const validations: ValidatorFn[] = [];

			Object.entries(field.validations).forEach((validation) => {
				const key: keyof typeof Validators = validation[0] as keyof typeof Validators;

				// validation without parameters (eg. Validators.required)
				const validatorFunction = Validators[key] as () => ValidatorFn;
				
				// validation with parameters (eg. Validators.maxLength(10))
				const validatorFunctionWithParam = (Validators[key] as (param: number | string | RegExp) => ValidatorFn)(validation[1]);

				if (typeof validation[1] !== "boolean") {
					validations.push(validatorFunctionWithParam);
				}
				else {
					validations.push(validatorFunction);
				}
			});

			if (field.fieldType === 'checkbox') {
				const formControl = new FormControl(false, validations);
				this.controlValidations.push(formControl);
			}
			else {
				const formControl = new FormControl('', validations);
				this.controlValidations.push(formControl);
			}
			
		});

        this.termsForm.addControl('part3terms', new FormGroup({
			part3: new FormArray(this.controlValidations)
		}))
    }

    openTerms(){
		event?.preventDefault();
		this.dialogRef = this.dialog.open(TermsAndConditionsComponent, {
			maxHeight: '80vh',
			maxWidth: '85vw',
			autoFocus: false,
		});
	}

	openPrivacy() {
		event?.preventDefault();
		this.dialogRef = this.dialog.open(PrivacyPolicyComponent, {
			maxHeight: '80vh',
			maxWidth: '85vw',
			autoFocus: false,
		});
	}

    checkErrors(fieldNumber: number, error: string){
		const formGroup = this.termsForm.get('part3terms');
		const mFormArray = formGroup?.get('part3') as FormArray;

		return this.bookingFormGroupService.checkErrors(mFormArray, error, fieldNumber);
	}

    get f() {
		const formGroup = this.termsForm.get('part3terms');
		const mFormArray = formGroup?.get('part3') as FormArray;
		return mFormArray.controls;
	}
}
