import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService, SidebarService } from '@i-con/shared/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'i-con-download-app',
  standalone: true,
  imports: [CommonModule, SlickCarouselModule],
  templateUrl: './download-app.component.html',
  styleUrl: './download-app.component.scss',
})
export class DownloadAppComponent implements OnInit {

  
  @Input() class_name: string = 'downloadapp-page';
	@Input() phrase: string = 'i-Con at your';
	@Input() title1: string = 'FINGERTIPS';

  expanded: boolean = false;
  appUrl: string = "";
  
  constructor(
		private router: Router,
    private sidebarService: SidebarService,
    private environment: EnvironmentService
    ) {
      this.sidebarService.expanded$.subscribe(expanded => (this.expanded = expanded));
     }

  ngOnInit(): void {
   this.appUrl = this.environment.getAppUrl;
  }

  mobileAppSlides = [
    {title: "Home", content: "Check out what the participants are up to during the conference!",img:"https://cdn-images.island-conference.com/download_app/home.png", alt:'Mobile phone screen displaying the i-Con application\'s home screen. Highlighting a picture of the city of dreams.'},
    {title: "Discover", content: "Uncover the complete list of our exhibitors, sponsors and attendees",img:"https://cdn-images.island-conference.com/download_app/discover.png", alt:'Mobile phone screen displaying the i-Con application\'s exhibitors screen. '},
    {title: "Chat", content: "Chat with i-Con's attendees and increase your networking opportunities easily!",img:"https://cdn-images.island-conference.com/download_app/chat.png", alt:'Mobile phone screen displaying the i-Con application\'s chat screen.'},
    {title: "Agenda - Schedule", content: "Don't miss a thing with the app's agenda, and stay up to date with all speeches, panels and parties!",img:"https://cdn-images.island-conference.com/download_app/schedule.png", alt:'Mobile phone screen displaying the i-Con application\'s schedule screen.'},
    {title: "Map", content: "Find your way around City of Dreams and get to your next destination swiftly!",img:"https://cdn-images.island-conference.com/download_app/map.png", alt:'Mobile phone screen displaying the i-Con application\'s map screen.'}
  ]

  slideConfig = { 
    slidesToShow: 1, 
    slidesToScroll: 1,
    "dots": true,
    "prevArrow": '<div class="ts-prev-arrow"><img class="prev-arrow" src="/assets/icons/chevron-left-green.svg"></div>',
    "nextArrow": '<div class="ts-next-arrow"><img class="next-arrow" src="/assets/icons/chevron-right-green.svg"></div>', 
  };



  goToPlaylist() {
    window.open("https://open.spotify.com/playlist/2hPmS5N3eDHsM3X1mTqfRP?si=4cLo49xpTBGbSz9ZJt4r0g&nd=1", "_blank");
  }

  contactUs() {
    this.router.navigate(['/contact']);
  }

  appStore() {
    window.open('https://apps.apple.com/cy/app/island-conference-i-con/id6450279521','_blank');
	}

	playStore() {
    window.open('https://play.google.com/store/apps/details?id=com.nexxie.islandconference','_blank');
	}
 

}
