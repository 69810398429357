import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStayReservationTypeResponse } from '../interfaces/responses/stay-reservation-response-interface';
import { EnvironmentService } from './environment.service';
import { StateService } from './state.service';

interface IStayReservationState {
  postData: IStayReservationTypeResponse;
}

const initialStatusValue: any = {
  postData: {},
};

@Injectable({ providedIn: 'root' })
export class StayReservationService extends StateService<IStayReservationState> {
  postData$: Observable<IStayReservationTypeResponse> = this.getState(state => state.postData);

  constructor(private http: HttpClient, private _environment: EnvironmentService) {
    super(initialStatusValue);
  }

  stayReservation(stayReservationInfo: object) {
    this.http.post<IStayReservationTypeResponse>(`${this._environment.getApiUrl}/stay-reservation`, stayReservationInfo).subscribe({
      error: (error: any) => {
        this.setState({ postData: error.error });
      },
      next: (response: any) => {
        this.setState({ postData: response });
      },
    });
  }
}
