<div class="container" [ngClass]="{ 'sidebarOpened' : expanded }">
    
    <div class="contact-header">
        <h3>Contact Us</h3>
        <h1>WE ARE HERE FOR YOU</h1>
    </div>
    <div
        class="first-section"
        *ngIf="responseState$ | async as responseState"
        [ngClass]="{ showElem: responseState.success }">
        <div class="container">
            <div class="form-container">
                <div class="success-msg flexr" *ngIf="responseState.success">
                    <div class="left">
                        <h2>Thank you for contacting i-Con!</h2>
                        <p>
                            A member of our team will be in touch with you shortly. Please be patient as we get back
                            you.
                            <br />
                            We would advise on checking your spam folder if you have not received a reply from us within
                            48 hours.
                        </p>
                    </div>
                    <div class="right">
                        <img class="success" src="https://cdn-images.island-conference.com/contact/email_sent.gif" />
                    </div>
                </div>
                <div class="form-body" *ngIf="!responseState.success">
                    <p class="header">Fill in your details below and we will get back to you right away!</p>
                    <p class="header-error" *ngFor="let message of responseState.return_msg">
                        {{ message }}
                    </p>
                    <form class="form" (ngSubmit)="onSubmit()" [formGroup]="contactForm">
                        <div class="form-row flexr">
                            <div class="field-container name-field">
                                <input
                                    class="name-field"
                                    type="text"
                                    placeholder="Name"
                                    formControlName="nameControl" />
                                <div *ngIf="submitted && f['nameControl'].errors" class="invalid-feedback">
                                    <div *ngIf="f['nameControl'].errors['required']">Name is required</div>
                                    <div *ngIf="f['nameControl'].errors['minlength']">
                                        A minimum of
                                        {{ f["nameControl"].errors["minlength"].requiredLength }} characters length is
                                        required
                                    </div>
                                    <div *ngIf="f['nameControl'].errors['maxlength']">
                                        A maximum of
                                        {{ f["nameControl"].errors["maxlength"].requiredLength }} characters length is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="field-container surname-field">
                                <input
                                    class="surname-field"
                                    type="text"
                                    placeholder="Surname"
                                    formControlName="surnameControl" />
                                <div *ngIf="submitted && f['surnameControl'].errors" class="invalid-feedback">
                                    <div *ngIf="f['surnameControl'].errors['required']">Name is required</div>
                                    <div *ngIf="f['surnameControl'].errors['minlength']">
                                        A minimum of
                                        {{ f["surnameControl"].errors["minlength"].requiredLength }} characters length
                                        is required
                                    </div>
                                    <div *ngIf="f['surnameControl'].errors['maxlength']">
                                        A maximum of
                                        {{ f["surnameControl"].errors["maxlength"].requiredLength }} characters length
                                        is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row flexr">
                            <div class="field-container company-field">
                                <input
                                    class="company-field"
                                    type="text"
                                    placeholder="Company"
                                    formControlName="companyControl" />
                                <div *ngIf="submitted && f['companyControl'].errors" class="invalid-feedback">
                                    <div *ngIf="f['companyControl'].errors['required']">Company is required</div>
                                    <div *ngIf="f['companyControl'].errors['minlength']">
                                        A minimum of
                                        {{ f["companyControl"].errors["minlength"].requiredLength }} characters length
                                        is required
                                    </div>
                                    <div *ngIf="f['companyControl'].errors['maxlength']">
                                        A maximum of
                                        {{ f["companyControl"].errors["maxlength"].requiredLength }} characters length
                                        is required
                                    </div>
                                </div>
                            </div>
                            <div class="field-container topic-field">
                                <mat-form-field class="mat-form-field" appearance="fill">
                                    <mat-label>Topic</mat-label>
                                    <mat-select formControlName="topicControl" panelClass="select-dropdown">
                                        <mat-option *ngFor="let option of options" [value]="option.value">{{
                                            option.text
                                        }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="submitted && f['topicControl'].errors" class="invalid-feedback">
                                    <div *ngIf="f['topicControl'].errors['required']">Topic is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row flexr">
                            <div class="field-container email-field">
                                <input
                                    class="email-field"
                                    type="text"
                                    placeholder="Email Address"
                                    formControlName="emailControl" />
                                <div *ngIf="submitted && f['emailControl'].errors" class="invalid-feedback">
                                    <div *ngIf="f['emailControl'].errors['required']">Email is required</div>
                                    <div *ngIf="f['emailControl'].errors['email']">Please write a valid email</div>
                                    <div *ngIf="f['emailControl'].errors['maxlength']">
                                        A maximum of
                                        {{ f["emailControl"].errors["maxlength"].requiredLength }} characters length is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row flexr">
                            <div class="field-container message-field">
                                <textarea
                                    class="message-area"
                                    placeholder="Message"
                                    formControlName="messageControl"></textarea>
                                <div *ngIf="submitted && f['messageControl'].errors" class="invalid-feedback">
                                    <div *ngIf="f['messageControl'].errors['required']">Message is required</div>
                                    <div *ngIf="f['messageControl'].errors['minlength']">
                                        A minimum of
                                        {{ f["messageControl"].errors["minlength"].requiredLength }} characters length
                                        is required
                                    </div>
                                    <div *ngIf="f['messageControl'].errors['maxlength']">
                                        A maximum of
                                        {{ f["messageControl"].errors["maxlength"].requiredLength }} characters length
                                        is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row buttons">
                            <button class="main-btn">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>