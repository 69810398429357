import { Injectable } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackBarService {

    constructor(
        private snackBar: MatSnackBar
    ) {}

    showSnackBar(message: string) {
        this.snackBar.open(message,'', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: ['custom-snackbar','mat-primary']
        });
    }

}