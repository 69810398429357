<div class="heading">
	<h2 mat-dialog-title>ATTENDEES TERMS AND CONDITIONS - ATTENDEES</h2>
	<!-- <button class="close-button" [mat-dialog-close]="true">
		<mat-icon class="close-icon" svgIcon="app:close-icon"> </mat-icon>
	</button> -->
</div>

<div class="container" mat-dialog-content>
	<p>
		These terms and conditions (the <strong>“Terms”</strong>), govern your booking for and participation at the
		I-Con Island Conference (the <strong> “Event”</strong>) as an attendee of the Event, taking place between
		30/05/2024 and 31/05/2024 (inclusive) or on such other dates as notified to you by Nexxie Group Ltd (the “Event
		Dates”), a company incorporated and registered in the Republic of Cyprus with company number HE 359807 whose
		registered office is at 23 Aristoteli Savva, Ficardos Centre, 8025 Paphos, Cyprus (<strong
			>“Nexxie”, “we”, “us” </strong
		>or <strong>“our”</strong>). You represent to us that you are authorised to enter into these Terms and that you
		have read, understood and agree with these Terms.
	</p>

	<br />
	<h3><strong>1. Definitions</strong></h3>
	<p>In these Terms the following capitalised words and expressions have the meanings set out against them below:</p>
	<p><strong>Attendee:</strong> a person attending the Event with a valid Ticket.</p>
	<p>
		<strong>Content:</strong> materials, data, information and products provided by Nexxie or its Event partners at,
		relating to or forming part of the Event.
	</p>
	<p>
		<strong>Event:</strong> the series of events collectively entitled iCON Island Conference, which are to take
		place on the Event Dates at the Venue.
	</p>
	<p>
		<strong>Event Marks:</strong> trademarks and service marks owned by Nexxie used singularly or collectively in
		association with the Event.
	</p>
	<p>
		<strong>Event Sponsor:</strong> a person or company which has entered into a sponsorship agreement with Nexxie
		in relation to the Event.
	</p>
	<p>
		<strong>Event Venue:</strong> the premises where the Event is to take place, which are situated at City of
		Dreams Mediterranean, Nikou Kavadia, 3150 Limassol, Cyprus or in an alternative location as notified to the
		Attendees by Nexxie prior to the Event Dates.
	</p>

	<p><strong>Event Website:</strong> the website with the address https://island-conference.com/</p>
	<p><strong>Fee:</strong> the Ticket price payable in order for an Attendee to be able to attend the Event.</p>
	<p>
		<strong>Force Majeure:</strong> any circumstance not within a party&#39;s reasonable control including, without
		limitation:
	</p>
	<p>(a) acts of God, flood, drought, earthquake or other natural disaster;</p>
	<p>(b) epidemic or pandemic;</p>
	<p>
		(c) terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed
		conflict, imposition of sanctions, embargo, or breaking off of diplomatic relations;
	</p>
	<p>(d) nuclear, chemical or biological contamination or sonic boom;</p>
	<p>
		(e) any law or any action taken by a government or public authority, including without limitation imposing an
		export or import restriction, quota or prohibition, or failing to grant a necessary licence or consent;
	</p>
	<p>(f) collapse of buildings, fire, explosion or accident; and</p>
	<p>(g) any labour or trade dispute, strikes, industrial action or lockouts.</p>
	<p><strong>Group Company:</strong> any of Nexxie’s subsidiaries or holding companies.</p>

	<p>
		<strong>Intellectual Property Rights:</strong> patents, rights to inventions, copyright and neighbouring and
		related rights, moral rights, trademarks and service marks, business names and domain names, goodwill and the
		right to sue for passing off or unfair competition, rights in designs, rights in computer software, database
		rights, rights to use, and protect the confidentiality of, confidential information (including know-how and
		trade secrets), and all other intellectual property rights, in each case whether registered or unregistered and
		including all applications and rights to apply for and be granted, renewals or extensions of, and rights to
		claim priority from, such rights.
	</p>
	<p>
		<strong>Losses:</strong> actions, damages, claims, liabilities, costs, losses and expenses (including but not
		limited to any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest,
		penalties and legal costs calculated on a full indemnity basis).
	</p>
	<p>
		<strong>Registration Details:</strong> the information provided by Attendees when they purchase a Ticket on the
		Event Website.
	</p>
	<p>
		<strong>Sponsored Chips:</strong> the free casino chips provided to the Attendee at the Event bearing the mark,
		logo or name of an Event Sponsor.
	</p>
	<p>
		<strong>Ticket:</strong> the document giving right to an Attendee to visit the Event and indicating full payment
		of the Fees. One ticket is valid for one person.
	</p>

	<br />
	<h3><strong>2. Purchasing a Ticket for the Event</strong></h3>
	<p>
		<strong>2.1 </strong>To purchase a Ticket for the Event, you must complete the purchase process on the Event
		Website and pay any applicable Fee. The Booking Details of each Attendee must be duly completed (whether in
		their personal capacity, or as a representative of a company or other legal entity) upon booking. We reserve the
		right to exclude any individual from the Event whose name does not appear on our register of Attendees.
	</p>

	<p>
		<strong>2.2 </strong>If you purchase Tickets for the Event as a representative of a company or other
		organisation, you confirm that you are authorised to agree to these Terms on behalf of the company or
		organisation. You shall also ensure that Attendee(s) from the company or other organisation attending the Event
		are made aware of and comply with these Terms.
	</p>
	<p>
		<strong>2.3 </strong>When purchasing a Ticket for the Event, you must provide us with accurate and complete
		Booking Details in connection to you or other Attendees you are purchasing a Ticket for. It is your
		responsibility to inform us of any changes to that information at any time but not later than thirty (30) days
		prior to the Event (including, without limitation, your email address) by contacting us using the contact
		information set out in clause 18
	</p>
	<p>
		<strong>2.4 </strong>It is your responsibility to inform us of any special access requirements or dietary
		requirements by sending us an email using the contact details set out in clause 18 below, and in any event no
		later than thirty (30) days in advance of the Event. Any dietary requests made less than thirty (30) days before
		the Event may not be available.
	</p>
	<p>
		<strong>2.5 </strong>All Tickets for the Event are subject to availability and their availability for purchase
		may close earlier than any posted deadline, as determined in our sole discretion.
	</p>
	<br />
	<h3><strong>3. Event pass</strong></h3>
	<p>
		<strong>3.1 </strong>In order to ensure the safety of Attendees during the Event, we may request Attendees to
		provide evidence of their identity before we issue their Event pass at the Event. For the same reasons, we may
		ask Attendees to show us their Event pass at any time during the Event and may refuse entry to any Attendee who
		fails to produce a valid pass when requested, in order to eliminate any risk of unauthorised persons being
		present at the Event. Attendees must therefore ensure that they keep their Event pass on them at all times
		during their attendance at the Event.
	</p>
	<p>
		<strong>3.2 </strong>Make sure to keep your Event pass safe at all times. If your Event pass is lost, misplaced,
		stolen or forgotten, we may not be able to issue a new pass to you and, even where we do, this may be subject to
		an additional charge.
	</p>
	<p>
		<strong>3.3 </strong>You may not sell, transfer or share your Event pass. Nexxie reserves the right to eject
		from the Event any Attendees found wearing falsified Event passes and/or sharing or swapping Event passes.
	</p>
	<p>
		<strong>3.4 </strong>An Event pass allows an Attendee entry to the Event including admission to panel
		discussions, seminars, meeting areas, networking sessions, parties and other events (jointly, the “Event
		Activities”) but cannot guarantee admission to every Event Activity. Whilst we will make best efforts to
		accommodate as many Attendees as possible in each Event Activity, due to limitations in venue capacity, there
		may be occasions where not all Attendees interested in attending an Event Activity will be able to do so. Spots
		to each Event Activity will be offered on a first-come, first-served basis in accordance with our reservation
		system as described in clause 3.5 below.
	</p>
	<p>
		<strong>3.5 </strong>You may reserve your spot for an Event Activity by completing the reservation form and
		submitting your request on the Event Website. Alternatively, you may reserve your spot by contacting us directly
		at events&#64;island-conference.com. Upon receipt of your request, our team will confirm or reject your booking
		request in accordance with the availability of the specific Event Activity.
	</p>

	<br />
	<h3><strong>4. Photographing, recording and videotaping at the Event</strong></h3>
	<p>
		By attending the Event, you acknowledge that photographs and filming may take place at the Event either by
		Nexxie or any third parties licensed by it. Nexxie reserves the right to use any material recorded during the
		Event including, inter alia, photographs that depict you and/or may identify you in future marketing and
		promotional materials in all or any media now available or hereafter developed, including social media,
		websites, and print material in accordance with clause 10 and our privacy policy, without obtaining your consent
		or making any payment to you. If you wish to avoid being photographed at the Event please notify the
		photographer accordingly.
	</p>

	<br />
	<h3><strong>5. Attendance Requirements</strong></h3>
	<p><strong>5.1 </strong>Each Attendee shall:</p>
	<p>
		(a) observe the rules, policies and procedures of the Event and Event Venue including in relation to health and
		safety, dress code and conduct and any reasonable instructions issued by Nexxie and/or the management of the
		Event Venue;
	</p>
	<p>
		(b) behave in a respectful, professional and appropriate way that does not breach applicable laws and/or
		regulations;
	</p>
	<p>
		(c) ensure they have adequate insurance in relation to any risks associated with these Terms or their attendance
		of the Event, including personal accident and travel insurance prior to attending the Event, and
	</p>
	<p>
		(d) ensure they have all necessary travel documentation, including but not limited to visas and other entry
		permits and that they comply with all health formalities as required by applicable laws. Attendees are
		responsible for becoming aware of, and complying with, any Covid—19 protocols and/or requirements issued by the
		Republic of Cyprus and their country of residence prior to and during the Event Dates.
	</p>
	<p>
		<strong>5.2 </strong> You acknowledge and agree that your travel to and attendance at the Event is your own
		responsibility and that Nexxie is not obliged to provide any advice or assistance relating to the obtainment of
		any travel documentation. Failure by any Attendee to obtain a necessary travel document for the attendance of
		the Event shall not entitle the Attendee to a refund of any Fee.
	</p>
	<p>
		<strong>5.3 </strong> No entry to the Event is allowed to any individual under eighteen (18) years old.
		Attendees shall make alternative childcare arrangements as Nexxie does not offer childcare facilities at the
		Event.
	</p>
	<p>
		<strong>5.4 </strong>Without prejudice to clause 5.1 , no entry to the Event shall be allowed to Attendees who
		appear to be or are intoxicated or under the influence of drugs.
	</p>
	<p>
		<strong>5.5 </strong>By attending the Event, you confirm that there are no laws, regulations or rules that
		restrict or prohibit your attendance at the Event.
	</p>
	<br />
	<h3><strong>6. Attendee Obligations</strong></h3>
	<p>
		<strong>6.1 </strong>Without prejudice to any other rights or remedies, Nexxie reserves the right to refuse
		entry to the Event to any Attendee or to eject any Attendee from the Event without any liability if, in its
		absolute discretion, it determines that such Attendee’s presence or conduct could cause:
	</p>
	<p>
		(a) Nexxie, its affiliates and partners, the Event Venue, or the Attendee to fail to comply with any applicable
		law or regulation, including, inter alia, in circumstances where the transactions contemplated under these Terms
		are in breach of any (in force) sanctions or other laws;
	</p>
	<p>
		(b) Nexxie to breach any term, warranty, condition or other provision of any agreement or undertaking to which
		Nexxie and/or Group Company is or becomes a party; or
	</p>
	<p>
		(c) disruption of the Event, threaten the safety of other attendees or hinder the enjoyment of the Event by
		other attendees.
	</p>
	<p>
		<strong>6.2 </strong>Nexxie reserves the right to recover from you any loss or damage incurred or suffered by
		us, the Event Venue or any other Attendee(s) as a result of your conduct at the Event or failure to comply with
		these Terms. In such circumstances, an Attendee shall not be entitled to a refund of any Fee.
	</p>
	<p>
		<strong>6.3 </strong>With the exception of individuals attending the Event as sponsors and/or exhibitors (whose
		promotional activities are governed by separate terms and conditions), no Attendee shall have an allocated space
		for the advertisement or promotion of any company, brand, service or product.
	</p>

	<p>
		<strong>6.4 </strong>No Attendee shall promote any products or services that we may, at our discretion, find to
		be prejudicial to the image and/or reputation of the Event or Nexxie including, but not limited to, any products
		or services which include or refer to minors, or which may be obscene, offensive, hateful or may promote
		violence or discrimination based on race, religion, gender, nationality, sexual orientation or age.
	</p>
	<p>
		<strong>6.5 </strong>Nexxie reserves the right to issue guidelines with respect to the manner in which Attendees
		may promote any products or services during the Event and will inform Attendees of the issuance of any such
		guidelines and/or any amendments thereto at any time prior to or during the Event. Attendees shall be bound by
		any such guidelines and, if found to be acting in breach of the guidelines, may have their Ticket cancelled and
		may be ejected from the Event without a right to a refund.
	</p>
	<p>
		<strong>6.6 </strong>Attendees will be required to comply with any Code of Conduct issued by Nexxie at any time
		prior to or during the Event, as will be made available to Attendees on the Event’s Website and/or at the Event
		Venue.
	</p>
	<p>
		<strong>6.7 </strong>No food and beverage (other than water) may be brought into the Event Venue by any
		Attendees for consumption on the premises.
	</p>
	<p>
		<strong>6.8 </strong>Attendees shall not sell or give away from any part of the Event Venue, any articles of
		food, drink, tobacco or any other products, whether supplied by the Event Venue, the Event Sponsors, the
		exhibitors or otherwise.
	</p>
	<p>
		<strong>6.9 </strong>Each Attendee shall indemnify Nexxie and keep Nexxie indemnified against all Losses and all
		other reasonable professional costs and expenses suffered or incurred by Nexxie as a result or in connection
		with:
	</p>

	<p>(a) any breach or negligent performance or non-performance of these Terms;</p>
	<p>(b) the enforcement of these Terms;</p>
	<p>
		(c) any claim made against Nexxie for actual or alleged infringement of a third party's intellectual property
		rights arising out of or in connection with the attendance of the Event by the Attendee, and
	</p>
	<p>
		(d) any claim made against Nexxie by a third party arising out of or in connection with the Attendee's
		participation (including attendance and conduct) at the Event.
	</p>

	<br />
	<h3><strong>7. Sponsored Chips</strong></h3>
	<p>
		<strong>7.1 </strong>Nexxie may provide you with Sponsored Chips during your attendance at the Event at its sole
		discretion. You acknowledge that you shall use the Sponsored Chips at your sole risk and responsibility and that
		the provision of the Sponsored Chips does not constitute any form of promotion or recommendation in engaging in
		any gambling activities or other related activities by Nexxie.
	</p>
	<p>
		<strong>7.2 </strong>By accepting the Sponsored Chips, you acknowledge that Nexxie shall not be liable for any
		direct or indirect losses arising out of or in connection with your use of the Sponsored Chips or with any other
		gambling activities you are involved in at the Venue during the Event.
	</p>

	<br />
	<h3><strong>8. Fees</strong></h3>
	<p>
		<strong>8.1 </strong>Payment in full of any applicable Fees for the Event is due upon completing the booking
		process on the Event Website for purchasing a Ticket. If such payment is insufficient or declined for any
		reason, the Ticket will not be booked.
	</p>
	<p>
		<strong>8.2 </strong>In consideration of the Fees, we shall grant the Attendee a Ticket to the Event, subject to
		these Terms.
	</p>
	<p><strong>8.3 </strong>Purchase of a Ticket shall be deemed to be acceptance of these Terms.</p>
	<p><strong>8.4 </strong>All Fees are inclusive of VAT.</p>
	<p>
		<strong>8.5 </strong>Following full payment of the Fees, Tickets shall be delivered electronically via email to
		the email address provided upon booking. Where you purchase more than one Tickets, you shall bear the
		responsibility to distribute each Ticket to the relevant Attendee.
	</p>
	<p>
		<strong>8.6 </strong>The Ticket acts as a receipt for the transaction and can be used to redeem entry at the
		Event. We reserve the right to refuse entry to any person who cannot produce evidence of a valid Ticket.
	</p>
	<p>
		<strong>8.7 </strong>You will have fourteen (14) days from the purchase of a Ticket to exercise your right to
		cancel without giving us any reason for doing so (the “Cancellation Period”). You can cancel your Ticket within
		the Cancellation Period by contacting us using the contact details set out in clause 18 below. Please be aware
		that any notice of cancellation, shall take effect upon its receipt by us. We will reimburse you within fourteen
		(14) days from the date we received your cancellation request, using the same means of payment that were used
		when you made the purchase for the Ticket, unless you expressly instruct us otherwise. No cancellations will be
		accepted once the Event starts.
	</p>
	<br />
	<h3><strong>9. Ticket</strong></h3>
	<p><strong>9.1 </strong>Tickets shall only be refundable in accordance with clause 8.7 and clause 11.4.</p>
	<p>
		<strong>9.2 </strong>Tickets may not be copied, reproduced, resold, used for another Nexxie event, or
		transferred to another person without our prior approval and solely at our discretion.
	</p>
	<p>
		<strong>9.3 </strong>Nexxie reserves the right to provide free Tickets to certain Attendees at its sole
		discretion for promotional and/or any other purposes. The provisions of these Terms shall apply to all Attendees
		irrespective of how they acquired the Ticket.
	</p>
	<br />
	<h3><strong>10. Intellectual Property Rights</strong></h3>
	<p>
		<strong>10.1 </strong>All Intellectual Property Rights in the Event and in the Content relating to the Event are
		the exclusive property of Nexxie or its third-party Content providers.
	</p>
	<p>
		<strong>10.2 </strong>Attendees may use the Content solely for their own personal use and benefit and not for
		resale, distribution or other commercial purposes.
	</p>
	<p><strong>10.3 </strong>The Event Marks may not be used by Attendees without Nexxie’s prior written permission.</p>
	<p>
		<strong>10.4 </strong>Any request for permission to republish, reprint or use for any other purpose any of the
		Content or Event Marks should be sent by email to us by using the contact details in clause 18.
	</p>

	<p>
		<strong>10.5 </strong>You grant us, our affiliates and our contractors the right to record, film, photograph and
		capture your voice and likeness in any media at the Event (collectively, the “Materials”). By attending the
		Event, you grant to Nexxie, its affiliates, employees and assignees an irrevocable, non-exclusive, perpetual,
		worldwide, royalty free right and licence to use, reproduce, modify, distribute and translate for any purpose
		relating to our business or the Event, all or any part of the Materials. We may edit the Materials, use them
		alone or together with other information, and allow others to use and distribute them. Any use of the Materials
		by us will always be in line with our privacy policy, which can be found on the Event’s Website or may be
		provided to you upon request by contacting us using the contact details set out in clause 18.
	</p>

	<br />
	<h3><strong>11. Changes to the Event</strong></h3>
	<p>
		<strong>11.1 </strong>Although Nexxie’s Event schedule is correct at the time of publication, we may need to
		cancel or alter the Event and/or the Event Activities or schedule, venue, speakers and specifications thereof at
		any time based on factors beyond our control and/or for the safety of Attendees. In the event that such
		alteration is required, we shall use reasonable endeavours to allow the Event to continue at an alternate venue
		or to an alternate schedule.
	</p>
	<p>
		<strong>11.2 </strong>Nexxie reserves the right to cancel the Event, if no viable alteration is possible. In
		case of cancellation of the Event, we shall not be deemed to be in breach of these Terms or otherwise liable to
		you for any such cancellation or postponement, or any failure or delay in performing our obligations under these
		Terms for commercial reasons (including but not limited to, an event of Force Majeure).
	</p>
	<p>
		<strong>11.3 </strong>Nexxie shall use its reasonable endeavours to promptly notify all Attendees at the email
		addresses provided upon purchase of a Ticket of any changes as described in clauses 11.1 and 11.2 prior to the
		commencement of the Event.
	</p>
	<p>
		<strong>11.4 </strong>In case of cancellation of the Event by Nexxie after payment of the Fees, you shall be
		entitled, by contacting us using the contact details set out in clause 18 below, either to apply your Fee to
		another Nexxie event or request a full refund of the relevant Fee within thirty (30) days from your receipt of
		the cancellation notice.
	</p>
	<p>
		<strong>11.5 </strong>In case of postponement of the Event by Nexxie and provided that you opt to apply your Fee
		to another Nexxie event, you shall be deemed to have accepted the new Event date and may not request a refund.
	</p>
	<p>
		<strong>11.6 </strong>Our liability to you as a result of any cancellation or postponement of an Event shall be
		limited to the amount of Fees which you have paid to us for that Event only and we shall not be liable for any
		additional Losses incurred by you as a result of such cancellation or postponement.
	</p>
	<p>
		<strong>11.7 </strong>Attendees may nominate a substitute to attend an Event in his or her place subject to
		their agreement and compliance with these Terms. Notifications and registration details of substitutions should
		be sent in writing to Nexxie using the contact details set out in clause 18 below at the latest thirty (30) days
		prior to the commencement of the Event. We retain the right to refuse any such substitution for any reason in
		our absolute discretion.
	</p>

	<br />
	<h3><strong>12. Personal Data</strong></h3>
	<p>
		The processing of your personal data in connection to your purchase of a Ticket and your participation in the
		Event is described in our privacy policy which can be found at the Event’s Website.
	</p>

	<br />
	<h3><strong>13. Limitation of liability</strong></h3>
	<p><strong>13.1 </strong>Nothing in these Terms shall limit or exclude a party’s liability for:</p>
	<p>
		(a) death or personal injury caused by its negligence, or the negligence of its employees, agents or
		subcontractors;
	</p>
	<p>(b) fraud or fraudulent misrepresentation; or</p>
	<p>(c) any other liability which cannot be limited or excluded by applicable law.</p>
	<p>
		<strong>13.2 </strong>All warranties, conditions and other terms implied by statute or common law are, to the
		extent permitted by law, excluded from these Terms.
	</p>
	<p>
		<strong>13.3 </strong>You agree that your attendance at the Event and your use of any of the Content is at your
		sole risk and responsibility. The Content does not constitute any form of advice, recommendation or arrangement
		by us and is not intended to be relied upon by Attendees in making (or refraining from making) any specific
		investment or other decisions and Nexxie accepts no liability for any direct or indirect losses incurred by the
		Attendees as a result of such investment and/or other decisions.
	</p>
	<p>
		<strong>13.4 </strong>You acknowledge that you shall be responsible for any damage caused by you to the Event
		Venue, property, furnishings and/or equipment therein by any act, default or neglect on your behalf and shall
		pay to us on an indemnity basis the amount required to make remedy any such damage.
	</p>
	<p>
		<strong>13.5 </strong>We will not accept liability for loss or damage to any object, equipment, furniture, stock
		or any other property brought into the Event Venue by any Attendee.
	</p>
	<p>
		<strong>13.6 </strong>Without prejudice to clause 13.1, Nexxie shall not be liable for any injury, damage or
		other loss (whether direct or indirect) incurred by an Attendee during the Event howsoever caused.
	</p>
	<p>
		<strong>13.7 </strong> Nexxie shall not be liable, whether based on a claim in contract, tort (including
		negligence) or otherwise arising out of or in relation to these Terms, for any special, indirect, consequential
		loss including but not limited to, loss of profits, loss of business, depletion of goodwill and/or similar
		losses.
	</p>
	<p>
		<strong>13.8 </strong>Nexxie accepts no responsibility for the actions of others while under the influence of
		alcohol served at any Event Activity hosted by Nexxie during the Event.
	</p>
	<p>
		<strong>13.9 </strong>Nexxie’s maximum aggregate liability in contract, tort (including negligence) or otherwise
		arising out of or in relation to these Terms shall be limited to the amount of the Fees paid under or pursuant
		to these Terms.
	</p>
	<br />
	<h3><strong>14. Guest speakers</strong></h3>
	<p>
		In order to provide Attendees with useful information and insights for their business, we invite key figures
		from various industries to share their personal perspectives and experiences concerning the challenges and
		opportunities facing businesses today. Nexxie neither pre-reviews nor comments on the content of our guest
		speaker sessions. Guest speakers’ views and opinions are entirely their own and in no way reflect the views and
		opinions of Nexxie.
	</p>

	<br />
	<h3><strong>15. Event mobile application</strong></h3>
	<p>
		By purchasing a Ticket to attend the Event, you may be featured in the official Event mobile application
		provided you give us your consent. Further details with regard to the Event mobile application and its permitted
		uses will be provided separately to you nearer the time of the Event.
	</p>
	<br />
	<h3><strong>16. Hotel Bookings</strong></h3>
	<p>
		While we recommend certain hotels on the Event Website for your accommodation during the Event dates, we have no
		control over any of the hotels’ policies (including, but, not limited to their cancellation policies) and have
		no liability in connection to any damages or losses you may incur in connection to your accommodation in any of
		these hotels.
	</p>

	<br />
	<h3><strong>17. Assignment</strong></h3>
	<p>
		<strong>17.1 </strong>Nexxie may, without your consent, assign, sub-contract or transfer any and all of its
		rights and obligations under these Terms to any Group Company or any entity which acquires a substantial part of
		the assets of its business.
	</p>
	<p>
		<strong>17.2 </strong>You may not assign or otherwise transfer any of your rights under these Terms without our
		prior written consent.
	</p>

	<br />
	<h3><strong>18. Amendments</strong></h3>
	<p>
		We may make amendments to these Terms when we deem it necessary to do so. Material changes to these Terms will
		be implemented provided that you have been notified in advance of the changes taking effect. What constitutes a
		material change will be determined by us in our sole discretion. You will be deemed to have accepted any such
		amendments if you attend the Event. If you do not wish to accept them, you may cancel your attendance in
		accordance with clause 8.7 of these Terms.
	</p>

	<br />
	<h3><strong>19. How to contact us</strong></h3>
	<p>
		If you wish to contact us for any reason in connection to these Terms, please feel free to contact our customer
		support team at any time either via email to
		<a href="mailto: info@island-conference.com">info&#64;island-conference.com</a> or via phone at +357 26 080 800.
	</p>

	<br />
	<h3><strong>20. Entire Agreement</strong></h3>
	<p>
		<strong>20.1 </strong>These Terms constitute the entire agreement between Nexxie and all visitors of the Event
		in their capacity as Attendees and supersedes and extinguishes all previous terms, promises, assurances,
		warranties, representations and understandings between them, whether written or oral, relating to its subject
		matter.
	</p>
	<p>
		<strong>20.2 </strong>Each party acknowledges that by accepting the Terms it does not rely on any statement,
		representation, assurance or warranty that is not set out in these Terms.
	</p>
	<p>
		<strong>20.3 </strong>You may be subject to additional terms and conditions where you attending the Event as an
		Event Sponsor or as an exhibitor.
	</p>

	<br />
	<h3><strong>21. Severance</strong></h3>
	<p>
		If any provision or part-provision of these Terms is or becomes invalid, illegal or unenforceable, it shall be
		deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is
		not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion
		of a provision or part-provision under this clause shall not affect the validity and enforceability of the rest
		of these Terms.
	</p>

	<br />
	<h3><strong>22. Waiver</strong></h3>
	<p>
		A waiver of any right or remedy under the Terms or by law is only effective if given in writing and shall not be
		deemed a waiver of any subsequent right or remedy.
	</p>
	<p>
		A failure or delay by a party to exercise any right or remedy provided under the Terms or by law shall not
		constitute a waiver of that or any other right or remedy, not shall it prevent or restrict any further exercise
		of that or any other right or remedy. No single or partial exercise of any right or remedy provided under the
		Terms or by law shall prevent or restrict the further exercise of that or any other right or remedy.
	</p>

	<br />
	<h3><strong>23. Governing Law</strong></h3>
	<p>
		These Terms and any dispute or claim arising out of or in connection with them or its subject matter or
		formation shall be governed by and construed in accordance with the laws of Cyprus.
	</p>

	<br />
	<h3><strong>24. Jurisdiction</strong></h3>
	<p>
		Each party irrevocably agrees that the courts of Cyprus shall have exclusive jurisdiction to settle any dispute
		or claim arising out of or in connection with these Terms or its subject matter or formation.
	</p>
</div>
