<div class="sidebar" [ngClass]="{ 'opened' : expanded }">

    <div class="wrapper" [ngClass]="{ 'opened' : expanded }">
        <div class="closeBtn" [ngClass]="{ 'opened' : expanded }" (click)="toggleSidebar()">
            <span class="bar bar-one" [ngClass]="{ 'opened' : expanded }"></span>
            <span class="bar bar-two" [ngClass]="{ 'opened' : expanded }"></span>
            <span class="bar bar-three" [ngClass]="{ 'opened' : expanded }"></span>
        </div>

        <div class="allExceptHamburger" [ngClass]="{ 'opened' : expanded }">
            <div class="logo" [ngClass]="{ 'opened' : expanded }">
                <div class="line">
                    <hr>
                </div>
                <img src="https://cdn-images.island-conference.com/panel/sidebar/logo.svg" alt="Island Conference">
                <p>PORTAL</p>
            </div>

            <div class="profile" [ngClass]="{ 'opened' : expanded }" *ngIf="profileDetails$ | async as profileData">
                <a [routerLink]="['/profile']"><img src="https://cdn-images.island-conference.com/panel/sidebar/default_prof.svg"
                        alt=""></a>
                <p>{{profileData.contactDetails.name}} {{profileData.contactDetails.surname}}</p>
            </div>

            <div class="itemsWrapper" [ngClass]="{ 'opened' : expanded }">
                <div class="top">
                    <div class="line">
                        <hr>
                    </div>
                    <div class="items">
                        <i-con-sidebar-item (click)="onItemClick(item)" *ngFor="let item of sidebarItems | slice:0:3"
                            [item]="item"></i-con-sidebar-item>
                    </div>
                    <div class="line">
                        <hr>
                    </div>
                </div>

                <div class="mid">
                    <div class="items">
                        <i-con-sidebar-item (click)="onItemClick(item)" *ngFor="let item of sidebarItems | slice:3:6"
                            [item]="item"></i-con-sidebar-item>
                    </div>
                    <div class="line">
                        <hr>
                    </div>
                </div>

                <div class="order">
                    <div class="items">
                        <i-con-sidebar-item (click)="onItemClick(item)" *ngFor="let item of sidebarItems | slice:6:7"
                            [item]="item"></i-con-sidebar-item>
                    </div>
                    <div class="line">
                        <hr>
                    </div>
                </div>

                <div class="logout">
                    <div class="items">
                        <i-con-sidebar-item (click)="logOutClick()" *ngFor="let item of sidebarItems | slice:7"
                            [item]="item"></i-con-sidebar-item>
                    </div>
                    <div class="link"><a href="https://island-conference.com/"
                            target="_blank">www.island-conference.com</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>