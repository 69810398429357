import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResendCode } from '../interfaces/resend-code.interface';
import { IResendOrderTypeResponse } from '../interfaces/responses/resend-code-type-response.interface';
import { EnvironmentService } from './environment.service';
import { StateService } from './state.service';

interface IResendCodeState {
  postData: IResendOrderTypeResponse;
}

const initialStateValue: any = {
  postData: {},
};

@Injectable({ providedIn: 'root' })
export class ResendCodeService extends StateService<IResendCodeState> {
  postData$: Observable<IResendOrderTypeResponse> = this.getState(state => state.postData);

  constructor(private http: HttpClient, private _environment: EnvironmentService) {
    super(initialStateValue);
  }

  postRequestResendCode(email: IResendCode) {
    return this.http.post<IResendOrderTypeResponse>(`${this._environment.getApiUrl}/booking/forgot-account`, email);
  }
}
