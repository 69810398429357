import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BookingFormGroupService, IFormField, IProfileType, ProfileService } from '@i-con/shared/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'i-con-booking-form',
  standalone: true,
  imports: [CommonModule, FormsModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './booking-form.component.html',
  styleUrl: './booking-form.component.scss',
})

export class BookingFormComponent implements OnInit {

	@Input() fieldsDataPart1: IFormField[] = [];
	@Input() submitted: boolean = false;
	@Input() profileDetails!: IProfileType;

	bookingForm!: FormGroup;

	controlValidations: FormControl[] = [];

	constructor(
		private parentForm: FormGroupDirective,
		private bookingFormGroupService: BookingFormGroupService,
	) {

	}

  	ngOnInit() {

		this.bookingForm = this.parentForm.form;

		this.fieldsDataPart1.forEach((field) => {
			const validations: ValidatorFn[] = [];

			Object.entries(field.validations).forEach((validation) => {
				const key: keyof typeof Validators = validation[0] as keyof typeof Validators;

				// validation without parameters (eg. Validators.required)
				const validatorFunction = Validators[key] as () => ValidatorFn;
				
				// validation with parameters (eg. Validators.maxLength(10))
				const validatorFunctionWithParam = (Validators[key] as (param: number | string | RegExp) => ValidatorFn)(validation[1]);

				if (typeof validation[1] !== "boolean") {
					validations.push(validatorFunctionWithParam);
				}
				else {
					validations.push(validatorFunction);
				}
			});

			if (this.profileDetails.contactDetails.id !== 0 ) {
				switch(field.controlName) {
					case 'firstName':
						const firstNameControl = new FormControl(this.profileDetails.contactDetails.name, validations);
						this.controlValidations.push(firstNameControl);
						break;
					case 'lastName':
						const lastNameControl = new FormControl(this.profileDetails.contactDetails.surname, validations);
						this.controlValidations.push(lastNameControl);
						break;
					case 'email':
						const emailControl = new FormControl({
								value: this.profileDetails.contactDetails.email, 
								disabled: true 
							}, validations);
						this.controlValidations.push(emailControl);
						break;
					default:
						const formControl = new FormControl('', validations);
						this.controlValidations.push(formControl);
						break;
				}
			}
			else {
				const formControl = new FormControl('', validations);
				this.controlValidations.push(formControl);
			}			
		});

		this.bookingForm.addControl('part1booking', new FormGroup({
			part1: new FormArray(this.controlValidations)
		}));
	}

	checkErrors(fieldNumber: number, error: string){
		const formGroup = this.bookingForm.get('part1booking');
		const mFormArray = formGroup?.get('part1') as FormArray;

		return this.bookingFormGroupService.checkErrors(mFormArray, error, fieldNumber);
	}

	get f() {
		const formGroup = this.bookingForm.get('part1booking');
		const mFormArray = formGroup?.get('part1') as FormArray;
		return mFormArray.controls;
	}



}
