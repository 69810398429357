<div class="resend-code-container" [class.active_nudge]="submitted">
    <form class="form" [formGroup]="resendCodeForm" (ngSubmit)="onSubmit()">
        <div class="form-row">

            <p>If you've forgotten your code, don't worry. You can easily request a new one here.</p>
            <h2 class="field-heading">Email</h2>
            <div class="form-col email-field">
                <input id="email" class="input-email-field" type="text" placeholder="email@example.com"
                    formControlName="email" />
            </div>
            <div *ngIf="(resendCodeForm.touched || submitted) && f['email'].errors" class="invalid-feedback">
                <div *ngIf="(resendCodeForm.touched || submitted) && f['email'].errors['required']">
                    Email is required
                </div>
                <div *ngIf="(resendCodeForm.touched || submitted) && f['email'].errors['email']">
                    Please write a valid email
                </div>
                <div *ngIf="(resendCodeForm.touched || submitted) && f['email'].errors['maxlength']">
                    A maximum of
                    {{
                    (resendCodeForm.touched || submitted) &&
                    f["email"].errors["maxlength"].requiredLength
                    }}
                    characters length is required
                </div>
            </div>
        </div>

        <div class="timer">
            <span>{{timerString}}</span>
            <p>The timer above shows the remaining time before you can request a new code. For security reasons, you can
                only request a new code every {{numberOfMinutes}} {{numberOfMinutes === 1 ? 'minute' : 'minutes'}}.</p>
        </div>

        <div class="button-container">
            <button class="primaryBtn main-btn" type="submit" [disabled]="timerIsRunning">Resend Code</button>
            <a class="link" (click)="backToLogin.emit()">Back to login</a>
        </div>
    </form>

</div>