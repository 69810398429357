import { IEnvironment } from './environment.interface';
export const environment: IEnvironment = {
	name: 'production',
	isProduction: true,
	apiUrlServer: 'https://api.island-conference.com',
	apiUrlBrowser: 'https://api.island-conference.com',
	domain: '.island-conference.com',
	appUrl: 'https://island-conference.com',
	portalUrl: 'https://portal.island-conference.com'
};
