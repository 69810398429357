import { CommonModule, ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { SidebarItemComponent, sidebarItems } from '@i-con/shared/ui';
import { ISideBarItem } from 'libs/i-con.shared/common/src/lib/interfaces/sidebar-item.interface';
import { SidebarService } from 'libs/i-con.shared/common/src/lib/services/sidebar.service';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';
import { IProfileType, ProfileService } from '@i-con/shared/common';

@Component({
  selector: 'i-con-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  imports: [CommonModule, SidebarItemComponent, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  username: string = '';
  expanded: boolean = false;

  sidebarItems: ISideBarItem[] = sidebarItems;
  @Input() sidebarItemId!: number;

  isOpen$: Observable<boolean> | undefined;

  profileDetails$!: Observable<IProfileType>;

  constructor(
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private router: Router,
    private sidebarService: SidebarService,
    public dialog: MatDialog,
    private profileService: ProfileService
  ) {

  }

  ngOnInit(): void {
    this.isOpen$ = this.sidebarService.expanded$;
    this.isOpen$.subscribe(val => (this.expanded = val));
    
    this.profileDetails$ = this.profileService.profileData$;
    
    if (!this.sidebarItemId) {
      this.sidebarService.selectedItem$.subscribe(num => {
        this.sidebarItems.forEach(sideBarItem => (sideBarItem.isActive = false));
        try {
          this.sidebarItems[num].isActive = true;
        } catch (e) {
          this.sidebarItems.forEach(sideBarItem => (sideBarItem.isActive = false));
        }
      });
    } else {
      try {
        this.sidebarItems.forEach(sideBarItem => (sideBarItem.isActive = false));
        this.sidebarItems[this.sidebarItemId].isActive = true;
      } catch (e) {
        this.sidebarItems.forEach(sideBarItem => (sideBarItem.isActive = false));
      }
    }
  }

  onItemClick(item: ISideBarItem) {
    if (typeof window !== 'undefined' && window.innerWidth <= 768) {
      this.expanded = false;
      this.sidebarService.closeSidebar();
    }

    this.sidebarItems.forEach(sideBarItem => (sideBarItem.isActive = false));
    item.isActive = true;
    this.sidebarService.setSelectedItem(this.sidebarItems.indexOf(item));

    try {
      this.router.navigate([item.path]);
      this.viewportScroller.scrollToPosition([0, 0]);
    } catch (e) {
      console.log(item.path + ' does not exist yet in the application');
    }
  }

  logOutClick() {
    event?.preventDefault();
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      maxHeight: '85vh',
      maxWidth: '520px',
      width: '100%',
      autoFocus: true,
      disableClose: false,
      panelClass: 'padding-inline',
      //send [expanded] data so padding can be applied on the modal if the sidebar is open. when the time comes to pass actual profile/booth data an object will need to be created in this function so that we can also pass the expanded value
      data: this.expanded,
    });
  }

  toggleSidebar() {
    this.sidebarService.toggle();
    this.sidebarService.expanded$.subscribe(expanded => (this.expanded = expanded));
  }
}
