import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ITripReservationTypeResponse, StateService, TripReservationService } from '@i-con/shared/common';
import { CountryISO, NgxIntlTelInputModule, SearchCountryField } from 'ngx-intl-tel-input';
import { Observable } from 'rxjs';

interface ITripReservationState {
  success: boolean;
  return_msg: string;
}

const initialState: ITripReservationState = {
  success: false,
  return_msg: '',
};

@Component({
  selector: 'app-trip-reservation',
  standalone: true,
  imports: [CommonModule, MatSelectModule, NgxIntlTelInputModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatDialogModule],
  templateUrl: './trip-reservation.component.html',
  styleUrls: ['./trip-reservation.component.scss'],
})
export class TripReservationComponent extends StateService<ITripReservationState> implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder, private tripReservationService: TripReservationService) {
    super(initialState);
  }

  selected_countries = CountryISO;
  SearchCountryField = SearchCountryField;
  submitted = false;
  reserveTripFrom!: UntypedFormGroup;

  postData$!: Observable<ITripReservationTypeResponse>;
  responseState$!: Observable<ITripReservationState>;

  ngOnInit(): void {
    this.postData$ = this.tripReservationService.postData$;
    this.responseState$ = this.getState(state => state);

    this.reserveTripFrom = this.formBuilder.group({
      nameControl: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]],
      surnameControl: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]],
      emailControl: ['', [Validators.required, Validators.email, Validators.minLength(6), Validators.maxLength(255)]],
      companyControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      positionControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      phoneControl: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.reserveTripFrom?.invalid) {
      return;
    }

    const tripReservationInfo = {
      name: this.reserveTripFrom.value.nameControl,
      surname: this.reserveTripFrom.value.surnameControl,
      dialCode: this.reserveTripFrom.value.phoneControl.dialCode,
      country: this.reserveTripFrom.value.phoneControl.countryCode,
      phoneNumber: this.reserveTripFrom.value.phoneControl.number,
      companyName: this.reserveTripFrom.value.companyControl,
      position: this.reserveTripFrom.value.positionControl,
      email: this.reserveTripFrom.value.emailControl,
    };

    this.tripReservationService.tripReservation(tripReservationInfo);

    this.postData$.subscribe(res => {
      if (res.response?.statusCode == 201) {
        this.setState({
          success: true,
          return_msg: '',
        });
      } else {
        this.setState({
          success: false,
          return_msg: res.response?.message,
        });
      }
    });
  }

  get f() {
    return this.reserveTripFrom.controls;
  }
}
