import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser, isPlatformServer } from '@angular/common'

export interface IPlatformService {
  readonly isBrowser: boolean
  readonly isServer: boolean
}

@Injectable({
  providedIn: 'root'
})
export class PlatformService implements IPlatformService {
  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<unknown>) { }

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId)
  }

  public get isServer(): boolean {
    return isPlatformServer(this.platformId)
  }
}