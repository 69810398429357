<div class="plan-events" id="home">
	<div class="top-header">
		<div class="container">
			<h3>Experience i-CON's</h3>
			<h1>NETWORKING EVENTS</h1>
		</div>
	</div>
	<div class="container">
		<div class="content">
			<h3>LIFESTYLE & NETWORKING EVENTS</h3>
			<p>i-Con also comes with an extended list of networking opportunities outside the professional setting of
				our Expo. Having partnered up with Haze, a lifestyle event organizer based in Cyprus, we bring you some
				of the most thrilling and exclusive events on the island! Enjoy our scheduled parties featuring
				performers as well as resident and guest DJs from some of the hottest Greek clubs! This is your chance
				to network in a more relaxed setting, but also party the night away after two days filled with business
				opportunities and knowledge!</p>
		</div>

		<div class="events">

			<div class="pre-event event">
				<div class="booked-ribbon">Fully Booked</div>
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
						<!-- <div class="img-2"></div>
						<div class="img-3"></div> -->
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						19:00 - 23:00
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details">
								<h3>PRE-EVENT WELCOME PARTY</h3>
								<div class="location flexr">
									<div class="date">
										<img class="calendar"
											src="https://cdn-images.island-conference.com/plan/calendar.png" />
										<p>Wednesday, May 29</p>
									</div>
									<div class="place">
										<img class="location-icon"
											src="https://cdn-images.island-conference.com/plan/location.png" />
										<p>La Caleta - ICON Limassol</p>
									</div>
								</div>
							</div>
							<div class="venue">
								<!-- <img class="location-img" src="https://cdn-images.island-conference.com/plan/lacaleta.svg" /> -->
								<img class="location-img"
									src="https://cdn-images.island-conference.com/plan/events/lacaleta-blue.png" />
							</div>
						</div>
						<div class="description">
							<p>i-Con conference kicks off with a VIP Welcome Party at "La Caleta," located on the 10th
								floor of the Icon Tower, one of the tallest buildings in Cyprus!
							</p>
							<div class="sponsored">
								<p>Sponsored by:</p>
								<img src="https://cdn-images.island-conference.com/plan/events/nova-logo.png" alt="Nova"/>
							</div>
							<div class="youtube">
								<button><a href="https://youtu.be/hY0H3u0PKG8"
										target="_blank" rel="nofollow"><div class="polygon"></div>Watch the aftermovie on YouTube</a> </button>
							</div>
						</div>
						<div class="reserve-btns-wrapper flexr">
							<button class="main-btn"
								 disabled>
								Add your crew on the Guestlist
								<!-- <span>Fully Booked</span> -->
							</button>
							<button class="reservevip-btn" disabled>
								Reserve Your VIP Table
								<!-- <span>Fully Booked</span> -->
							</button>
						</div>
						
						<div class="organized-by">
							<p>Organized by:</p>						
							<div class="event-sponsors flexr">
								<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="event-flyer"></div>
						<!-- <video controls id="my_video_1" (mouseenter)="playVid('my_video_1')"
							(mouseleave)="pauseVid('my_video_1')" muted
							poster="https://cdn-images.island-conference.com/events/nova-sponsorship-v2.jpg">
							<source src="https://cdn-images.island-conference.com/plan/events/Nova-teaser.mp4" type="video/mp4" />
							Browser not supported
						</video> -->
					</div>
				</div>
			</div>
			
			<div class="networking event secret-garden">
				<div class="booked-ribbon">Fully Booked</div>
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						16:00 - 18:30
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details premium">
								<div class="box">
									<h3>SECRET GARDEN NETWORKING DRINKS</h3>
									<div class="location flexr">
										<div class="date">
											<img class="calendar"
												src="https://cdn-images.island-conference.com/plan/calendar.png" />
											<p>Thursday, May 30</p>
										</div>
										<div class="place">
											<img class="location-icon"
												src="https://cdn-images.island-conference.com/plan/location.png" />
											<p>Anais, City of Dreams</p>
										</div>
									</div>
								</div>
								<div class="box">
									<img class="premium-badge" src="https://cdn-images.island-conference.com/events/premium_holders_badge.png"/>
								</div>
							</div>
							<div class="venue">
								<img class="location-img"
									src="https://cdn-images.island-conference.com/plan/events/anais.png" />
							</div>
						</div>
						<div class="description">
							<p>Finish the first day of the conference, with some networking drinks, at Anaïs, Europe's
								first French Gastro Club. Between 16:00-18:30 our Premium attendees will be exclusively
								invited to gather all together in the "La Terrasse" area of Anaïs to relax after the
								first day of the conference and warm up for the i-Conic Opening Party. Immerse yourself
								in an enchanting atmosphere with the sounds of our guest DJ while enjoying the aperitivo
								style cocktails and drinks.
							</p>
							<div class="sponsored">
								<p>Sponsored by:</p>
								<img src="https://cdn-images.island-conference.com/exhibitors/logos/advidi.svg" alt="Advidi"/>
							</div>
						</div>
						<div class="organized-by">
							<p>Organized by:</p>						
							<div class="event-sponsors flexr">
								<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div class="opening-party event">
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
						<!-- <div class="img-2"></div>
						<div class="img-3"></div> -->
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						22:00 - 02:00
					</div>
				</div>
				<div class="bottom">
					<div class="right">
						<div class="event-flyer"></div>
						<!-- <video controls id="my_video_2" (mouseenter)="playVid('my_video_2')"
							(mouseleave)="pauseVid('my_video_2')" muted>
							<source src="https://cdn-images.island-conference.com/plan/events/PropellerAds-Opening-Party-v2.mp4" type="video/mp4"
								poster="https://cdn-images.island-conference.com/events/propellerads-sponsorship-v2.jpg" />
							Browser not supported
						</video> -->
					</div>
					<div class="left">
						<div class="event-info flexr">
							<div class="details">
								<h3>i-CONIC OPENING PARTY</h3>
								<div class="location flexr">
									<div class="date">
										<img class="calendar"
											src="https://cdn-images.island-conference.com/plan/calendar.png" />
										<p>Thursday, May 30</p>
									</div>
									<div class="place">
										<img class="location-icon"
											src="https://cdn-images.island-conference.com/plan/location.png" />
										<p>SUMMER BREEZE</p>
									</div>
								</div>
							</div>
							<div class="venue">
								<img class="location-img"
									src="https://cdn-images.island-conference.com/plan/events/breeze-logo.png" />
							</div>
						</div>
						<div class="description">
							<p>The first day of the conference ends with an i-Conic Opening party at one of the most
								prestigious clubs in Limassol, Summer Breeze!
							</p>
							<div class="sponsored">
								<p>Sponsored by:</p>
								<img src="https://cdn-images.island-conference.com/exhibitors/logos/propellerads.svg" alt="PropellerAds"/>
							</div>
							<div class="youtube">
								<button><a href="https://youtu.be/q7SSJSfWhzo"
										target="_blank" rel="nofollow"><div class="polygon"></div>Watch the aftermovie on YouTube</a> </button>
							</div>
						</div>
						<div class="reserve-btns-wrapper flexr">
							<button class="main-btn"
								(click)="openGuestListDialog('i-Conic Opening Party', 'opening','PropellerAds')">
								Add your crew on the Guestlist
								<!-- <span>Fully Booked</span> -->
							</button>
							<button class="reservevip-btn" (click)="openVIPReserveDialog('i-Conic Opening Party', 'opening')">
								Reserve Your VIP Table
								<!-- <span>Fully Booked</span> -->
							</button>
						</div>
						<div class="organized-by">
							<p>Organized by:</p>
							<div class="event-sponsors flexr">
								<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="closing-party event">
				<!-- <div class="booked-ribbon">Fully Booked</div> -->
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
						<!-- <div class="img-2"></div> -->
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						18:00 - 23:00
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details">
								<h3>OFFICIAL CLOSING PARTY</h3>
								<div class="location flexr">
									<div class="date">
										<img class="calendar"
											src="https://cdn-images.island-conference.com/plan/calendar.png" />
										<p>Friday, May 31</p>
									</div>
									<div class="place">
										<img class="location-icon"
											src="https://cdn-images.island-conference.com/plan/location.png" />
										<p>CAPTAIN'S CABIN</p>
									</div>
								</div>
							</div>
							<div class="venue">
								<!-- <img class="location-img" src="https://cdn-images.island-conference.com/plan/events/cc-logo.png" /> -->
								<img class="location-img"
									src="https://cdn-images.island-conference.com/plan/events/Captains-Cabin-gold.png" />
							</div>
						</div>
						<div class="description">
							<p>Finish off your i-Con experience with a sunset beach party, at the most premium venue at
								Lady's Mile beach, watching the sun going down and Limassol lighting up!
							</p>
							<div class="sponsored">
								<p>Sponsored by:</p>
								<img src="https://cdn-images.island-conference.com/exhibitors/logos/twinred.svg" alt="TwinRed"/>
							</div>
							<div class="youtube">
								<button><a href="https://youtu.be/fP79bw8wr44"
										target="_blank" rel="nofollow"><div class="polygon"></div>Watch the aftermovie on YouTube</a> </button>
							</div>
						</div>
						<div class="reserve-btns-wrapper flexr">
							<button class="main-btn"
								disabled>
								<!-- (click)="openGuestListDialog('Official Closing Party', 'closing', 'TwinRed')" -->
								Add your crew on the Guestlist
								<span>Fully Booked</span>
							</button>

							<!-- 
								<button class="reservevip-btn" (click)="openVIPReserveDialog('Official Closing Party', 'closing')">
								Reserve Your VIP Table
								<span>Fully Booked</span>
							</button>
							-->
							<button class="reservevip-btn" disabled>
								Reserve Your VIP Table
								<span>Fully Booked</span>
							</button>
						</div>
						<div class="organized-by">
							<p>Organized by:</p>
							<div class="event-sponsors flexr">
								<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							</div>
						</div>
						
					</div>
					<div class="right">
						<div class="event-flyer"></div>
						<!-- <video controls id="my_video_3" (mouseenter)="playVid('my_video_3')"
							(mouseleave)="pauseVid('my_video_3')" muted>
							<source src="https://cdn-images.island-conference.com/plan/events/Captains-Cabin.mp4" type="video/mp4"
								poster="https://cdn-images.island-conference.com/events/twinred-sponsorship-v2.jpg" />
							Browser not supported
						</video> -->
					</div>
				</div>
			</div>

			<h3 class="section-title" [ngClass]="referenceClass">Satellite Events</h3>
			<div class="vip event">
				<!-- <div class="booked-ribbon">Fully Booked</div> -->
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						19:00 - 23:00
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details">
								<h3>SKY SUITE VIP PARTY</h3>
								<div class="location flexr">
									<div class="date">
										<img class="calendar" src="https://cdn-images.island-conference.com/plan/calendar.png" />
										<p>Tuesday, May 28</p>
									</div>
									<div class="place">
										<img class="location-icon" src="https://cdn-images.island-conference.com/plan/location.png" />
										<p>Sky Suite, City of Dreams</p>
									</div>
								</div>
							</div>
							<div class="venue">
								<img class="location-img" src="https://cdn-images.island-conference.com/plan/events/cod-logo.svg" />
							</div>
						</div>
						<div class="description">
							<p>HOI Agency are kicking off the event early and hosting a VIP invite-only party in one
								of the exclusive Sky Suites at the City of Dreams. With panoramic views, premium
								drinks and a Haze resident DJ, what better way is there to get in the i-Con mood!
							</p>
							<div class="sponsored sky-suite">
								<p>Sponsored by:</p>
								<img src="https://cdn-images.island-conference.com/plan/events/hoi-agency.svg" alt="hoi agency logo"/>
							</div>
						</div>
						<div class="organized-by satellites">
							<p>Organized by:</p>
							<div class="event-sponsors flexr">
								<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="speak-dinner event">
				<!-- <div class="booked-ribbon">Fully Booked</div> -->
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
						<div class="img-2"></div>
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						19:00 - 21:00
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details">
								<h3>EXCLUSIVE SPEAKERS' DINNER</h3>
								<div class="location flexr">
									<div class="date">
										<img class="calendar"
											src="https://cdn-images.island-conference.com/plan/calendar.png" />
										<p>Wednesday, May 29</p>
									</div>
									<div class="place">
										<img class="location-icon"
											src="https://cdn-images.island-conference.com/plan/location.png" />
										<p>Dionysus Mansion, Limassol</p>
									</div>
								</div>
							</div>
							<div class="venue">
								<img class="location-img" src="https://cdn-images.island-conference.com/plan/events/dionysus_mansion.png" />
							</div>
						</div>
						<div class="description">
							<p>From 19:00 to 21:00, our Conference Speakers will meet at Dionysus Mansion for an exclusive networking dinner to relax and create connections in anticipation of the upcoming conference.
								<br/><br/>
								Our speakers will experience the ambiance of Dionysus Mansion, a unique space where culinary excellence meets vibrant networking. The cuisine consists of seasonal dishes with local Cypriot flavors, ensuring a delightful experience with every bite!
							</p>

							<div class="sponsored sky-suite">
								<p>Sponsored by:</p>
								<img src="https://cdn-images.island-conference.com/plan_speakers/speakers/clone-twin-logo-light-background.png" alt="PropellerAds"/>
							</div>
						</div>
						<div class="organized-by">
							<p>Organized by:</p>
							
							<div class="event-sponsors flexr">
								<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="event-flyer"></div>
					</div>
				</div>
			</div>
			
			<div class="spiritual event">
				<!-- <div class="booked-ribbon">Fully Booked</div> -->
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						08:30 - 09:30
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details">
								<h3>SPIRITUAL JOURNEY - DETOX</h3>
								<div class="location flexr">
									<div class="date">
										<img class="calendar"
											src="https://cdn-images.island-conference.com/plan/calendar.png" />
										<p>Thursday, May 30</p>
									</div>
									<div class="place">
										<img class="location-icon"
											src="https://cdn-images.island-conference.com/plan/location.png" />
										<p>City of Dreams - Outdoor area</p>
									</div>
								</div>
							</div>
							<div class="venue">
								<img class="location-img"
									src="https://cdn-images.island-conference.com/plan/events/cod-logo.svg" />
							</div>
						</div>
						<div class="description">
							<p>Start the 1st day of the conference rejuvenated, with an outdoor energy yoga healing
								session at City of Dreams! Prepare yourself both mentally and physically for a day full
								of networking opportunities and business connections! <span>Due to limited availability,
									please make sure to book your space in advance</span>
							</p>
						</div>
						<div class="reserve-btns-wrapper flexr">
							<button class="reservevip-btn"
								(click)="openGuestListDialog('Spiritual Journey - Detox', 'spiritual', 'loudbids')">
								Reserve Your Spot
								<!-- <span>Fully Booked</span> -->
							</button>
						</div>
						<!-- <div class="event-sponsors flexr">
							<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
							<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							<div><img src="https://cdn-images.island-conference.com/plan/events/cod-logo.svg" /></div>
						</div> -->
					</div>

				</div>
			</div>
			
			<div class="lunch event">
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						12:30 - 15:30
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details premium">
								<div class="box">
									<h3>FREE AURA LUNCH BREAK</h3>
									<div class="location flexr">
										<div class="date">
											<img class="calendar"
												src="https://cdn-images.island-conference.com/plan/calendar.png" />
											<p>Both Days</p>
										</div>
										<div class="place">
											<img class="location-icon"
												src="https://cdn-images.island-conference.com/plan/location.png" />
											<p>Aura Restaurant, City of Dreams</p>
										</div>
									</div>
								</div>
								<div class="box">
									<img class="premium-badge" src="https://cdn-images.island-conference.com/events/premium_holders_badge.png"/>
								</div>
							</div>
							<div class="venue flexr">
								<img class="location-img"
									src="https://cdn-images.island-conference.com/plan/events/cod-logo.svg" />
								<img class="location-img"
									src="https://cdn-images.island-conference.com/plan/events/aura-logo.svg" />
							</div>
						</div>
						<div class="description">
							<p>i-Con's Exhibitors and holders of the Premium tickets will have the privilege to
								experience the VIP 5-star lunch buffet prepared by the team of executive chefs at the
								City of Dreams.
								<br /><br />Go on a culinary journey on both days of the affiliate conference and let
								your taste buds savour the variety of international cuisines at Aura Restaurant.
								<br /><br />Walk by all the interactive cooking stations and try out unique delights
								from the Middle East, Asia, and The Mediterranean.
								<br /><br />Simply show your Exhibitor/Premium ticket badge and let your palate
								experience new flavours through each chef's creations.
							</p>
							<div class="sponsored sky-suite">
								<p>Sponsored by:</p>
								<img src="https://cdn-images.island-conference.com/exhibitors/logos/monrem.svg" alt="Monrem Logo"/>
							</div>
						</div>
						<!-- <div class="organized-by">
							<p>Organized by:</p>
							
							<div class="event-sponsors flexr">
								<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" /></div>
								<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							</div>
						</div> -->
					</div>

				</div>

			</div>
			
			<div class="miconos event">
				<!-- <div class="booked-ribbon">Fully Booked</div> -->
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
						<div class="img-2"></div>
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details">
								<h3>MICONOS MEETUP</h3>
								<div class="location flexr">
									<div class="date">
										<img class="calendar"
											src="https://cdn-images.island-conference.com/plan/calendar.png" />
										<p>Sunday, June 2 - Tuesday, June 4</p>
									</div>
									<div class="place">
										<img class="location-icon"
											src="https://cdn-images.island-conference.com/plan/location.png" />
										<p>Mykonos Island, Greece</p>
									</div>
								</div>
							</div>
						</div>
						<div class="description">
							<p>An invitation-only event on the enchanting island of Mykonos, which will bring together the minds from different industries across the globe! It invites the masterminds behind the largest corporations in the industry and allows them to connect, socialize and work in a more playful and relaxed setting!
							</p>
							<div class="youtube">
								<button><a href="https://www.youtube.com/watch?v=jMqGpiBO57I"
										target="_blank" rel="nofollow"><div class="polygon"></div>Watch the aftermovie on YouTube</a> </button>
							</div>
							<div class="sponsored">
								<p>Sponsored by:</p>
								<img src="https://cdn-images.island-conference.com/exhibitors/logos/adsempire.svg" alt="AdsEmpire"/>
								<img src="https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg" alt="Redwalking"/>
							</div>
							<div class="organizers">
								<p>Organized by:</p>
								<img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" />
								<img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" />
								<img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" />
								<img src="https://cdn-images.island-conference.com/plan/events/nexxie-logo-black.png" />
							</div>
						</div>
						<div class="reserve-btns-wrapper flexr">
							<!-- <button class="reservevip-btn" (click)="openVIPReserveDialog('Closing Party', 'closing')">
								Reserve Your VIP Table
							</button> -->
							<button class="main-btn" (click)="openTripDialog()">Enter the VIP-List</button>
						</div>
					</div>
					<div class="right">
						<div class="event-flyer"></div>
					</div>
				</div>
			</div>

			<!-- <div class="vip event">
				<div class="booked-ribbon">Fully Booked</div>
				<div class="top">
					<div class="background">
						<div class="img-1"></div>
					</div>
					<div class="event-time">
						<span><img src="https://cdn-images.island-conference.com/plan_schedule/time_icon.svg" /></span>
						19:00 - 23:00
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="event-info flexr">
							<div class="details">
								<h3>SKY SUITE VIP PARTY</h3>
								<div class="location flexr">
									<div class="date">
										<img class="calendar" src="https://cdn-images.island-conference.com/plan/calendar.png" />
										<p>Tuesday, June 27</p>
									</div>
									<div class="place">
										<img class="location-icon" src="https://cdn-images.island-conference.com/plan/location.png" />
										<p>Sky Suite, City of Dreams</p>
									</div>
								</div>
							</div>
							<div class="venue">
								<img class="location-img" src="https://cdn-images.island-conference.com/plan/events/cod-logo.svg" />
							</div>
						</div>
						<div class="description">
							<p>HOI Agency are kicking off the event early and hosting a VIP invite-only party in one
								of the exclusive Sky Suites at the City of Dreams. With panoramic views, premium
								drinks and a Haze resident DJ, what better way is there to get in the i-Con mood!
							</p>
						</div>
						<div class="event-sponsors flexr">
							<div><img src="https://cdn-images.island-conference.com/plan/events/i-con-logo.png" /></div>
							<div><img src="https://cdn-images.island-conference.com/plan/events/hoi-agency.svg" /></div>
							<div><img src="https://cdn-images.island-conference.com/plan/events/Haze-logo-8.png" /></div>
							<div><img src="https://cdn-images.island-conference.com/plan/events/BV_logo_white.png" /></div>
							<div><img src="https://cdn-images.island-conference.com/plan/events/cod-logo.svg" /></div>
						</div>
					</div>

				</div>
			</div> -->

		</div>
	</div>
</div>