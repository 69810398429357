import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOrderResponse } from '../interfaces/responses/order-response.interface';
import { ISponsorshipTypeResponse } from '../interfaces/responses/sponsorship-type-response.interface';
import { ISponsorshipWaitingListResponse } from '../interfaces/responses/sponsorship-waiting-list-response.interface';
import { ISponsorshipTypeReservationLoggedIn } from '../interfaces/sponsorship-type-reservation.interface';
import { ISponsorshipWaitingList } from '../interfaces/sponsorship-waiting-list.interface';
import { ISponsorshipType } from '../interfaces/sposnorships.interface';
import { AuthorizationService } from './authorization.service';
import { EnvironmentService } from './environment.service';
import { SponsorshipService } from './sponsorships.service';
import { StateService } from './state.service';

interface ISponsorshipState {
  data: ISponsorshipType;
  postData: ISponsorshipTypeResponse;
  pendingData: ISponsorshipWaitingListResponse;
  orderData: IOrderResponse;
}

const initialStateValue: any = {
  data: {},
  postData: {},
  orderData: {},
  pendingData: {
    response: {
      data: {},
      message: '',
      statusCode: 0,
      error: '',
    },
  },
};

@Injectable({ providedIn: 'root' })
export class ReserverSponsorshipService extends StateService<ISponsorshipState> {
  data$: Observable<ISponsorshipType> = this.getState(state => state.data);
  postData$: Observable<ISponsorshipTypeResponse> = this.getState(state => state.postData);
  orderData$: Observable<IOrderResponse> = this.getState(state => state.orderData);
  pendingData$: Observable<ISponsorshipWaitingListResponse> = this.getState(state => state.pendingData);

  constructor(
    private http: HttpClient,
    private ReserveSponsorshipData: SponsorshipService,
    private _environment: EnvironmentService,
    private authService: AuthorizationService
  ) {
    super(initialStateValue);
  }

  reserverSponsorshipItem(reserveDetails: object) {
    this.http.post<ISponsorshipTypeResponse>(`${this._environment.getApiUrl}/booking/sponsorships`, reserveDetails).subscribe({
      error: (error: any) => {
        this.setState({ postData: error.error });
      },
      next: (resposnse: any) => {
        this.setState({ postData: resposnse });
      },
    });
  }

  reserveSponsorshipLoggedIn(reserveDetails: ISponsorshipTypeReservationLoggedIn): void {
    this.http
      .post<IOrderResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/order/sponsorship`, reserveDetails, {
        withCredentials: true,
      })
      .subscribe({
        error: (error: any) => {
          this.setState({ orderData: error.error });
        },
        next: (resposnse: any) => {
          this.setState({ orderData: resposnse });
        },
      });
  }

  pendingSponsorship(userDetails: ISponsorshipWaitingList) {
    this.http.post<ISponsorshipWaitingListResponse>(`${this._environment.getApiUrl}/sponsorship-waiting-list`, userDetails).subscribe({
      error: (error: any) => {
        this.setState({ pendingData: error.error });
      },
      next: (resposnse: any) => {
        this.setState({ pendingData: resposnse });
      },
    });
  }

  resetFormState() {
    const initialData: ISponsorshipWaitingListResponse = {
      response: {
        message: [''],
        statusCode: 0,
        error: '',
      },
    };

    this.setState({ pendingData: initialData });
  }
}
