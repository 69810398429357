<div class="list-container">
	<div class="arrow-up" (click)="scrollToTop()">
		<img class="arrow" src="https://cdn-images.island-conference.com/sponsorships/arrow_up.svg" />
	</div>

	<div class="sponsor-item" *ngFor="let item of sponsorshipsList">
		<div class="top" [ngClass]="{'hasType': item.class !== 0}"
			style="background-image: url(https://cdn-images.island-conference.com/sponsorships/headers/{{item.backgroundImage}});">
			<!-- status -->
			<div class="reserved" *ngIf="item.status === 0">
				Reserved
			</div>
			<div class="pending" *ngIf="item.status === 1">
				Pending
			</div>

			<!-- class -->
			<div class="shared" *ngIf="item.class === 1">
				SHARED OPTION
			</div>
			<div class="combo" *ngIf="item.class === 2">
				COMBO DEAL
			</div>
			<!-- <img class="multiple-icon" src="https://cdn-images.island-conference.com/sponsorships/selection.svg"> -->
			<h3>{{item.name}}</h3>
		</div>
		<div class="bottom">

			<div class="row">
				<p class="description" [innerHtml]="item.description"></p>
				<ng-container *ngIf="item.reservedBy && item.status !== 1">
					<div class="sponsor-wrapper">
						<img *ngFor="let path of item.reservedBy" class="sponsor-img"
							src="https://cdn-images.island-conference.com/exhibitors/logos/{{path}}">
					</div>
				</ng-container>
				<ng-container *ngIf="item.inStock">
					<div class="price-container" *ngIf="item.class !== 2">
						<h3>&euro;{{item.price | number}}</h3>
					</div>
					<div class="combo-container" *ngIf="item.class === 2">
						<div class="discount-wrapper" *ngIf="item.boothCombo.length > 0">
							<s>
								<h4>&euro;{{item.price | number}}</h4>
							</s>
							<h3>&euro;{{getDiscountedPrice(item.price, item.discounts) | number}}</h3>
						</div>
					</div>
				</ng-container>
			</div>
			<div class="row">
				<div class="glory-wrapper" *ngIf="item.class === 1 && item.inStock">
					<div class="shared option" *ngIf="item.status === 2">
						<div class="details">
							<div class="left">
								<img class="multiple-icon"
									src="https://cdn-images.island-conference.com/sponsorships/shared.svg">
							</div>
							<div class="right">
								<h3>Shared</h3>
								<div class="price">
									<span>
										Up to {{item.maxCapacity}} <br>Sponsors
									</span>
									<p>&euro;{{item.price | number}} </p>
								</div>
							</div>
						</div>
						<button class="main-btn" (click)="goToForm(item.id, 1, item.name, item.price)">
							Reserve
						</button>
					</div>
					<div class="glory option" *ngIf="item.quantity === item.maxCapacity">
						<div class="details">
							<div class="left">
								<img class="glory-icon"
									src="https://cdn-images.island-conference.com/sponsorships/glory.svg">
							</div>
							<div class="right">
								<h3>Grab the Glory</h3>
								<div class="price">
									<span>
										Stand-Alone <br> Presence
									</span>
									<p>&euro;{{item.price * item.maxCapacity | number}} </p>
								</div>
							</div>
						</div>
						<button class="main-btn"
							(click)="goToForm(item.id, item.maxCapacity, item.name, item.price, item.discounts)">
							Reserve
						</button>
					</div>
				</div>
				<div class="combo-wrapper" *ngIf="item.class === 2">
					<img class="plus-icon" src="https://cdn-images.island-conference.com/sponsorships/plus.svg">
					<div class="combo-item"
						style="background-image: url(https://cdn-images.island-conference.com/sponsorships/headers/{{item.backgroundImage}});">
						<p>{{item.name}}</p>
						<p *ngIf="item.inStock">&euro;{{item.price - item.boothCombo[0].price | number}}</p>
					</div>
					<div class="combo-item booth"
						style="background-image: url(https://cdn-images.island-conference.com/attend/booths/{{item.boothCombo[0].id}}/{{item.boothCombo[0].comboImageUrl}});">
						<p>{{item.boothCombo[0].name}} Booth</p>
						<p *ngIf="item.inStock">&euro;{{item.boothCombo[0].price | number}}</p>
					</div>
				</div>
			</div>
			<div class="row">
				<button class="main-btn" (click)="goToForm(item.id, 1, item.name, item.price, item.discounts)"
					*ngIf="item.inStock && item.status === 2 && item.class !== 1">
					Reserve
				</button>
				<div class="pending-wrapper" *ngIf="item.inStock === true && item.status === 1">
					<button class="main-btn" (click)="openDialog(item.id, item.name)">
						Add me on the waiting list
					</button>
					<span>The current sponsorship is on pending status</span>
				</div>

			</div>
			<!-- <div class="row">				
				<button class="view-photos">
					View Photos
				</button>
			</div> -->
		</div>
	</div>



</div>