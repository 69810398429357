import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarService } from '@i-con/shared/common';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'i-con-root-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, FooterComponent],
  templateUrl: './root-layout.component.html',
  styleUrls: ['./root-layout.component.scss'],
})
export class RootLayoutComponent implements OnInit {
  @Input() className: string = '';
  sidebarOpened: boolean = false;
  constructor(private sidebarService: SidebarService) {
  }

  ngOnInit() {
    this.sidebarService.expanded$.subscribe(expanded => (this.sidebarOpened = expanded));
  }
}
