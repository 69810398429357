<div class="form-container" *ngIf="profileData$ | async as profileData">
    <form [formGroup]="bookingFormGroup" (ngSubmit)="submitDebounceForm()" class="form-wrapper">

        <div class="errors" *ngFor="let error of errorMessages">
            <p>{{error}}</p>
        </div>

        <h3 *ngIf="isPanel" class="profile-title">Edit Profile</h3>


        <ng-container *ngIf="isLoggedIn && !isPanel; else notLoggedin">
            <h3>Thank you for your interest in {{confirmationName}}!</h3>
            <p>Please confirm your reservation by clicking on the "Reserve" button below, and a member of our team will contact you to finalize your booking process!</p>
        </ng-container>

        <ng-template #notLoggedin>

            <i-con-booking-form 
            [fieldsDataPart1]="fieldsDataPart1" 
            [submitted]="submitted"
            [profileDetails]="profileData"
            ></i-con-booking-form>

            <i-con-billing-form [fieldsPart2]="fieldsDataPart2" [submitted]="submitted" [profileDetails]="profileData">

            </i-con-billing-form>
        
            <ng-container *ngIf="!isPanel">
                <i-con-terms-form [fieldsPart3]="fieldsDataPart3" [submitted]="submitted"></i-con-terms-form>
            </ng-container>
        </ng-template>

        <div class="buttons-wrapper">
            <button class="main-btn" type="submit" [disabled]="bookingFormGroup.invalid"  [ngClass]="{'invalid': bookingFormGroup.invalid}">{{btnText}}</button>
            <button class="back-btn" (click)="goBack()">Go Back</button>
        </div>        
    </form>
</div>
