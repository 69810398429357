<div class="card">
    <div class="images">
        <div style="--url: url({{transportationEvent.images[0]}})"></div>
        <div style="--url: url({{transportationEvent.images[1]}})"></div>
        <div style="--url: url({{transportationEvent.images[2]}})"></div>
    </div>
    <div class="content">
        <div class="row">
            <h3>{{transportationEvent.title}}</h3>
            <div class="logos">
                <img src="https://cdn-images.island-conference.com/plan/events/cod-logo.svg" alt="City Of Dreams logo" />
                <img src="{{transportationEvent.logoImage}}" alt="{{transportationEvent.venueName}} logo" />
            </div>
        </div>
        <hr/>
        <div class="timeslots">
            <div class="left">
                <div class="to-event">
                    <div class="route">
                        <div class="to-event-venue">
                            <div class="timeline">
                                <div class="event">
                                    <div class="line"></div>
                                    City Of Dreams
                                </div>
                                <div class="event filled">
                                    {{transportationEvent.venueName}}
                                </div>
                            </div>
                            <div class="available-slots">
                                <h3>
                                    <img src="https://cdn-images.island-conference.com/stay/transportation/timer.svg" /> Available Slots
                                </h3>
                                <p>
                                    {{transportationEvent.toEventAvailableSlotsFirst.join('   /   ')}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!transportationEvent.oneVenue" class="route">
                        <div class="to-event-venue">
                            <div class="timeline">
                                <div class="event">
                                    <div class="line"></div>
                                    Ajax Hotel
                                </div>
                                <div class="event filled">
                                    {{transportationEvent.venueName}}
                                </div>
                            </div>
                            <div class="available-slots">
                                <h3>
                                    <img src="https://cdn-images.island-conference.com/stay/transportation/timer.svg" /> Available Slots
                                </h3>
                                <p>
                                    {{transportationEvent.toEventAvailableSlotsSecond.join('   /   ')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="right">
                <div class="from-event">
                     
                    <div class="route">
                        <div class="from-event-venue">
                            <div class="timeline">
                                <div class="event">
                                    <div class="line"></div>
                                    {{transportationEvent.venueName}}
                                </div>
                                <div class="event filled">
                                    City Of Dreams
                                </div>
                            </div>
                            <div class="available-slots">
                                <h3>
                                    <img src="https://cdn-images.island-conference.com/stay/transportation/timer.svg" /> Available Slots
                                </h3>
                                <p>
                                    {{transportationEvent.fromEventAvailableSlotsFirst.join('   /   ')}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!transportationEvent.oneVenue" class="route">
                        <div class="from-event-venue">
                            <div class="timeline">
                                <div class="event">
                                    <div class="line"></div>
                                    {{transportationEvent.venueName}}
                                </div>
                                <div class="event filled">
                                    Ajax Hotel
                                </div>
                            </div>
                            <div class="available-slots">
                                <h3>
                                    <img src="https://cdn-images.island-conference.com/stay/transportation/timer.svg" /> Available Slots
                                </h3>
                                <p>
                                    {{transportationEvent.fromEventAvailableSlotsSecond.join('   /   ')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="body">
        <div class="content">
            <h3>{{transportationEvent.title}}</h3>
            <hr/>
            <div class="schedule">
            
                <div class="to-event-venue">
                    <div class="timeline">
                        <div class="event">
                            <div class="line"></div>
                            City Of Dreams
                        </div>
                        <div class="event">
                            <div class="line"></div>
                            Ajax Hotel
                        </div>
                        <div class="event filled">
                            {{transportationEvent.venueName}}
                        </div>
                    </div>
                    <div class="available-slots">
                        <h3>
                            <img src="https://cdn-images.island-conference.com/stay/transportation/timer.svg" /> Available Slots
                        </h3>
                        <p>
                            {{transportationEvent.toEventAvailableSlots.join('   /   ')}}
                        </p>
                    </div>
                </div>
                <div class="from-event-venue">
                    <div class="timeline">
                        <div class="event">
                            <div class="line"></div>
                            {{transportationEvent.venueName}}
                        </div>
                        <div class="event">
                            <div class="line"></div>
                            Ajax Hotel
                        </div>
                        <div class="event filled">
                            City Of Dreams
                        </div>
                    </div>
                    <div class="available-slots">
                        <h3>
                            <img src="https://cdn-images.island-conference.com/stay/transportation/timer.svg" /> Available Slots
                        </h3>
                        <p>
                            {{transportationEvent.fromEventAvailableSlots.join('   /   ')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="logos">
            <img src="https://cdn-images.island-conference.com/plan/events/cod-logo.svg" alt="City Of Dreams logo" />
            <img src="{{transportationEvent.logoImage}}" alt="{{transportationEvent.venueName}} logo" />
        </div>
    </div> -->
    
</div>