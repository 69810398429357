import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AuthorizationService, EnvironmentService } from '@i-con/shared/common';
import { CookieService } from 'ngx-cookie-service';
import { ResendCodeFormComponent } from '../../resend-code-form/resend-code-form.component';

@Component({
  standalone: true,
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, ResendCodeFormComponent],
})
export class LoginFormComponent implements OnInit {
  submitted: boolean = false;
  success: boolean = false;
  loginForm!: UntypedFormGroup;
  hasErrors: boolean = false;
  @Input() panelUrlRedirection?: string = '';
  step: number = 1;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    private authService: AuthorizationService,
    private cd: ChangeDetectorRef,
    private environment: EnvironmentService,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    public dialog: MatDialog
  ) {}

  onKey(event: any, i: number) {
    if (event.keyCode === 8 && i > 0) {
      this.renderer.selectRootElement('#digit-' + (i - 1)).focus();
    } else if (event.key.match(/^[0-9a-zA-Z]$/)) {
      if (i >= this.digits.length - 1) {
        this.renderer.selectRootElement('#email').focus();
      } else {
        this.renderer.selectRootElement('#digit-' + (i + 1)).focus();
      }
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      digits: this.formBuilder.array([
        this.formBuilder.control('', [Validators.required]),
        this.formBuilder.control('', [Validators.required]),
        this.formBuilder.control('', [Validators.required]),
        this.formBuilder.control('', [Validators.required]),
      ]),
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get validDigits() {
    return this.digits.status === 'INVALID' ? false : true;
  }

  get digits() {
    return this.loginForm.get('digits') as FormArray;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      const loginData = {
        code: this.digits.value.join(''),
        email: this.loginForm.controls['email'].value,
      };
      this.authService.login(loginData.code, loginData.email).subscribe({
        error: (error: unknown) => {
          
          this.hasErrors = true;
          return error;
        },
        next: (response: any) => {
          const token = response?.response.data.access_token;
          //if the status is a success or we have the token
          if (response.status === 201 || token) {
            const firstTimeLoginCookie = this.cookieService.get('ftl');
            
            if (firstTimeLoginCookie === '') {
              this.cookieService.set('ftl', '1', 400, '/', this.environment.getDomain);
            }
          }
          this.authService.getUser().subscribe({
            error: (error: unknown) => {
              
              return error;
            },
            next: (bookingResponse: any) => {
              const contact = bookingResponse.response.data.contact;
              const bookingId = bookingResponse.response.data.id;
              const profile = {
                name: contact.name,
                surname: contact.surname,
                bookingId: bookingId,
              };
              this.authService.setExpiration(token, profile);
              if (this.document.location.href.includes('account') || this.document.location.href.includes('portal')) {
                this.document.location.href = `${this.environment.getPortalUrl}${this.panelUrlRedirection}`;
              } else this.document.location.href = this.environment.getPortalUrl;

              return contact;
            },
          });
          return response;
        },
      });
    }
  }

  onReset() {
    //this.submitted = false;
    this.loginForm.reset();
  }
}
