import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqCategoryListComponent } from './pages/faq-category-list/faq-category-list.component';
import { FaqCategoryComponent } from './pages/faq-category/faq-category.component';
import { FaqQuestionComponent } from './pages/faq-question/faq-question.component';

const routes: Routes = [

    {
        path: '', component: FaqCategoryListComponent
    },
    {
      path: ':category_ref', component: FaqCategoryComponent
    },
    {
      path: ':category_ref/:question_ref_name', component: FaqQuestionComponent
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule { }