import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { faqData } from '../../data/faq-data';
import { IFaqCategory, SidebarService } from '@i-con/shared/common';


@Component({
  selector: 'app-faq-category-list',
  templateUrl: './faq-category-list.component.html',
  styleUrls: ['./faq-category-list.component.scss']
})
export class FaqCategoryListComponent implements OnInit {

	public faqData: any = faqData;

	@Input() class_name:string = 'faq_page';
	@Input() phrase: string = 'Need Some Help?';
	@Input() title1: string = 'ASK US ANYTHING';
	@Input() title2: string = '';

	expanded: boolean = false;

	constructor(private router: Router,
		private sidebarService: SidebarService) {
			this.sidebarService.expanded$.subscribe(expanded => (this.expanded = expanded));
	} 

	ngOnInit(): void {

	}

	goToCategory(faq: IFaqCategory): void {
		
		this.router.navigateByUrl('/faq/'+faq.ref_name);
	}

}
