<div class="terms-container" [formGroup]="termsForm">

    <div class="terms-wrapper" formGroupName="part3terms">
        <ng-container *ngFor="let field of fieldsPart3; let i = index;">
            <div class="field-container" class="field-container" [ngClass]="{'inline': field.fullLine}">
            
                <div formArrayName="part3" class="checkbox" *ngIf="field.fieldType === 'checkbox'">
                    <input 
                        type="checkbox"
                        class="{{field.class}}"
                        [formControlName]="i"
                        >
                    <label *ngIf="field.controlName === 'terms'; else consentLabel">
                        I agree to comply with the Attendees' 
                        <a href="#" (click)="openTerms()">Terms and Conditions</a>
                        and I acknowledge that I have read and understood the 
                        <a href="#" (click)="openPrivacy()">Privacy Policy</a>.
                    </label>

                    <ng-template #consentLabel>
                        <label>
                            I agree to be contacted by the Nexxie Group via e-mail for possible future collaborations with any of the Nexxie Group companies.
                        </label>
                    </ng-template>    

                </div>
                
                <div [ngClass]="{'checkbox': field.fieldType === 'checkbox'}" class="invalid-feedback" *ngIf="(termsForm.touched || submitted) && f[i].errors">
                    <div *ngIf="checkErrors(i, 'required')">
                        {{field.placeHolder}} is required.
                    </div>
                    <div *ngIf="checkErrors(i, 'maxlength')">
                        exceeded.
                    </div>
                    <div *ngIf="checkErrors(i, 'minlength')">
                        small
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="info" *ngIf="showInformationSection">
        <img src="https://cdn-images.island-conference.com/icons/info.svg" alt="Information">
        <label>This charge will appear as i-Con Conference on your credit card statement.</label>
    </div>
</div>