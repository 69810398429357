<div class="accommodation-list-page">
	<div class="accommodation-header">
		<h2>
			<span>CONFERENCE<br/></span> ACCOMMODATION
		</h2>
		<p>
			The i-Con team is delighted to offer you a list of the best hotels in the city of Limassol. We have cherry-picked hotels which are in close proximity to our main venue, which will not only offer you a luxurious stay, but also an amazing and relaxing experience. <br><br>
			We have secured a great number of rooms amongst the hotels listed below, so if you book your stay with us, rest assured that you are getting the best rates on the market. Additionally, all of our accommodation packages include breakfast for both days of the conference! 
		</p>
	</div>
	<ng-container *ngFor="let hotel of hotelsInfo">
		<div class="hotel-text-side">
			<div class="left">
				<div class="content">
					<div class="title">
						<h2>
							{{hotel.name}} 
						</h2>
						<img *ngIf="hotel.stars === 'four_stars'" class="stars" src="https://cdn-images.island-conference.com/stay/accommodation/stars-4.png" alt="4 stars" />
						<img *ngIf="hotel.stars === 'five_stars'" class="stars" src="https://cdn-images.island-conference.com/stay/accommodation/stars-5.png" alt="5 stars" />
					</div>
					<p class="price-range" *ngIf="hotel.priceRange"><img src="https://cdn-images.island-conference.com/stay/accommodation/price.svg" alt="price range" /> {{hotel.priceRange}}</p>
					<p class="description" [innerHTML]="hotel.description"></p>
					<ul>
						<li *ngFor="let item of hotel.includes">{{item}}</li>
					</ul>
					<div class="buttons">
						<app-nav-button class="main-btn" [type]="'internal'" [new_path]="navigateToHotel(hotel.ref_name)"
							[text]="'Request Quotation'" [target]="'_blank'"></app-nav-button>
						<div class="badges">
							<img class="shuttle" *ngIf="hotel.freeShuttleService" src="https://cdn-images.island-conference.com/stay/accommodation/shuttle-service.svg" alt="Free shuttle service" />
							<img class="budget" *ngIf="hotel.budgetFriendly" src="https://cdn-images.island-conference.com/stay/accommodation/budget-friendly.svg" alt="Budget friendly" />
						</div>
					</div>
				</div>
				<div class="photos">
					<div class="photo1" style="--url: url({{hotel.image1}})"></div>
					<div class="photo2" style="--url: url({{hotel.image2}})"></div>
				</div>
			</div>
			<div class="right"> 
				<div class="backdrop"></div>
				<img class="logo" src="{{hotel.logoUrl}}" alt="City of Dreams logo" />
				<div class="main-img" style="--url: url({{hotel.mainImage}});--url-mobile: url({{hotel.mainImageMobile}})"></div>
			</div>
		</div>
	</ng-container>
</div>
