import { Observable, Observer, Subject } from "rxjs";
import { IStream } from "../interfaces/stream.interface";
import { IStreamState } from "../interfaces/stream-state.interface";
import { IStreamConfig } from "../interfaces/stream-config.interface";
import { PlatformService } from "./platform.service";
import { InjectorHolderService } from "./injector-holder.service";
import { Injectable } from "@angular/core";

// @Injectable({providedIn: 'root'})
export class AudioStream implements IStream {
    protected state: IStreamState = {
        playing: false,
        trackInfo: {
            currentTrack: undefined,
            duration: undefined,
            currentTime: undefined,
            currentTrackArtist: undefined,
            currentTrackName: undefined,
            currentAlbumPhoto: undefined,
        }
    }

    private audio: HTMLAudioElement;
    private audioEvents: Array<string> = [
        'ended',
        'error',
        'play',
        'playing',
        'pause',
        'timeupdate',
        'canplay',
        'loadedmetadata',
        'loadstart'
    ];
    private stateChange: Subject<any> = new Subject();
    private config: IStreamConfig;
    protected platformService: PlatformService;


    constructor(config: IStreamConfig = {},
        platformService: PlatformService) {

            if (platformService.isBrowser) {
                this.audio = new Audio();
                this.config = config;
            }
            
        // this.platformService = InjectorHolderService.injector.get(PlatformService);



        // if (this.platformService.isBrowser) {
        //     this.audio = new Audio();
        //     this.config = config;
        // }
    }

    play(): void {
        this.audio.play();
    }

    pause(): void {
        this.audio.pause();
    }

    stop(): void {
        this.audio.currentTime = 0;
        this.audio.pause();
    }

    seekTo(time: number) {
        this.audio.currentTime = time;
    }

    setVolume(volume: number) {
        this.audio.volume = volume;
    }

    setMute(muted: boolean) {
        this.audio.muted = muted;
    }

    setSinkId(deviceId: string) {
        if (!(this.audio as any).setSinkId) {
            throw new Error('setSinkId is not supported by this browser');
        }

        (this.audio as any).setSinkId(deviceId);
    }

    events(): Observable<any> {
        return new Observable((obeserver: Observer<any>) => {
            const handler = (event: Event) => {
                obeserver.next(event);
                this.updateStateEvents(event);
            };
            this.addEvents(this.audio, this.audioEvents, handler);
            return () => {
                this.removeEvents(this.audio, this.audioEvents, handler);
            }
        })
    }

    protected addEvents(obj: any, events: Array<string>, handler?: any): void {
        events.forEach(event => {
            obj.addEventListener(event, handler);
        })
    }

    protected removeEvents(obj: any, events: Array<string>, handler?: any): void {
        events.forEach(event => {
            obj.removeEventListener(event, handler);
        });
    }

    protected updateStateEvents(event: Event): void {
        const trackInfo = this.state.trackInfo;
        switch (event.type) {
            case 'canplay':
                trackInfo.duration = this.audio.duration;
                this.state.playing = false;
                break;
            case 'playing':
                this.state.playing = true;
                break;
            case 'pause':
                this.state.playing = false;
                break;
            case 'timeupdate':
                trackInfo.currentTime = this.audio.currentTime;
                break;
            case 'error':
                this.state.playing = false;
                trackInfo.currentTime = 0;
                trackInfo.duration = 0;
                break;
        }
        this.stateChange.next(this.state);
    }

    getState(): Subject<any> {
        return this.stateChange;
    }


    loadTrack(src: string): void {
        if (this.audio) {
            this.stop();
        }
        this.audio.src = src;
        this.audio.autoplay = this.state.playing || this.config.autoPlay || false;
        this.audio.load();
    }

    protected updateStateProps(obj: any, prop: string, value: any): void {
        obj[prop] = value;
        this.stateChange.next(obj);
    }

}