import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentService, IProfileType, IProfileTypeResponse } from '../..';
import { ILoginResponse } from '../interfaces/responses/login-response.interface';

export interface IBooker {
  name: string;
  surname: string;
  bookingId: string;
}

export interface IBookerExp extends IBooker {
  exp: string;
}

@Injectable({ providedIn: 'platform' })
export class AuthorizationService {
  constructor(private http: HttpClient, private _environment: EnvironmentService, private cookieService: CookieService) {}

  public isLoggedIn() {
    const cookie: string = this.cookieService.get('ea');
    if (cookie) {
      const bookerString: string = atob(cookie);
      const booker: IBookerExp = JSON.parse(bookerString);
      const exp: number = booker.exp as unknown as number;
      const date = new Date(exp * 1000);
      // console.log('exp: ' + date);
      if (new Date() < date) {
        return true;
      }
    }

    return false;
  }

  public getBookingId(): string | null {
    const cookie: string = this.cookieService.get('ea');
    if (cookie) {
      const bookerString: string = atob(cookie);
      const booker: IBookerExp = JSON.parse(bookerString);
      const bookingId: string = booker.bookingId as unknown as string;
      return bookingId;
    }
    return null;
  }

  public setExpiration(token: string, profile: IBooker) {
    const payload = jwtDecode(token);
    if (payload && 'exp' in payload) {
      const expiry = payload.exp as number;
      const value = {
        name: profile.name,
        surname: profile.surname,
        bookingId: profile.bookingId,
        exp: payload.exp!.toString(),
      };
      this.setCookie('ea', btoa(JSON.stringify(value)), expiry, '/', this._environment.getDomain);
    }
    return;
  }

  public setProfileCookie(profileDetails: IProfileType){
    const cookie: string = this.cookieService.get('ea');

    if (cookie) {
      const cookieDetails = JSON.parse(atob(cookie));

      if ('exp' in cookieDetails) {
        const expiry = cookieDetails.exp as number;
        const encodedDetails = btoa(encodeURIComponent(JSON.stringify(profileDetails)));

        this.setCookie('usr', encodedDetails, expiry,'/', this._environment.getDomain);
      }
    }
  }

  private setCookie(name: string, value: string, expire: number, path: string = '/', domain: string) {
    const d: Date = new Date(expire * 1000);
    const expires: string = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value};domain=${domain};${expires}${cpath}`;
  }

  public login(code: string, email: string) {

    const lowerEmail = email.toLowerCase();
    const capCode = code.toUpperCase();

    return this.http.post<ILoginResponse>(
      `${this._environment.getApiUrl}/booking/login`,
      {
        email: lowerEmail,
        code: capCode,
      },
      {
        withCredentials: true,
      }
    );
  }

  public logout() {
    //delete cookies
    this.cookieService.delete('ea', '/', this._environment.getDomain);
    this.cookieService.delete('usr', '/', this._environment.getDomain);
  }

  public getUser() {
    return this.http.get<unknown>(`${this._environment.getApiUrl}/booking`, { withCredentials: true });
  }

  public getFirstTimeLoginCookie() {
    return this.cookieService.get('ftl');
  }

  public setFirstTimeLoginCookieAfterFirstLogin() {
    this.cookieService.set('ftl', '1T', 400, '/', this._environment.getDomain);
  }
}
