import { CommonModule } from '@angular/common';
import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginFormComponent } from './components/login-form.component';

@Component({
  selector: 'i-con-login-dialog',
  standalone: true,
  imports: [CommonModule, RouterModule, MatDialogModule, LoginFormComponent],
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  ngOnInit(): void {

  }

  closeAllDialogs() {
    this.dialog.closeAll();
  }


}
