<div class="stay-accommodation-request-page">
	<div class="container">
		<div class="selected-hotel flexr">
			<div class="details">
				<div class="title">
					<h3>{{ selectedHotel.name }}</h3>
					<img class="stars" src="https://cdn-images.island-conference.com/stay/{{ selectedHotel.stars }}.svg"
						alt="{{selectedHotel.altStars}}" />
				</div>
				<div class="description">
					<p [innerHTML]="selectedHotel.description"></p>
				</div>
			</div>
			<div class="img-gallery">
				<div class="left">
					<div class="img-container-1">
						<img class="hotel-img" src="{{ selectedHotel.image1 }}" alt="{{selectedHotel.alt1}}" />
					</div>
					<div class="img-container-2">
						<img class="hotel-img" src="{{ selectedHotel.image2 }}" alt="{{selectedHotel.alt2}}" />
					</div>
				</div>
				<div class="right">
					<div class="img-container-3">
						<img class="hotel-img" src="{{ selectedHotel.mainImage }}" alt="{{selectedHotel.alt3}}" />
					</div>
				</div>
			</div>
		</div>

		<div class="persons-selection">
			<div class="left">
				<h3>Request a Quotation</h3>
				<p>{{ selectedHotel.name }}</p>
			</div>
			<div class="right">
				<form class="form" [formGroup]="dateForm">
					<div class="input-container range-field">
						<mat-form-field appearance="fill" (click)="picker.open()">
							<mat-label>Check in - Check out</mat-label>
							<mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
								<input formControlName="startDate" matStartDate placeholder="Start date" />
								<input formControlName="endDate" matEndDate placeholder="End date" />
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
						<div *ngIf="submitted && f['startDate'].errors" class="invalid-feedback start">
							<div *ngIf="f['startDate'].errors['required']">Check-in date is required</div>
						</div>
						<div *ngIf="submitted && f['endDate'].errors && !f['startDate'].errors"
							class="invalid-feedback end">
							<div *ngIf="f['endDate'].errors['required']">Check-out date is required</div>
						</div>
						<div *ngIf="submitted && wrongRange" class="invalid-feedback end">
							<div>Select a range of maximum 10 days</div>
						</div>
					</div>
					<div class="input-container persons-field">
						<img class="avatar" src="https://cdn-images.island-conference.com/stay/avatar.svg" />
						<div class="quantity-container">
							<div class="minus" (click)="decreasePersons()">-</div>
							<div class="number">{{ numberOfPersons }}</div>
							<div class="plus" (click)="increasePersons()">+</div>
						</div>
						<div *ngIf="submitted && numberOfPersons === 0" class="invalid-feedback start">
							<div>Select at least one person</div>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="next-section">
			<button class="main-btn" (click)="onSubmit()">Next</button>
			<h3><span>Still not found where to stay?</span> Check out our other Hotels</h3>
		</div>

		<div class="hotel-list">
			<ng-container *ngFor="let hotel of hotelsInfo">
				<div class="list-item" *ngIf="hotel !== selectedHotel">
					<div class="left">
						<img class="main-img" src="{{ hotel.mainImage }}" alt="{{hotel.alt3}}" />
					</div>
					<div class="middle">
						<div class="title">
							<h3>{{ hotel.name }}</h3>
							<img class="stars" src="https://cdn-images.island-conference.com/stay/{{ hotel.stars }}.svg"
								alt="{{hotel.altStars}}" />
						</div>
						<p [innerHTML]="hotel.description"></p>


					</div>
					<div class="right">
						<button class="main-btn" (click)="changeSelectedHotel(hotel)">Request Quotation</button>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>