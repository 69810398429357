import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService, EnvironmentService } from '@i-con/shared/common';

@Component({
  selector: 'i-con-logout-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logout-dialog.component.html',
  styleUrl: './logout-dialog.component.scss',
})
export class LogoutDialogComponent {
  //data will be an interface when we pass actual data
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    private authService: AuthorizationService,
    private _environment: EnvironmentService
  ) {
    if (data) {
      if (typeof window !== 'undefined' && window.innerWidth >= 768) {
        const modalEl = this.document.querySelector(".cdk-global-overlay-wrapper[dir='ltr']");
        modalEl?.setAttribute('style', 'padding-left: 260px');
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  continueClick() {
    this.authService.logout();
    this.closeDialog();
    if (typeof window !== 'undefined') window.location.href = this._environment.getAppUrl;
  }
}
