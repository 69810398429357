<div class="footer-section flexr" [ngClass]="screenclass">
	<div class="left">
		<p>&#169; {{ this.currentYear }} Island Conference</p>
	</div>
	<div class="links">
		<ul class="social-items flexr">
			<li class="social-item"><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
			<li class="social-item"><a [routerLink]="['/terms-and-conditions']">Terms and Conditions</a></li>
			 <li class="social-item"><a [routerLink]="['/faq']">FAQ</a></li>
			<li class="social-item"><a [routerLink]="['/download-app']">Mobile App</a></li>
			<li class="social-item"><a [routerLink]="['/contact']">Contact us</a></li>
		</ul>
	</div>

	<div class="socials">
			<a class="social-link" href="https://www.facebook.com/IslandConference" target="_blank" rel="nofollow">
				<img class="social-icon fb_icon" src="https://cdn-images.island-conference.com/footer/fb_black.svg" alt="Facebook favicon. Icon and circle border are black and white." />
			</a>
			<a class="social-link" href="https://www.instagram.com/islandconference_icon/" target="_blank" rel="nofollow">
				<img class="social-icon insta_icon" src="https://cdn-images.island-conference.com/footer/insta_black.svg"  alt="Instagram favicon. Icon and circle border are black and white"/>
			</a>
			<a class="social-link" href="https://www.linkedin.com/company/islandconference-i-con/" target="_blank" rel="nofollow">
				<img class="social-icon linked_icon" src="https://cdn-images.island-conference.com/footer/link_black.svg" alt="LinkedIn favicon. Icon and circle border are black and white"/>
			</a>
			<a class="social-link" href="https://t.me/IslandConferenceICON" target="_blank" rel="nofollow">
				<img class="social-icon telegram_icon" src="https://cdn-images.island-conference.com/footer/telegram_black3.svg" alt="Telegram favicon. Icon and circle border are black and white"/>
			</a>
			<a class="social-link" href="https://open.spotify.com/playlist/2hPmS5N3eDHsM3X1mTqfRP?si=4cLo49xpTBGbSz9ZJt4r0g&nd=1" target="_blank" rel="nofollow">
				<img class="social-icon spotify_icon" src="https://cdn-images.island-conference.com/footer/spotify_new_black.svg" alt="Spotify favicon. Icon and circle border are black and white"/>
			</a>
			<a class="social-link" href="https://www.youtube.com/@Island-Conference" target="_blank" rel="nofollow">
				<img class="social-icon youtube_icon" src="https://cdn-images.island-conference.com/footer/youtube-new-black.svg" alt="Youtube favicon. Icon and circle border are black and white"/>
			</a>
	</div>
</div>

<div class="additionalInfo" [ngClass]="screenclass" *ngIf="screenclass === 'homepage'">
	<div class="homepageAdditions">		
		<p>23 Aristotellous Savva, Paphos 8025</p>
		<a href="https://nexxie.com" target="_blank" class="center-company">Nexxie Group Ltd</a>
		<a href="mailto:{{email}}" target="_blank">{{email}}</a>
	</div>
</div>