import { IEventTransportation } from '@i-con/shared/common';

export const eventsTransportationData: IEventTransportation[] = [
  {
    id: 1,
    venueName: 'La Caleta',
    title: 'WELCOME PARTY AT LA CALETA',
    logoImage: 'https://cdn-images.island-conference.com/stay/transportation/la-caleta-logo.png',
    images: [
      'https://cdn-images.island-conference.com/stay/transportation/la-caleta-image-1.jpg',
      'https://cdn-images.island-conference.com/stay/transportation/la-caleta-image-2.jpg',
      'https://cdn-images.island-conference.com/stay/transportation/la-caleta-image-3.jpg',
    ],
    toEventAvailableSlotsFirst: ["18:30", "19:00", "19:30"],
    toEventAvailableSlotsSecond: ["18:30", "19:30"],
    fromEventAvailableSlotsFirst: ['22:30', '23:00', '23:30'],
    fromEventAvailableSlotsSecond: ['22:30', '23:30'],
  },
  {
    id: 2,
    venueName: 'Breeze Club',
    title: 'OPENING PARTY AT BREEZE CLUB',
    logoImage: 'https://cdn-images.island-conference.com/stay/transportation/summer-breeze-logo.png',
    images: [
      'https://cdn-images.island-conference.com/stay/transportation/breeze-image-1.jpg',
      'https://cdn-images.island-conference.com/stay/transportation/breeze-image-3.jpg',
      'https://cdn-images.island-conference.com/stay/transportation/breeze-image-2.jpg',
    ],
    toEventAvailableSlotsFirst: ['21:30', '22:00', '22:30'],
    toEventAvailableSlotsSecond: ['21:30', '22:00'],
    fromEventAvailableSlotsFirst: ['01:30', '02:00', '02:30'],
    fromEventAvailableSlotsSecond: ['01:30', '02:30'],
  },
  {
    id: 3,
    venueName: "Captain's Cabin",
    oneVenue: true,
    title: "CLOSING PARTY AT CAPTAIN'S CABIN - LADY'S MILE BEACH",
    logoImage: 'https://cdn-images.island-conference.com/stay/transportation/captains-cabin-logo.png',
    images: [
      'https://cdn-images.island-conference.com/stay/transportation/captains-cabin-image1.jpg',
      'https://cdn-images.island-conference.com/stay/transportation/captains-cabin-image2.jpg',
      'https://cdn-images.island-conference.com/stay/transportation/captains-cabin-image3.jpg',
    ],
    toEventAvailableSlotsFirst: ['17:30', '18:00', '18:30', '19:00', '20:00','2100'],
    toEventAvailableSlotsSecond: [],
    fromEventAvailableSlotsFirst: ['22:30', '23:00', '23:30', '00:00'],
    fromEventAvailableSlotsSecond: ['22:30', '23:00', '23:30', '00:00'],
  },
];
