import { IHotels } from "@i-con/shared/common";


export const hotelsData: IHotels[] = [
    {
        id: 1,
        ref_name: "city-of-dreams",
        name: "City Of Dreams",
        stars: "five_stars",
        altStars:"Five stars in a line coloured in orange.",
        description: "No other venue could be as deserving of hosting i-Con as the City of Dreams.\
         Among the available conference venues with accommodation in Limassol, \
         the biggest Casino resort in Europe was an easy pick!\
         <br/><br/>As the very first integrated resort in Europe, City of Dreams is perfectly \
         equipped to accommodate a large number of guests while maintaining their \
         quality and elegance. There's also no better place to stay if you want to get a head \
         start on i-Con's Expo for both days of the conference.<br/><br/>If you'd like to secure \
         a room with City of Dreams, please contact the i-Con team or request a quotation using \
         our link below. Please keep in mind that the City of Dreams is currently in high demand, \
         and as such, the earlier you book your rooms, the more likely you'll be to secure them \
         for Island Conference!",
        includes: ["Free Shuttle Service from Event Hotel to all Party Venues"],
        priceRange: "Price Range: €360 - €1960/night (B&B)",
        logoUrl: "https://cdn-images.island-conference.com/stay/accommodation/cod-logo.svg",
        image1: "https://cdn-images.island-conference.com/stay/accommodation/city-of-dreams-indoor-1.jpg",
        image2: "https://cdn-images.island-conference.com/stay/accommodation/city-of-dreams-indoor-2.jpg",
        mainImage: "https://cdn-images.island-conference.com/stay/accommodation/city-of-dreams-outdoor.jpg",
        mainImageMobile: "https://cdn-images.island-conference.com/stay/accommodation/city-of-dreams-outboor-mobile.jpg",
        alt1:"",
        alt2:"",
        alt3:"Aerial view of City of Dreams Casino resort.",
        freeShuttleService: true,
    },
    // {
    //     id: 2,
    //     ref_name: "royal-apollonia",
    //     name: "Royal Apollonia",
    //     stars: "five_stars",
    //     altStars:"Five stars in a line coloured in orange.",
    //     description: "Royal Apollonia made our search easy to find the perfect combination of first-class \
    //     hospitality and picturesque views of the island. The resort itself has received multiple hospitality \
    //     awards over the years including the Traveler's Choice Award of TripAdvisor. \
    //     <br/><br/>As a 5-star hotel, Royal Apollonia has the highest standards as far as service goes, \
    //     featuring 204 rooms of which 13 are suites, its own outdoor pool and 3 restaurants with a range \
    //     of different cuisines to try out!",
    //     includes: ["Approx. 20 min driving distance from Event Hotel"],
    //     priceRange: "Price Range: €248 - €275/night (B&B)",
    //     logoUrl: "https://cdn-images.island-conference.com/stay/accommodation/royal-apollonia-logo.svg",
    //     image1: "https://cdn-images.island-conference.com/stay/accommodation/royal-apollonia-bedroom-1.jpg",
    //     image2: "https://cdn-images.island-conference.com/stay/accommodation/royal-apollonia-bedroom-2.jpg",
    //     mainImage: "https://cdn-images.island-conference.com/stay/accommodation/royal-apollonia-outdoor2.jpg",
    //     mainImageMobile: "https://cdn-images.island-conference.com/stay/accommodation/royal-mobile.jpg",
    //     alt1:"",
    //     alt2:"",
    //     alt3:""
    // },
    {
        id: 3,
        ref_name: "atlantica-oasis-hotel",
        name: "Atlantica Oasis Hotel",
        stars: "four_stars",
        altStars:"Four stars in a line coloured in orange.",
        description: "With its location perfectly situated within walking distance from the beach while \
        offering a beautiful pool area guaranteed to keep you on your sunbeds, you won't want to be anywhere else. \
        <br/><br/>Atlantica Oasis is no more than a 25-minute drive from our Venue, making it convenient for our\
        attendees but also placing it at an ideal location to enjoy the Limassol nightlife!",
        includes: ["Approx. 20 min driving distance from Event Hotel"],
        priceRange: "Price Range: €130 - €155/night (B&B)",
        logoUrl: "https://cdn-images.island-conference.com/stay/accommodation/atlantica-logo.svg",
        image1: "https://cdn-images.island-conference.com/stay/accommodation/atlantica-indoor-1.jpg",
        image2: "https://cdn-images.island-conference.com/stay/accommodation/atlantica-indoor-2.jpg",
        mainImage: "https://cdn-images.island-conference.com/stay/accommodation/atlantica-outdoor.jpg",
        mainImageMobile: "https://cdn-images.island-conference.com/stay/accommodation/atlantica-outdoor-mobile.jpg",
        alt1:"",
        alt2:"",
        alt3:""
    },
    {
        id: 4, //IF YOU EVER CHANGE THE ID MAKE SURE TO ADJUST IT ON stay-accommodation-request.component.html AND stay-accommodation-request.component.ts!
        ref_name: "ajax-hotel",
        name: "Ajax Hotel",
        stars: "four_stars",
        altStars:"Four stars in a line coloured in orange.",
        description: "Discover a haven of comfort and convenience at Ajax Hotel. Nestled in the heart of Limassol, \
        the hotel is currently being renovated to offer a perfect blend of elegance and trendiness, making it a great \
        choice for your conference accommodation.<br/><br/>With spacious rooms, world-class amenities, and warm, \
        attentive staff, your stay promises to be an unforgettable experience.",
        includes: ["Approx. 20 min driving distance from Event Hotel","Shuttle Service from Ajax to Event Hotel"],
        priceRange: "This specific hotel is available only for the Affiliate Combo tickets.",
        logoUrl: "https://cdn-images.island-conference.com/stay/accommodation/ajax-logo-white.svg",
        image1: "https://cdn-images.island-conference.com/stay/accommodation/ajax-indoor-1.jpg",
        image2: "https://cdn-images.island-conference.com/stay/accommodation/ajax-indoor-2.jpg",
        mainImage: "https://cdn-images.island-conference.com/stay/accommodation/ajax-outdoor.jpg",
        mainImageMobile: "https://cdn-images.island-conference.com/stay/accommodation/ajax-mobile.jpg",
        alt1:"",
        alt2:"",
        alt3:"",
        freeShuttleService: true,
        budgetFriendly: true
    }
    
];