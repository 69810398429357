import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { StateService } from "./state.service";
import { IProfileType } from "../interfaces/profile-type.interface";
import { EnvironmentService } from "./environment.service";
import { IProfileTypeResponse } from "../interfaces/responses/profile-type-response.interface";
import { AuthorizationService } from "./authorization.service";
import { CookieService } from "ngx-cookie-service";
import { SnackBarService } from "./snack-bar.service";

interface IProfileDetailsState {
    data: IProfileType
}

const initialProfileValue : IProfileDetailsState = {
    data : {
        contactDetails: {
            id: 0,
            name: "",
            surname: "",
            email: "",
            termsAgreement: false,
            optinConsent: false
        },
        billingDetails: {
            id: 0,
            city: "",
            address: "",
            country: "",
            postalCode: "",
            companyName: "",
            vatNumber: ""
        }
    }
}
@Injectable({ providedIn: 'root' })
export class ProfileService extends StateService<IProfileDetailsState> {

    profileData$: Observable<IProfileType> = this.getState((state) => state.data)

    constructor(
        private authService: AuthorizationService,
        private http:HttpClient,
        private _environment: EnvironmentService,
        private cookieService: CookieService,
        private snackBarService: SnackBarService,
    ) {
        super(initialProfileValue);
    }

    getProfileData() {
        const cookieProfile = this.cookieService.get('usr');

        if (cookieProfile) {
            const profileData = JSON.parse(decodeURIComponent(atob(cookieProfile)));
            this.setState({data: profileData});
        }
        else {
            this.http.get<IProfileTypeResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/contact`,
            {withCredentials: true}).subscribe({
                next: (res) => {
                    this.setState({data: res.response.data});
                    this.authService.setProfileCookie(res.response.data);
                },
                error: () => {
                    // DO NOT DELETE ERROR FUNCTION
                }
            });
        }
    }

    updateProfileData(profileInfo: IProfileType) {
        this.http.put<IProfileTypeResponse>(`${this._environment.getApiUrl}/booking/${this.authService.getBookingId()}/contact`,
        profileInfo,
        {withCredentials: true})
        .subscribe({
            next: (res) => {
                this.authService.setProfileCookie(res.response.data);
                this.setState({data: res.response.data});
                this.snackBarService.showSnackBar("Details Succesfully Upated!");
            },
            error: () => {

            }
        });
    }
}