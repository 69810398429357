<div class="form-container">
    <div
    [formGroup]="bookingForm" class="form-wrapper">
        <div class="part-one" formGroupName="part1booking">
            <h3 class="form-head">Contact Details</h3>
            <div class="field-wrapper">
                <ng-container *ngFor="let field of fieldsDataPart1; let i = index;">                
                    <div formArrayName="part1" class="field-container" [ngClass]="{'inline': field.fullLine}">
                        <input 
                            [attr.type]="field.fieldType"
                            class="{{field.class}}"
                            [placeholder]="field.placeHolder"
                            [formControlName]="i"
                        >
                        <div class="invalid-feedback" *ngIf="(bookingForm.touched || submitted)  && f[i].errors">
                            <div *ngIf="checkErrors(i, 'required')">
                                {{field.placeHolder}} is required.
                            </div>
                            <div *ngIf="checkErrors(i, 'email')">
                                Invalid email format.
                            </div>
                            <div *ngIf="checkErrors(i, 'maxlength')">
                                A maximum of {{field.validations.maxLength}} characters length is required.
                            </div>
                            <div *ngIf="checkErrors(i, 'minlength')">
                                A maximum of {{field.validations.minLength}} characters length is required.
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>            
        </div>
    </div>
</div>