import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PlaylistAudioStream } from '@i-con/shared/common';

@Component({
  selector: 'i-con-music-player',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './music-player.component.html',
  styleUrl: './music-player.component.scss',
})
export class MusicPlayerComponent implements OnInit {
  @Input() isPlaying: boolean = false;
  artistName: string = '';
  trackName: string = '';
  albumPhoto: string = '';
  inTransition: boolean = false;
  isOpen: boolean = false;
  isHidden: boolean = false;

  constructor(private playlistAudioStream: PlaylistAudioStream, private ref: ChangeDetectorRef) {
  
  }
  
  ngOnInit(): void {
    this.playlistAudioStream.getState().pipe().subscribe((state: any) => {
      this.isPlaying = state.playing;
      this.artistName = state.trackInfo.currentTrackArtist;
      this.trackName = state.trackInfo.currentTrackName;
      this.albumPhoto = state.trackInfo.currentAlbumPhoto;
      this.ref.markForCheck();
    });

    this.isHidden = true;
  }

  toogglePlay() {
    this.isPlaying ? this.playlistAudioStream.pause() : this.playlistAudioStream.play();
  }

  play(): void {
    this.playlistAudioStream.play();
  }

  pause(): void {
    this.playlistAudioStream.pause();
  }

  next(): void {
    if (this.isPlaying) {
      this.inTransition = true;
      this.playlistAudioStream.next();
    }

  }

  previous(): void {
    if (this.isPlaying)
      this.playlistAudioStream.previous();
  }

  toogglePlayer(): void {
    this.isOpen = !this.isOpen;
    if (this.isHidden) {
      this.isHidden = false;
    } else {
      this.isHidden = true;
    }


  }

}
