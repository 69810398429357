import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';

@Component({
  selector: 'app-nav-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent {
  @Input() new_path!: string[];
  @Input() target!: string;
  @Input() text!: string;
  @Input() class!: string;
  @Input() type!: string;
  @Input() queryParams!: Params;
  @Input() disabled!: boolean;

  constructor(private router: Router) {}

  navigate(path: string[], type: string, target: string, params?: Params) {
    if (type == 'external') {
      window.open(path[0], target);
    } else {
      
      this.router.navigate(path, { queryParams: params });
    }
  }
}
