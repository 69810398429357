import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IEventTransportation } from '@i-con/shared/common';

@Component({
  selector: 'transportation-event-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './transportation-event-card.component.html',
  styleUrls: ['./transportation-event-card.component.scss'],
})
export class TransportationEventCardComponent {
  @Input() transportationEvent!: IEventTransportation;
}
