import { CommonModule } from '@angular/common';
import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'i-con-photos-dialog',
  standalone: true,
  imports: [CommonModule, RouterModule, SlickCarouselModule, MatDialogModule],
  templateUrl: './photos-dialog.component.html',
  styleUrls: ['./photos-dialog.component.scss'],
})
export class PhotosDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
  }

  @ViewChild("slickModalBoothPhotos") slickModalBoothPhotos!: SlickCarouselComponent;
  @ViewChild("slickModalBoothThumbs") slickModalBoothThumbs!: SlickCarouselComponent;

  boothPhotosSlideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay": false,
    "dots": false,
    "infinite": false,
    "arrows": false,
    "asNavFor": '.boothPhotosCarouselThumbs',
    "responsive": [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  boothThumbsSlideConfig = {
    "speed": 300,
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "cssEase": 'linear',
    "autoplay": false,
    "centerMode": true,
    "draggable": false,
    "focusOnSelect": true,
    "arrows": true,
    "asNavFor": '.boothPhotosCarousel',
    "prevArrow": '<div class="ts-prev-arrow"><img class="prev-arrow" src="https://cdn-images.island-conference.com/icons/chevron-left.svg"></div>',
    "nextArrow": '<div class="ts-next-arrow"><img class="next-arrow" src="https://cdn-images.island-conference.com/icons/chevron-right.svg"></div>',
    "responsive": [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  
  closeAllDialogs() {
    this.dialog.closeAll();
  }


}
