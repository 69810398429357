import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StateService } from './state.service';
import { EnvironmentService } from './environment.service';
import { IContactUsTypeResponse } from '../interfaces/responses/contact-us-type-response.interface';


interface IContactUsState {
	postData: IContactUsTypeResponse;
}

const initialStateValue: any = {
	postData: {},
};

@Injectable({ providedIn: 'root' })
export class ContactUsService extends StateService<IContactUsState> {
	postData$: Observable<IContactUsTypeResponse> = this.getState((state) => state.postData);

	constructor(private http: HttpClient,
		private _environment: EnvironmentService) {
		super(initialStateValue);
	}

	contactUsService(contactInfo: object) {
		this.http.post<IContactUsTypeResponse>(`${this._environment.getApiUrl}/contact-us`, contactInfo).subscribe({
			error: (error: any) => {
				this.setState({ postData: error.error });
			},
			next: (resposnse: any) => {
				this.setState({ postData: resposnse });
			},
		});
	}
}
