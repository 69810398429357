<div class="custom--select" [ngClass]="select_class">
	<div class="icon" *ngIf="prefixIconUrl">
		<img [src]="prefixIconUrl" />
	</div>
	<mat-select [id]="formControlName" [placeholder]="placeholder" [disabled]="disableSelect.value"
		[(ngModel)]="selected_item_id" (selectionChange)="change($event)"
		[ngClass]="{ 'value-selected': selected_item_id !== '' }" (ngModelChange)="onChange($event)">
		<mat-option *ngFor="let item of items" [value]="item.key" [disabled]="item.disabled">
			{{ item.text }} <span class="sub-text" *ngIf="item.subtext">{{item.subtext}}</span>
		</mat-option>
	</mat-select>
</div>