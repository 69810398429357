<div class="booth-photos-dialog">
    <div class="top">
        <button class="close-btn" mat-button mat-dialog-close>X</button>
    </div>
    <div class="photos-slides">
        <ngx-slick-carousel class="boothPhotosCarousel" #slickModalBoothPhotos="slick-carousel" [config]="boothPhotosSlideConfig">
            <div ngxSlickItem class="slide" *ngFor="let sdata of data?.images;">
                <!-- <img src="{{sdata.url}}" alt="{{sdata.altText}}"/> -->
                <div class="img" style="background-image:url( {{sdata.url}} )"></div>
            </div>
        </ngx-slick-carousel>
    </div>
    <div class="photos-thumbs">
        <ngx-slick-carousel class="boothPhotosCarouselThumbs" #slickModalBoothThumbs="slick-carousel" [config]="boothThumbsSlideConfig">
            <div ngxSlickItem class="slide" *ngFor="let sdata of data?.images;">
                <!-- <img src="{{sdata.url}}" alt="{{sdata.altText}}"/> -->
                <div class="img" style="background-image:url( {{sdata.url}} )"></div>
            </div>
        </ngx-slick-carousel>
    </div>
</div>