
<div class="container" [ngClass]="{ 'sidebarOpened' : expanded }">
	<div class="faq-list-header">
		<h3>Need Some Help?</h3>
		<h1>ASK US ANYTHING</h1>
	</div>
	
	<div class="category-section">
		<div class="container">
			<div class="navigator">
				<span class="cursor" (click)="goToHome()">Home</span> <span class="arrow">></span>
				<span class="category-name">{{ category.name }}</span>
			</div>
			<div class="questions-list">
				<div
					class="question"
					*ngFor="let q of category.items; let i = index"
					(click)="goToQuestion(q, category)">
					<p>
						{{ q.question }}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
