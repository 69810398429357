import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PlaylistAudioStream, PlaylistStreamConfig } from '@i-con/shared/common';
import { NativeMusicPlayerComponent } from './components/native-music-player/native-music-player.component';
import { MusicPlayerComponent } from './components/music-player/music-player.component';



@NgModule({
  declarations: [
  ],
  providers: [PlaylistAudioStream],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MusicPlayerComponent,
    NativeMusicPlayerComponent
  ],
  exports: [MusicPlayerComponent, NativeMusicPlayerComponent, MatIconModule],
})
export class MusicPlayerModule {
  static forRoot(config: PlaylistStreamConfig): ModuleWithProviders<MusicPlayerModule> {
    return {
      ngModule: MusicPlayerModule,
      providers: [
        { provide: PlaylistStreamConfig, useValue: config }
      ]
    }
  }
}
