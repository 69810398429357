
<div class="container" [ngClass]="{ 'sidebarOpened' : expanded }">
    <div class="faq-list-header">
        <h3>Need Some Help?</h3>
        <h1>ASK US ANYTHING</h1>
    </div>
    
    
    <div class="categories-section">
        <div class="container">
            <h1><span>Categories</span></h1>
    
            <div class="faq-items">
                <div class="faq-item" *ngFor="let faq of faqData; let i = index;" (click)="goToCategory(faq)">
                    <div class="img-c">
                        <img src="https://cdn-images.island-conference.com/faq/{{ faq.ref_name }}.svg" alt="{{faq.alt}}"/>
                    </div>
                    <p>
                        {{faq.name}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
