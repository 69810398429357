import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { FaqRoutingModule } from "./faq-routing.module";
import { FaqCategoryListComponent } from "./pages/faq-category-list/faq-category-list.component";
import { FaqCategoryComponent } from "./pages/faq-category/faq-category.component";
import { FaqQuestionComponent } from "./pages/faq-question/faq-question.component";

@NgModule({
    declarations: [
        FaqCategoryListComponent,
        FaqCategoryComponent,
        FaqQuestionComponent
    ],
    imports: [
    MatIconModule,
      CommonModule,
      FaqRoutingModule,
      FormsModule, 
      ReactiveFormsModule,
    ]
  })
  export class FaqModule { }
  