<div class="login flexr">
    <ng-container *ngIf="step === 1">
        <div class="login-container" [class.active_nudge]="submitted && !success">
            <div class="login-info">
                <p>Manage your bookings, company details, ticket assignments, booth creative uploads and other
                    conference-related reservations via our online portal.</p>
            </div>
            <div class="form-container">
                <div class="error" *ngIf="hasErrors">
                    Sorry, the credentials you provided are incorrect. Please try again.
                </div>
                <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-row" formArrayName="digits">
                        <h2 class="field-heading">Use your 4-digit code</h2>
                        <div class="form-col code-fields">
                            <input *ngFor="let digitControl of digits.controls; let i = index" class="input-code-field"
                                id="digit-{{i}}" type="password" placeholder="*" maxlength="1"
                                (keyup)="onKey($event, i)" [formControlName]="i" />
                        </div>
                        <div *ngIf="(digits.touched || submitted) && !validDigits" class="invalid-feedback">
                            Code is required
                        </div>
                    </div>
                    <div class="form-row">
                        <h2 class="field-heading">Email</h2>
                        <div class="form-col email-field">
                            <input id="email" class="input-email-field" type="text" placeholder="email@example.com"
                                formControlName="email" />
                        </div>
                        <div *ngIf="(loginForm.touched || submitted) && f['email'].errors" class="invalid-feedback">
                            <div *ngIf="(loginForm.touched || submitted) && f['email'].errors['required']">
                                Email is required
                            </div>
                            <div *ngIf="(loginForm.touched || submitted) && f['email'].errors['email']">
                                Please write a valid email
                            </div>
                            <div *ngIf="(loginForm.touched || submitted) && f['email'].errors['maxlength']">
                                A maximum of
                                {{
                                (loginForm.touched || submitted) &&
                                f["email"].errors["maxlength"].requiredLength
                                }}
                                characters length is required
                            </div>
                        </div>
                    </div>
                    <div class="button-container">
                        <button class="primaryBtn main-btn" type="submit">Login</button>
                    </div>
                </form>
            </div>
            <div class="resend-code-container">
                <p>Forgot your 4-digit code? <br /> We 've got your back.</p>
                <a class="link" (click)="step = 2">Resend Code</a>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
        <resend-code-form (backToLogin)="step = 1"></resend-code-form>
    </ng-container>
</div>