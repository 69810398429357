import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryISO, NgxIntlTelInputComponent, NgxIntlTelInputModule, SearchCountryField } from 'ngx-intl-tel-input';

export interface IPrimaryContactReservation {
  name: string;
  surname: string;
  email: string;
  dialCode: string;
  phoneNumber: string;
  skypeAccount: string;
  country: string;
  companyName: string;
}

@Component({
  selector: 'primary-contact-form',
  standalone: true,
  imports: [CommonModule, NgxIntlTelInputModule, ReactiveFormsModule],
  templateUrl: './primary-contact-form.component.html',
  styleUrls: ['./primary-contact-form.component.scss'],
})
export class PrimaryContactFormComponent implements OnInit, AfterViewInit {
  @Output('setPrimaryContact')
  setPrimaryContact: EventEmitter<any> = new EventEmitter();
  @Output('goToStep') goToStep: EventEmitter<any> = new EventEmitter();
  @Input() previousStep!: number;
  @Input() primaryContact!: IPrimaryContactReservation;
  @Input() buttonText!: string;
  @Input() formTitle!: String;

  @ViewChild(NgxIntlTelInputComponent) telInputt!: NgxIntlTelInputComponent;
  primaryContactForm!: UntypedFormGroup;
  submitted = false;
  success = false;
  phoneInput: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  constructor(private formBuilder: UntypedFormBuilder, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.primaryContactForm = this.formBuilder.group({
      nameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      surnameControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
      emailControl: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      phoneControl: ['', [Validators.required]],
      skypeControl: ['', [Validators.minLength(5), Validators.maxLength(41)]],
      companyControl: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
    });

    if (this.primaryContact != null) {
      this.primaryContactForm.setValue({
        nameControl: this.primaryContact.name,
        surnameControl: this.primaryContact.surname,
        emailControl: this.primaryContact.email,
        skypeControl: this.primaryContact.skypeAccount || null,
        phoneControl: {
          number: this.primaryContact.phoneNumber,
          countryCode: this.primaryContact.country,
          dialCode: this.primaryContact.dialCode,
        },
        companyControl: this.primaryContact.companyName,
      });
      this.cd.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    if (this.primaryContact != null) {
      this.primaryContactForm.setValue({
        nameControl: this.primaryContact.name,
        surnameControl: this.primaryContact.surname,
        emailControl: this.primaryContact.email,
        skypeControl: this.primaryContact.skypeAccount || null,
        phoneControl: {
          number: this.primaryContact.phoneNumber,
          countryCode: this.primaryContact.country,
          dialCode: this.primaryContact.dialCode,
        },
        companyControl: this.primaryContact.companyName,
      });
      this.telInputt.onPhoneNumberChange();
      this.cd.detectChanges();
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.primaryContactForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.primaryContactForm.invalid) {
      return;
    } else {
      this.success = true;
      const primaryContact: IPrimaryContactReservation = {
        name: this.primaryContactForm.value.nameControl,
        surname: this.primaryContactForm.value.surnameControl,
        email: this.primaryContactForm.value.emailControl,
        skypeAccount: this.primaryContactForm.value.skypeControl || null,
        dialCode: this.primaryContactForm.value.phoneControl.dialCode,
        country: this.primaryContactForm.value.phoneControl.countryCode,
        phoneNumber: this.primaryContactForm.value.phoneControl.number,
        companyName: this.primaryContactForm.value.companyControl,
      };
      this.setPrimaryContact.emit({
        primaryContact,
      });
    }
  }

  goBack() {
    this.goToStep.emit({ step: this.previousStep });
  }

  onReset() {
    this.submitted = false;
    this.primaryContactForm.reset();
  }
}
