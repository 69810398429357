import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IPrimaryContactReservation, IReservation, IStayReservation, IStayReservationTypeResponse, StateService, StayReservationService } from '@i-con/shared/common';
import { CommonModule } from '@angular/common';
import { StayAccommodationRequestComponent } from "../stay-accommodation-request/stay-accommodation-request.component";
import { StayCreatePrimaryContactComponent } from "../create-primary-contact/create-primary-contact.component";

interface IStayReservationState {
	success: boolean;
	return_msg: string;
}

const initialState: IStayReservationState = {
	success: false,
	return_msg: '',
};

@Component({
    selector: 'app-stay-accommodation-request-layout',
    standalone: true,
    templateUrl: './stay-accommodation-request-layout.component.html',
    styleUrls: ['./stay-accommodation-request-layout.component.scss'],
    imports: [CommonModule, StayAccommodationRequestComponent, StayCreatePrimaryContactComponent]
})
export class StayAccommodationRequestLayoutComponent extends StateService<IStayReservationState> implements OnInit {
	constructor(private route: ActivatedRoute, private stayReservationService: StayReservationService) {
		super(initialState);
	}

	postData$!: Observable<IStayReservationTypeResponse>;
	responseState$!: Observable<IStayReservationState>;

	class_name = 'stay_accommodation_request_page';
	title1: string = 'BOOK YOUR STAY';

	stayReservation: IStayReservation = {
		primaryContact: {
			name: '',
			surname: '',
			email: '',
			country: '',
			dialCode: '',
			phoneNumber: '',
			skypeAccount: '',
			companyName: '',
		},
		reservation: {
			startDate: '',
			endDate: '',
			totalPersons: 0,
			hotelName: '',
		},
	};

	current_step: number = 1;
	hotelId!: number;
	params!: object;
	currentStep: number = 1;
	daysInformation!: object;

	success = false;
	submitted = false;

	ngOnInit(): void {
		this.postData$ = this.stayReservationService.postData$;
		this.responseState$ = this.getState((state) => state);

		// this.route.queryParams.subscribe((params) => {
		// 	this.hotelId = params['id'];
		// });
	}

	stayInformation($event: { reservation: IReservation }) {
		this.stayReservation.reservation = $event.reservation;
		this.currentStep = 2;
	}

	setPrimaryContact($event: { primaryContact: IPrimaryContactReservation }) {
		this.stayReservation.primaryContact = $event.primaryContact;
		this.sendPostRequest();
	}

	sendPostRequest() {
		this.submitted == true;

		const test_error = {
			primaryContact: {},
			reservation: {},
		};

		this.stayReservationService.stayReservation(this.stayReservation);

		// this.stayReservationService.stayReservation(test_error);

		this.postData$.subscribe((res) => {
			if (res.response?.statusCode == 201) {
				this.setState({
					success: true,
					return_msg: '',
				});
			} else {
				this.setState({
					success: false,
					return_msg: res.response?.message,
				});
			}
		});
	}

	goToStep(step: any): void {
		this.currentStep = step.step;
	}
}
