import { INextSteps } from '@i-con/shared/common';

export const cardsData: INextSteps[] = [
  {
    imageUrl: 'https://cdn-images.island-conference.com/successful-api-call/events.jpg',
    imageAlt: '',
    title: 'Events',
    description: `Don't miss out! Reserve your spot at i-Con's unforgettable events. You won't want to be left out of any of the legendary parties organized by Haze!`,
    linkText: 'Get On the Guestlist',
    redirectUrl: 'plan/events',
  },
  {
    imageUrl: 'https://cdn-images.island-conference.com/successful-api-call/accommodation.jpg',
    imageAlt: '',
    title: 'Accommodation',
    description: `So now that you are officially coming, where are you going to stay? We have got you covered with exclusive discounts at the best hotels in Cyprus.`,
    linkText: 'Reserve your Stay',
    redirectUrl: 'stay/accommodation',
  },
  {
    imageUrl: 'https://cdn-images.island-conference.com/successful-api-call/astronaut.png',
    imageAlt: '',
    title: 'Free Transportation',
    description: `Enjoy complimentary rides with our exclusive transportation. Travel with our shuttle buses, ensuring smooth journeys to and from the airport and event hotspots.`,
    linkText: 'Grab your Seat',
    redirectUrl: 'stay/transportation',
  },
];
